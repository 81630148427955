import React, { useState } from "react";

function usePagination(data = []) {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const maxPage = Math.ceil(data.length / Number(perPage));

  if (Number(maxPage) && Number(maxPage) < Number(currentPage))
    setCurrentPage(maxPage);

  const length = data.length;
  function currentData() {
    const begin = (currentPage - 1) * Number(perPage);
    const end = begin + Number(perPage);
    return data.slice(begin, end);
  }

  function next() {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  }

  function showPerPage(perPage) {
    setPerPage(perPage);
  }
  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  }

  function jump(page) {
    const pageNumber = Math.max(1, page);
    setCurrentPage((currentPage) => Math.min(pageNumber, maxPage));
  }

  return {
    next,
    prev,
    jump,
    currentData,
    currentPage,
    maxPage,
    length,
    showPerPage,
    perPage: Number(perPage),
  };
}

export default usePagination;
