import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openedList: null,
  activeLink: null,
};

const siderbarActiveListSlice = createSlice({
  name: "SiderbarActiveList",
  initialState,
  reducers: {
    setSiderBarActiveList(state, action) {
      return {
        ...state,
        openedList: action.payload.name,
      };
    },
    setSiderBarActiveLink(state, action) {
      return {
        ...state,
        activeLink: action.payload.link,
      };
    },
  },
});

export const { setSiderBarActiveList, setSiderBarActiveLink } =
  siderbarActiveListSlice.actions;
export default siderbarActiveListSlice.reducer;
