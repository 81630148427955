import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import toast from "react-hot-toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import { allCategoryApi, categoryApi } from "../../../assets/apis";
import Footer from "../../../helper/Footer";
import { useDispatch, useSelector } from "react-redux";
import ConfirmPortal from "../../../uiModals/confirmModel/ConfirmPortal";
import {
  setSiderBarActiveLink,
  setSiderBarActiveList,
} from "../../../redux-store/sidebarActiveList";
import { hasPermission } from "../../../utils/helpers";
export const fetchCategories = async () => {
  const response = await axios.get(`${allCategoryApi}?active=${true}`);
  return response.data;
};

const Category = () => {
  const { FYear } = useSelector((store) => store.FinancialYear);
  const { loggedUser } = useSelector((store) => store.Auth);

  const dispatch = useDispatch();
  const [openPortal, setOpenPortal] = useState(false);
  const [deletingItem, setDeletingItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [categoryData, setCategoryData] = useState([]);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: ["category"],
    queryFn: async () => {
      const response = await axios.get(categoryApi);
      return response.data;
    },
  });

  useEffect(() => {
    // Check if data has categories and set the state
    if (data?.categories) {
      setCategoryData(data.categories);
    }
  }, [data]);

  const deleteCategory = async (id) => {
    handleClosePortal();
    try {
      await axios.delete(`${categoryApi}/${id}`);
      toast.success("Category deleted successfully");
      queryClient.invalidateQueries("category");
    } catch (error) {
      toast.error(error.response?.data?.message || "Error deleting category");
    }
  };

  // Function to close the modal
  const handleOpenPortal = (data) => {
    setDeletingItem(data);
    setOpenPortal(true);
  };
  const handleClosePortal = () => {
    setDeletingItem(null);
    setOpenPortal(false);
  };

  const handleInputChangeWithCondition = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setSearchTerm(value);
    }
  };

  const filteredData = categoryData?.filter((item) =>
    Object.values(item).some((val) =>
      val?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  useEffect(() => {
    refetch();
  }, [FYear.value]);

  useEffect(() => {
    dispatch(setSiderBarActiveList({ name: "Items" }));
    dispatch(setSiderBarActiveLink({ link: "categories" }));
  }, []);

  return (
    <div className="main-panel">
      <div className="content-wrapper  ">
        <div id="first" className="card col-lg-12 p-3">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="card-title"> All Categories</div>
            {hasPermission(loggedUser, 16) && (
              <button
                className="btn btn-primary px-2 py-1"
                onClick={() => navigate("/add/category")}
              >
                Add Category
              </button>
            )}
          </div>

          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label className="form-control-label" htmlFor="name"></label>
                <input
                  type="text"
                  className="form-control "
                  name="name"
                  id="name"
                  placeholder="search"
                  value={searchTerm}
                  onChange={(e) => {
                    handleInputChangeWithCondition(e, /^[\s\S]*$/);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="d-flex flex-column  align-items-center">
            <DataTable
              value={filteredData}
              style={{ width: "100%" }}
              id="review-table"
              className="mytable"
            >
              <Column
                className="border-bottom border-top"
                header="SR. No."
                body={(rowData, { rowIndex }) => rowIndex + 1}
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "4rem",
                }}
              />

              <Column
                className="border-bottom border-top"
                field="name"
                header="NAME"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "9rem",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="active"
                body={(rowData) => (rowData.active ? "Active" : "Inactive")}
                header="ACTIVE"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "9rem",
                }}
              />
              <Column
                className="border-bottom border-top"
                body={(data) => (
                  <div
                    style={{
                      minWidth: "50%",
                      maxWidth: "2rem",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    {hasPermission(loggedUser, 17) && (
                      <i
                        onClick={(e) => navigate(`/edit/category/${data.id}`)}
                        className="dropdown-item-icon mdi mdi-pen text-primary me-2"
                      />
                    )}
                    {hasPermission(loggedUser, 18) && (
                      <i
                        onClick={(e) => {
                          handleOpenPortal(data);
                        }}
                        className="dropdown-item-icon mdi mdi-delete-forever text-primary me-2"
                      />
                    )}
                  </div>
                )}
                header="ACTIONS"
              />
            </DataTable>
          </div>
        </div>
      </div>
      {openPortal && (
        <ConfirmPortal
          onClose={handleClosePortal}
          type={"Category"}
          name={deletingItem.name}
          id={deletingItem.id}
          handleDalete={deleteCategory}
        ></ConfirmPortal>
      )}
    </div>
  );
};

export default Category;
