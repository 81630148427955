import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToWords } from "to-words";
import {
  dateFormateDDMMYYYY,
  parseFloatWithFixedValue,
} from "../../../utils/helpers";

const PreviewDeliveryChallan = ({
  data,
  apiInProccess,
  handleSubmit,
  setIsPreview,
  setPayload,
}) => {
  const { companyData } = useSelector((state) => state.Company);
  const printRef = useRef();
  const navigate = useNavigate();
  const colspanValue =
    data?.invoiceType === "item_wise_discount_and_tax" ? 5 : 4;
  const colspanValueRight =
    data?.invoiceType === "item_wise_discount_and_tax" ? 3 : 1;

  const checkout_details = data?.checkout_details || {};
  const isBillAndShipAddressSame = checkout_details?.isBillAndShipAddressSame;
  const ship_to_details = {
    shipping_display_name: isBillAndShipAddressSame
      ? checkout_details?.display_name
      : checkout_details?.shipping_display_name,
    shipping_address: isBillAndShipAddressSame
      ? checkout_details?.billing_address
      : checkout_details?.shipping_address,
    shipping_city: isBillAndShipAddressSame
      ? checkout_details?.billing_city
      : checkout_details?.shipping_city,
    shipping_state: isBillAndShipAddressSame
      ? checkout_details?.billing_state
      : checkout_details?.shipping_state,
    shipping_pin_code: isBillAndShipAddressSame
      ? checkout_details?.billing_PIN_Code
      : checkout_details?.shipping_pin_code,
    shipping_country: isBillAndShipAddressSame
      ? checkout_details?.billing_country
      : checkout_details?.shipping_country,
    shipping_phone: isBillAndShipAddressSame
      ? checkout_details?.phone
      : checkout_details?.shipping_phone,
    shipping_email: isBillAndShipAddressSame
      ? checkout_details?.email
      : checkout_details?.shipping_email,
  };
  const toWords = new ToWords();

  const editDeliveryChallan = () => {
    setIsPreview(false);
    setPayload({});
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`main-panel`}>
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-12">
            <div className="d-flex justify-content-between m-1 p-1 align-items-baseline ">
              <h3 className="ukhd mb-3">Preview Delivery Challan</h3>
              <button
                type="button"
                className="btn btn-warning btn-sm"
                onClick={editDeliveryChallan}
              >
                <div className="d-flex justify-content-center">
                  <i className="mdi mdi mdi-keyboard-backspace"></i>
                  <span>Back</span>
                </div>
              </button>
            </div>

            <div className="row flex-grow">
              <div className="col-12 grid-margin stretch-card">
                <div className="card card-rounded">
                  <div className="card-body responsiveviewsales">
                    <div className="print_normal_container p-0" ref={printRef}>
                      {data?.other_info?.clientNote ? (
                        <div className="title_body">
                          <span>
                            Note&nbsp;:&nbsp;{data?.other_info?.clientNote}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="normal_first-section">
                        <div>
                          <span className="title">
                            Delivery Challan Receipt
                          </span>
                          <br />
                          <span className="title">
                            {`Challan-${data?.challan}`}
                          </span>
                          <div>
                            <span className="title">Date : </span>
                            <span>{dateFormateDDMMYYYY(data?.date)}</span>
                          </div>
                        </div>
                        {companyData?.is_logo && companyData?.logo ? (
                          <div>
                            <img src={companyData?.logo} alt="" width={100} />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="normal_second_section invoice_border_top">
                        <div className="normal_second_section_child  pe-3">
                          <span className="title">{companyData?.name}</span>
                          <span className="title_body">
                            {companyData?.address}
                            {", "}
                            {companyData?.pin}
                          </span>
                          {/* <span className="title_body">
                          {companyData?.city} {companyData?.state}{" "}
                          {companyData?.pin}
                        </span> */}
                          {/* <span className="title_body">
                          {companyData?.country}
                        </span> */}
                          <span className="title_body">
                            <span>Phone : </span>
                            <span>{companyData?.phone || ""}</span>
                          </span>
                          <span className="title_body">
                            <span>Email : </span>
                            <span>{companyData?.email || ""}</span>
                          </span>
                          {companyData?.website ? (
                            <span className="title_body">
                              <span>Website : </span>
                              <span>{companyData?.website || ""}</span>
                            </span>
                          ) : (
                            ""
                          )}
                          <span className="title">
                            <span>GSTIN : </span>
                            <span>{companyData?.gst}</span>
                          </span>
                        </div>
                        <div className="normal_second_section_child  pe-3">
                          <span className="title">Bill to</span>
                          <span className="title">
                            {checkout_details?.display_name}
                          </span>
                          <span className="title_body">
                            {checkout_details?.billing_address}
                          </span>
                          <span className="title_body">
                            {checkout_details?.billing_city}{" "}
                            {checkout_details?.billing_state}{" "}
                            {checkout_details?.billing_PIN_Code}
                          </span>
                          <span className="title_body">
                            {checkout_details?.billing_country}
                          </span>
                          <span className="title_body">
                            <span>Phone : </span>
                            <span>{checkout_details?.phone || ""}</span>
                          </span>
                          {checkout_details?.email ? (
                            <span className="title_body">
                              <span>Email : </span>
                              <span>{checkout_details?.email || ""}</span>
                            </span>
                          ) : (
                            ""
                          )}
                          {checkout_details?.GSTIN ? (
                            <span className="title">
                              <span>GSTIN : </span>
                              <span>{checkout_details?.GSTIN || ""}</span>
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="normal_second_section_child">
                          <span className="title ">Ship to</span>
                          <span className="title">
                            {ship_to_details?.shipping_display_name}
                          </span>
                          <span className="title_body">
                            {ship_to_details?.shipping_address}
                          </span>
                          <span className="title_body">
                            {ship_to_details?.shipping_city}{" "}
                            {ship_to_details?.shipping_state}{" "}
                            {ship_to_details?.shipping_pin_code}
                          </span>
                          <span className="title_body">
                            {ship_to_details?.shipping_country}
                          </span>
                          <span className="title_body">
                            <span>Phone : </span>
                            <span>{ship_to_details?.shipping_phone || ""}</span>
                          </span>
                          {ship_to_details?.shipping_email ? (
                            <span className="title_body">
                              <span>Email : </span>
                              <span>
                                {ship_to_details?.shipping_email || ""}
                              </span>
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="normal_third_section">
                        <table>
                          <thead>
                            <tr className="title">
                              <th className="privent_sr_widthtext-align-left invoice-border-right">
                                Sr. No.
                              </th>
                              <th className="text-align-left invoice-border-right">
                                Product
                              </th>
                              <th className="privent_width text-align-left invoice-border-right">
                                HSN/SAC
                              </th>
                              <th className="invoice-border-right">Qty</th>
                              <th className="invoice-border-right">Rate</th>
                              {data?.invoiceType ===
                              "item_wise_discount_and_tax" ? (
                                <>
                                  <th className="invoice-border-right">
                                    Amount
                                  </th>
                                  <th className="invoice-border-right">
                                    Disc.
                                  </th>
                                  <th className="invoice-border-right">Tax</th>
                                </>
                              ) : (
                                ""
                              )}

                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.all_products.map((product, index) => (
                              <tr className="title-body" key={index}>
                                <td className="text-align-left invoice-border-right">
                                  {index + 1}
                                </td>
                                <td className="text-align-left invoice-border-right">
                                  {product?.product?.name}
                                </td>
                                <td className="invoice-border-right">
                                  {product?.code}
                                </td>
                                <td className="invoice-border-right">
                                  {product?.quantity}
                                </td>
                                <td className="invoice-border-right">
                                  ₹&nbsp;
                                  {parseFloatWithFixedValue(product?.price)}
                                </td>
                                {data?.invoiceType ===
                                "item_wise_discount_and_tax" ? (
                                  <>
                                    <td className="invoice-border-right">
                                      ₹&nbsp;
                                      {parseFloatWithFixedValue(
                                        product?.quantity * product?.price
                                      )}
                                    </td>{" "}
                                    <td className="invoice-border-right">
                                      ₹&nbsp;
                                      {parseFloatWithFixedValue(
                                        product?.discount || 0
                                      )}
                                    </td>
                                    <td className="invoice-border-right">
                                      ₹&nbsp;
                                      {parseFloatWithFixedValue(
                                        product?.itemTax || 0
                                      )}
                                    </td>
                                  </>
                                ) : (
                                  ""
                                )}

                                {/* <td>0</td>
            <td>0</td> */}
                                <td>
                                  ₹&nbsp;
                                  {parseFloatWithFixedValue(product?.amount)}
                                </td>
                              </tr>
                            ))}
                            {data?.invoiceType ===
                            "item_wise_discount_and_tax" ? (
                              <tr>
                                <td
                                  className="text-align-center invoice-border-right title"
                                  colSpan={colspanValue}
                                >
                                  Totals
                                </td>
                                <td className="text-align-left invoice-border-right title">
                                  ₹&nbsp;
                                  {total(data?.all_products, "preSubTotal")}
                                </td>
                                <>
                                  <td className="text-align-left invoice-border-right title">
                                    ₹&nbsp;
                                    {total(data?.all_products, "discount")}
                                  </td>
                                  <td className="text-align-left invoice-border-right title">
                                    ₹&nbsp;
                                    {total(data?.all_products, "itemTax")}
                                  </td>
                                </>

                                <td className="text-align-left  title">
                                  ₹&nbsp;{total(data?.all_products, "amount")}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                            <tr className="border-top-none border-bottom-none">
                              <td
                                className="title"
                                style={{ textAlign: "center" }}
                                colSpan={colspanValue}
                              ></td>
                              <td
                                className="title"
                                style={{ textAlign: "right" }}
                                colSpan={colspanValueRight}
                              >
                                Sub Total
                              </td>
                              <td className="title">
                                ₹&nbsp;
                                {parseFloatWithFixedValue(data?.amount || 0)}
                              </td>
                            </tr>

                            {data?.discount ? (
                              <tr className="border-top-none border-bottom-none">
                                <td
                                  className="title"
                                  style={{ textAlign: "center" }}
                                  colSpan={colspanValue}
                                ></td>
                                <td
                                  className="title"
                                  style={{ textAlign: "right" }}
                                  colSpan={colspanValueRight}
                                >
                                  Discount(&nbsp;
                                  <span
                                    style={{
                                      fontSize: "1rem",
                                    }}
                                  >
                                    -
                                  </span>
                                  &nbsp;)
                                </td>
                                <td className="title">
                                  ₹&nbsp;
                                  {parseFloatWithFixedValue(
                                    data?.discount || 0
                                  )}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}

                            {data?.CGST ? (
                              <tr className="border-top-none border-bottom-none">
                                <td
                                  className="title"
                                  style={{ textAlign: "center" }}
                                  colSpan={colspanValue}
                                ></td>
                                <td
                                  className="title"
                                  style={{ textAlign: "right" }}
                                  colSpan={colspanValueRight}
                                >
                                  CGST
                                </td>
                                <td className="title">
                                  ₹&nbsp;
                                  {parseFloatWithFixedValue(data?.CGST || 0)}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}

                            {data?.SGST ? (
                              <tr className="border-top-none border-bottom-none">
                                <td
                                  className="title"
                                  style={{ textAlign: "center" }}
                                  colSpan={colspanValue}
                                ></td>
                                <td
                                  className="title"
                                  style={{ textAlign: "right" }}
                                  colSpan={colspanValueRight}
                                >
                                  SGST
                                </td>
                                <td className="title">
                                  ₹&nbsp;
                                  {parseFloatWithFixedValue(data?.SGST || 0)}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}

                            {data?.IGST ? (
                              <tr className="border-top-none border-bottom-none">
                                <td
                                  className="title"
                                  style={{ textAlign: "center" }}
                                  colSpan={colspanValue}
                                ></td>
                                <td
                                  className="title"
                                  style={{ textAlign: "right" }}
                                  colSpan={colspanValueRight}
                                >
                                  IGST
                                </td>
                                <td className="title">
                                  ₹&nbsp;
                                  {parseFloatWithFixedValue(data?.IGST || 0)}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}

                            <tr className="border-top-none border-bottom-none">
                              <td
                                className="title"
                                style={{ textAlign: "center" }}
                                colSpan={colspanValue}
                              >
                                {toWords.convert(data?.total || 0)}
                              </td>
                              <td
                                className="title"
                                style={{ textAlign: "right" }}
                                colSpan={colspanValueRight}
                              >
                                Total Amount
                              </td>
                              <td className="title">
                                ₹&nbsp;
                                {parseFloatWithFixedValue(data?.total || 0)}
                              </td>
                            </tr>

                            {data?.paid_amount ? (
                              <tr className="border-top-none border-bottom-none">
                                <td
                                  className="title"
                                  style={{ textAlign: "center" }}
                                  colSpan={colspanValue}
                                ></td>
                                <td
                                  className="title"
                                  style={{ textAlign: "right" }}
                                  colSpan={colspanValueRight}
                                >
                                  Paid Amount
                                </td>
                                <td className="title">
                                  ₹&nbsp;
                                  {parseFloatWithFixedValue(
                                    data?.paid_amount || 0
                                  )}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}

                            {data?.balance ? (
                              <tr className="border-top-none border-bottom-none">
                                <td
                                  className="title"
                                  style={{ textAlign: "center" }}
                                  colSpan={colspanValue}
                                ></td>
                                <td
                                  className="title"
                                  style={{ textAlign: "right" }}
                                  colSpan={colspanValueRight}
                                >
                                  Due Amount
                                </td>
                                <td className="title">
                                  ₹&nbsp;
                                  {parseFloatWithFixedValue(data?.balance || 0)}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                          </tbody>
                          {/* <tfoot>
                          <tr className="title-body">
                            <th className="title" colSpan={2}>Totals</th>
                            <td>
                              {data?.all_products?.reduce(
                                (sum, product) => sum + product.quantity,
                                0
                              )}
                            </td>
                            <td>
                              {parseFloatWithFixedValue(
                                data?.all_products?.reduce(
                                  (sum, product) =>
                                    sum + product.quantity * product.price,
                                  0
                                )
                              )}
                            </td>
                            <td>
                              {parseFloatWithFixedValue(
                                data?.all_products?.reduce(
                                  (sum, product) =>
                                    sum + product.discount || 0,
                                  0
                                )
                              )}
                            </td>
                            <td>
                              {parseFloatWithFixedValue(
                                data?.all_products?.reduce(
                                  (sum, product) => sum + product.itemTax,
                                  0
                                )
                              )}
                            </td>

                            <td>
                              {parseFloatWithFixedValue(
                                data?.all_products?.reduce(
                                  (sum, product) => sum + product.amount,
                                  0
                                )
                              )}
                            </td>
                          </tr>
                        </tfoot> */}
                        </table>
                      </div>
                      <div className="normal_fourth_section invoice_border_top">
                        {companyData?.is_bank_detail &&
                        data?.other_info?.bank ? (
                          <span className="title">Company's Bank Details</span>
                        ) : (
                          <span></span>
                        )}
                        <span className="title">
                          Certified that the particular given are true and
                          correct
                        </span>
                      </div>
                      <div className="normal_fifth_section invoice_border_top">
                        <div>
                          {companyData?.is_bank_detail &&
                          data?.other_info?.bank ? (
                            <div>
                              <div className="bankDetails">
                                <span className="title">BANK NAME</span>
                                <span className="title-body">
                                  {data?.other_info?.bank?.name}
                                </span>
                              </div>
                              <div className="bankDetails">
                                <span className="title">ACCOUNT HOLDER</span>
                                <span className="title-body">
                                  {data?.other_info?.bank?.holder_name}
                                </span>
                              </div>
                              <div className="bankDetails">
                                <span className="title">BANK A/C No.</span>
                                <span className="title-body">
                                  {data?.other_info?.bank?.account_no}
                                </span>
                              </div>
                              <div className="bankDetails">
                                <span className="title">IFSC No. </span>
                                <span className="title-body">
                                  {data?.other_info?.bank?.ifsc}
                                </span>
                              </div>
                              <div className="bankDetails">
                                <span className="title">BRANCH</span>
                                <span className="title-body">
                                  {data?.other_info?.bank?.branch}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                        <div className="title">
                          {companyData?.is_common_seal
                            ? companyData?.common_seal && (
                                <span>
                                  <img
                                    src={companyData?.common_seal}
                                    alt=""
                                    width={80}
                                  />
                                </span>
                              )
                            : ""}

                          <span>Common Seal</span>
                        </div>
                        <div className="title">
                          <span>For &nbsp;{companyData?.name}&nbsp;</span>
                          <div>
                            {companyData?.is_sign
                              ? companyData?.sign && (
                                  <span>
                                    <img
                                      src={companyData?.sign}
                                      alt=""
                                      width={80}
                                    />
                                  </span>
                                )
                              : ""}
                            <span>Authorised Signatory</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ padding: "1.5rem" }}>
                    <button
                      type="submit"
                      className="btn btn-primary me-2 px-3 py-2"
                      onClick={handleSubmit}
                      disabled={apiInProccess}
                    >
                      <div className="d-flex justify-content-center">
                        {" "}
                        <i className="mdi mdi-plus fw-bold"></i> Save
                      </div>
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary me-2 px-3 py-2"
                      onClick={editDeliveryChallan}
                    >
                      <div className="d-flex justify-content-center">
                        {" "}
                        <i className="mdi mdi-pencil fw-bold"></i> Edit
                      </div>
                    </button>

                    <button
                      className="btn btn btn-secondary px-3 py-2"
                      onClick={() => navigate("/invoices/delivery_challan")}
                    >
                      <div className="d-flex justify-content-center ">
                        <i className="mdi mdi-close "></i>
                        Cancel
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewDeliveryChallan;

function total(products, key) {
  return parseFloat(
    products?.reduce((sum, product) => sum + Number(product[key] || 0), 0)
  ).toFixed(2);
}
