import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Column } from "primereact/column";
import Modal from "@mui/material/Modal";
import { DataTable } from "primereact/datatable";
import { Box } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import Select from "react-select";
import axios from "axios";
import {
  brandApi,
  categoryApi,
  productApi,
  taxApi,
} from "../../../assets/apis";
import { fetchTaxes } from "../../Taxes/Tax/Tax";
import { fetchHsns } from "../../Taxes/gsthsn/GstHsn";
import { fetchSacs } from "../../Taxes/gstsac/GstSac";
import { unitOfMeasurement } from "../../../assets/UnitOfmeasure";
import { fetchBrands } from "../brands/Brand";
import { fetchCategories } from "../category/Category";
import { selectCustomStyle } from "../../../assets/selectCustomStyle";
import { productValidation } from "./productValidation";
import {
  setSiderBarActiveLink,
  setSiderBarActiveList,
} from "../../../redux-store/sidebarActiveList";
import { hasPermission } from "../../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  p: 4,
};

const AddProductService = () => {
  const { loggedUser } = useSelector((state) => state.Auth);
  const [addItemData, setAddItemData] = useState({
    type: "product",
  });
  const dispatch = useDispatch();
  const [addCategoryVisible, setAddCategoryVisible] = useState(false);
  const [addBrandVisible, setAddBrandVisible] = useState(false);
  const [addTaxVisible, setAddTaxVisible] = useState(false);
  const [categoryArray, setCategoryArray] = useState([]);
  const [brandArray, setBrandArray] = useState([]);
  const [taxArray, setTaxArray] = useState([]);
  const [hsnArray, setHnsArray] = useState([]);
  const [sacArray, setSacArray] = useState([]);
  const [codeArray, setCodeArray] = useState([]);
  const [category, setCategory] = useState({ active: true });
  const [editCategoryState, setEditCategoryState] = useState(false);
  const [brand, setBrand] = useState({ active: true });
  const [editBrandState, setEditBrandState] = useState(false);
  const [tax, setTax] = useState({ active: true });
  const [editTaxState, setEditTaxState] = useState(false);
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const onChangeInput = (e, selectName) => {
    const updatedData = selectName
      ? { [selectName]: e }
      : { [e.target.name]: e.target.value };

    setAddItemData((prev) => ({
      ...prev,
      ...updatedData,
    }));
  };

  const handleInputChangeWithCondition = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setAddItemData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleInputChangeWithConditonTypeNumber = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setAddItemData((prev) => ({ ...prev, [name]: Number(value) }));
    }
  };

  const handleItemTypeChange = (e, selectName) => {
    setAddItemData((pre) => ({
      ...pre,
      [selectName]: e,
      sac_code: "",
      hsn_code: "",
    }));
  };

  const isProduct = addItemData.type === "product";

  // category logic
  const { data: categoryData } = useQuery({
    queryKey: ["category"],
    queryFn: fetchCategories,
  });

  useEffect(() => {
    if (categoryData?.categories) {
      const temp = categoryData.categories.map((cat) => {
        return { ...cat, value: cat.name, label: cat.name };
      });
      setCategoryArray(temp);
    }
  }, [categoryData]);

  const handleCategoryInputChangeWithCondition = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setCategory((prev) => ({ ...prev, [name]: value }));
    }
  };

  const createCategoryApiCallHandler = async (e) => {
    e.preventDefault();
    const { name } = category;
    let updatedCategory = {
      ...category,
      name: name.trim(),
    };
    setCategory(updatedCategory);
    if (!updatedCategory.name) return toast.error("Please enter Category name");
    try {
      await axios.post(categoryApi, updatedCategory);
      toast.success("Category added successfully");
      queryClient.invalidateQueries("category");
      setAddCategoryVisible(false);
      setCategory({
        active: true,
      });
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const editCategory = async (e) => {
    e.preventDefault();
    const { name } = category;
    let updatedCategory = {
      ...category,
      name: name.trim(),
    };
    setCategory(updatedCategory);
    if (!updatedCategory.name) return toast.error("Please enter Category name");
    try {
      await axios.put(`${categoryApi}/${editCategoryState}`, updatedCategory);
      toast.success("Category edited successfully");
      queryClient.invalidateQueries("category");
      setAddCategoryVisible(false);
      setCategory({
        active: true,
      });
      setEditCategoryState(false);
    } catch (error) {
      toast.error(error.response.message);
    }
  };

  const deleteCategory = async (id) => {
    try {
      await axios.delete(`${categoryApi}/${id}`);

      toast.success("Category deleted successfully");
      queryClient.invalidateQueries("category");
    } catch (error) {
      toast.error(error);
    }
  };

  // brand logic
  const { data: brandData } = useQuery({
    queryKey: ["brand"],
    queryFn: fetchBrands,
  });

  useEffect(() => {
    if (brandData?.brands) {
      const temp = brandData.brands.map((cat) => {
        return { ...cat, value: cat.name, label: cat.name };
      });
      setBrandArray(temp);
    }
  }, [brandData]);

  const handleBrandInputChangeWithCondition = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setBrand((prev) => ({ ...prev, [name]: value }));
    }
  };

  const createBrandApiCallHandler = async (e) => {
    e.preventDefault();
    const { name } = brand;
    let updatedBrandData = {
      ...brand,
      name: name.trim(),
    };
    setBrand(updatedBrandData);
    if (!updatedBrandData.name) return toast.error("Please enter brand name");
    try {
      await axios.post(brandApi, updatedBrandData);
      toast.success("Brand added successfully");
      queryClient.invalidateQueries("brand");
      setAddBrandVisible(false);
      setBrand({
        active: true,
      });
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const editBrand = async (e) => {
    e.preventDefault();
    const { name } = brand;
    let updatedBrandData = {
      ...brand,
      name: name.trim(),
    };
    setBrand(updatedBrandData);
    if (!updatedBrandData.name) return toast.error("Please enter brand name");
    try {
      await axios.put(`${brandApi}/${editBrandState}`, updatedBrandData);
      toast.success("Brand edited successfully");
      queryClient.invalidateQueries("brand");
      setAddBrandVisible(false);
      setBrand({
        active: false,
      });
      setEditBrandState(false);
    } catch (error) {
      toast.error(error.response.message);
    }
  };

  const deleteBrand = async (id) => {
    try {
      await axios.delete(`${brandApi}/${id}`);

      toast.success("Brand deleted successfully");
      queryClient.invalidateQueries("brand");
    } catch (error) {
      toast.error(error);
    }
  };

  // tax logic
  const { data: taxData } = useQuery({
    queryKey: ["tax"],
    queryFn: fetchTaxes,
  });

  useEffect(() => {
    if (taxData?.taxes) {
      const temp = taxData?.taxes.map((cat) => {
        return { ...cat, value: cat.name, label: cat.name };
      });
      setTaxArray(temp);
    }
  }, [taxData]);

  const handleTaxInputChangeWithCondition = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setTax((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleTaxInputChangeWithConditonTypeNumber = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setTax((prev) => ({ ...prev, [name]: Number(value) }));
    }
  };

  const createTaxApiCallHandler = async (e) => {
    e.preventDefault();
    const { name, rate, description } = tax;
    const updatedTaxData = {
      ...tax,
      name: name.trim(),
      ...(description && { description: description.trim() }),
    };
    setTax(updatedTaxData);
    if (!updatedTaxData.name) return toast.error("Please enter a name");
    if (+rate > 100) return toast.error("Please enter a valid rate");
    try {
      await axios.post(taxApi, updatedTaxData);
      toast.success("Tax added successfully");
      queryClient.invalidateQueries("tax");
      setTax({
        actve: true,
      });
      setAddTaxVisible(false);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const editTax = async (e) => {
    e.preventDefault();
    const { name, rate, description } = tax;
    const updatedTaxData = {
      ...tax,
      name: name.trim(),
      ...(description && { description: description.trim() }),
    };
    setTax(updatedTaxData);
    if (!updatedTaxData.name) return toast.error("Please enter a name");
    if (+rate > 100) return toast.error("Please enter a valid rate");
    try {
      await axios.put(`${taxApi}/${editTaxState}`, updatedTaxData);
      toast.success("Tax edited successfully");
      queryClient.invalidateQueries("tax");
      setAddTaxVisible(false);
      setTax({
        active: true,
      });
      setEditTaxState(false);
    } catch (error) {
      toast.error(error.response.message);
    }
  };

  const deleteTax = async (id) => {
    try {
      await axios.delete(`${taxApi}/${id}`);

      toast.success("Tax deleted successfully");
      queryClient.invalidateQueries("tax");
    } catch (error) {
      toast.error(error.response.message);
    }
  };

  //hsn logic
  const { data: hsnData } = useQuery({
    queryKey: ["hsns"],
    queryFn: fetchHsns,
  });

  const hsnMemoriesArray = useMemo(() => {
    if (!hsnData || !hsnData.gsthsns) return [];

    return hsnData.gsthsns.reduce((acc, hsn) => {
      const { companyId, createdAt, updatedAt, ...rest } = hsn;
      acc.push(rest);
      return acc;
    }, []);
  }, [hsnData]);

  useEffect(() => {
    setHnsArray(hsnMemoriesArray);
  }, [hsnMemoriesArray]);

  //sac logic
  const { data: sacData } = useQuery({
    queryKey: ["sacs"],
    queryFn: fetchSacs,
  });

  const sacMemoriesArray = useMemo(() => {
    if (!sacData || !sacData.gstsacs) return [];
    return sacData.gstsacs.reduce((acc, sac) => {
      const { companyId, createdAt, updatedAt, ...rest } = sac;
      acc.push(rest);
      return acc;
    }, []);
  }, [sacData]);

  useEffect(() => {
    setSacArray(sacMemoriesArray);
  }, [sacMemoriesArray]);

  useEffect(() => {
    let combineArray = [];

    if (isProduct) {
      combineArray = [
        ...hsnMemoriesArray.map(
          ({ name_of_commodity, gst_rate, active, hsn_code, hsn_code_no }) => ({
            name_of_commodity,
            gst_rate,
            active,
            code: hsn_code,
            code_no: hsn_code_no,
          })
        ),
        ...sacMemoriesArray.map(
          ({ name_of_commodity, gst_rate, active, sac_code, sac_code_no }) => ({
            name_of_commodity,
            gst_rate,
            active,
            code: sac_code,
            code_no: sac_code_no,
          })
        ),
      ];
    } else {
      combineArray = sacMemoriesArray.map(
        ({ name_of_commodity, gst_rate, active, sac_code, sac_code_no }) => ({
          name_of_commodity,
          gst_rate,
          active,
          code: sac_code,
          code_no: sac_code_no,
        })
      );
    }

    setCodeArray(combineArray);
    return () => {
      setCodeArray([]);
    };
  }, [isProduct, hsnMemoriesArray, sacMemoriesArray]);

  const mutation = useMutation({
    mutationFn: (data) => {
      return axios.post(productApi, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["product"]);
      toast.success("Item saved successfully!");
      navigate("/items");
    },
    onError: (error) => {
      const errorMessage = error.response?.data?.message;
      toast.error(` ${errorMessage}`);
    },
  });

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    const { name, varient, description } = addItemData;
    let updatedItemData = {
      ...addItemData,
      name: name.trim(),
      ...(varient && { varient: varient.trim() }),
      ...(description && { description: description.trim() }),
    };
    setAddItemData(updatedItemData);
    if (productValidation(updatedItemData)) mutation.mutate(updatedItemData);
  };

  useEffect(() => {
    dispatch(setSiderBarActiveList({ name: "Items" }));
    dispatch(setSiderBarActiveLink({ link: "items" }));
  }, []);

  return (
    <div className={`main-panel`}>
      <Modal
        open={addCategoryVisible}
        onClose={(e) => {
          setCategory({ active: true });
          setEditCategoryState(false);
          setAddCategoryVisible(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        header="Add Category"
        className="col-sm-12"
      >
        <Box sx={style}>
          <h4>Category</h4>
          <form
            className="d-flex flex-row align-items-center gap-3 text-align-center "
            onSubmit={
              editCategoryState ? editCategory : createCategoryApiCallHandler
            }
          >
            <input
              type="text"
              className="form-control"
              placeholder="Category"
              name="name"
              value={category.name || ""}
              onChange={(e) => {
                handleCategoryInputChangeWithCondition(e, /^[\s\S]*$/);
              }}
              required
            />
            <div className="d-flex flex-row align-items-center justify-content-center">
              <input
                type="checkbox"
                id="categoryActive"
                name="active"
                checked={category.active || ""}
                onChange={(e) =>
                  setCategory((prev) => {
                    return { ...prev, [e.target.name]: e.target.checked };
                  })
                }
              />
              <label style={{ margin: "0 4px 0 6px" }} htmlFor="categoryActive">
                Active
              </label>
            </div>
            <div className="d-flex ">
              {editCategoryState ? (
                <button
                  className="btn btn-primary d-flex justify-content-center"
                  type="submit"
                >
                  Edit
                </button>
              ) : (
                <button
                  className="btn btn-primary d-flex justify-content-center px-3 py-2"
                  type="submit"
                >
                  Save
                </button>
              )}
            </div>
          </form>

          <div className="tabel-div">
            <DataTable
              title="Categories"
              value={categoryArray}
              paginator
              rows={5}
              style={{ width: "100%" }}
            >
              <Column
                className="border"
                header="SR. No."
                body={(rowData, { rowIndex }) => rowIndex + 1}
                style={{
                  height: "2.5rem",
                  alignItems: "center",
                  textAlign: "center",
                }}
              />

              <Column
                className="border"
                field="name"
                header="CATEGORY"
                style={{
                  height: "2.5rem",
                  alignItems: "center",
                  textAlign: "center",
                }}
              />

              <Column
                className="border"
                body={(data) => (
                  <div className="d-flex flex-row align-items-center justify-content-center">
                    {hasPermission(loggedUser, 17) && (
                      <i
                        onClick={(e) => {
                          setCategory({ name: data.name, active: data.active });
                          setEditCategoryState(data.id);
                        }}
                        className="dropdown-item-icon mdi mdi-pen text-primary me-2"
                      />
                    )}
                    {hasPermission(loggedUser, 18) && (
                      <i
                        onClick={(e) => deleteCategory(data.id)}
                        className="dropdown-item-icon mdi mdi-delete-forever text-primary me-2"
                      />
                    )}
                  </div>
                )}
                header="ACTIONS"
              />
            </DataTable>
          </div>
        </Box>
      </Modal>
      <Modal
        open={addBrandVisible}
        onClose={() => {
          setBrand({ active: true });
          setEditBrandState(false);
          setAddBrandVisible(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        header="Add Category"
        className="col-sm-12"
      >
        <Box sx={style}>
          <h4>Brand</h4>
          <form
            className="d-flex flex-row align-items-center  gap-4"
            onSubmit={editBrandState ? editBrand : createBrandApiCallHandler}
          >
            <input
              type="text"
              className="form-control"
              placeholder="Brand Name"
              name="name"
              required
              value={brand.name || ""}
              onChange={(e) => {
                handleBrandInputChangeWithCondition(e, /^[\s\S]*$/);
              }}
            />
            <div className="d-flex flex-row align-items-center justify-content-center">
              <input
                type="checkbox"
                name="active"
                id="brandActive"
                checked={brand.active || ""}
                onChange={(e) =>
                  setBrand((prev) => {
                    return { ...prev, [e.target.name]: e.target.checked };
                  })
                }
              />
              <label style={{ margin: "0 4px 0 6px" }} htmlFor="brandActive">
                Active
              </label>
            </div>

            <div className="d-flex">
              {editBrandState ? (
                <button
                  type="submit"
                  className="btn btn-primary d-flex justify-content-center"
                >
                  Edit
                </button>
              ) : (
                <button
                  type="submt"
                  className="btn btn-primary d-flex justify-content-center px-3 py-2"
                >
                  Save
                </button>
              )}
            </div>
          </form>

          <div className="tabel-div">
            <DataTable
              title="Categories"
              value={brandArray}
              paginator
              rows={5}
              style={{ width: "100%" }}
            >
              <Column
                className="border "
                header="SR. No."
                body={(rowData, { rowIndex }) => rowIndex + 1}
                style={{
                  height: "2.5rem",
                  alignItems: "center",
                  textAlign: "center",
                  minWidth: "5rem",
                }}
              />

              <Column
                className="border "
                field="name"
                header="BRAND"
                style={{
                  height: "2.5rem",
                  alignItems: "center",
                  textAlign: "center",
                  minWidth: "9rem",
                }}
              />

              <Column
                className="border "
                body={(data) => (
                  <div
                    style={{
                      minWidth: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {hasPermission(loggedUser, 20) && (
                      <i
                        onClick={(e) => {
                          setBrand({ name: data.name, active: data.active });
                          setEditBrandState(data.id);
                        }}
                        className="dropdown-item-icon mdi mdi-pen text-primary me-2"
                      />
                    )}
                    {hasPermission(loggedUser, 21) && (
                      <i
                        onClick={(e) => deleteBrand(data.id)}
                        className="dropdown-item-icon mdi mdi-delete-forever text-primary me-2"
                      />
                    )}
                  </div>
                )}
                header="ACTIONS"
              />
            </DataTable>
          </div>
        </Box>
      </Modal>

      <Modal
        open={addTaxVisible}
        onClose={(e) => {
          setTax({ active: true });
          setEditTaxState(false);
          setAddTaxVisible(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        header="Add Category"
        className="col-sm-12"
      >
        <Box sx={style}>
          <h4>Add Tax</h4>
          <form onSubmit={editTaxState ? editTax : createTaxApiCallHandler}>
            <div className="d-flex flex-row align-items-center gap-3 mb-2">
              <input
                type="text"
                className="form-control"
                placeholder="Tax Name"
                name="name"
                value={tax.name || ""}
                onChange={(e) => {
                  handleTaxInputChangeWithCondition(e, /^[\s\S]*$/);
                }}
                required
              />
              <input
                type="text"
                className="form-control"
                placeholder="Tax (%)"
                minLength={1}
                maxLength={3}
                name="rate"
                value={tax.rate || ""}
                onChange={(e) =>
                  handleTaxInputChangeWithConditonTypeNumber(e, /^[0-9\b]+$/)
                }
                required
              />
            </div>

            <div className="col-6 ">
              <label>Active ? </label>{" "}
              <input
                type="checkbox"
                name="active"
                checked={tax.active || ""}
                onChange={(e) =>
                  setTax((prev) => {
                    return { ...prev, active: e.target.checked };
                  })
                }
              />
            </div>

            {editTaxState ? (
              <button
                type="submiit"
                className="btn btn-primary d-flex justify-content-center mt-2"
              >
                Edit
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-primary d-flex justify-content-center  mt-2 px-3 py-2"
              >
                Save
              </button>
            )}
          </form>
          <div className="tabel-div">
            <DataTable
              title="Categories"
              value={taxArray}
              paginator
              rows={5}
              style={{ width: "100%" }}
            >
              <Column
                className="border "
                header="SR. No."
                body={(rowData, { rowIndex }) => rowIndex + 1}
                style={{
                  height: "2.5rem",
                  alignItems: "center",
                  textAlign: "center",
                }}
              />

              <Column
                className="border "
                field="name"
                header="NAME"
                style={{
                  height: "2.5rem",
                  alignItems: "center",
                  textAlign: "center",
                }}
              />
              <Column
                className="border "
                field="rate"
                header="TAX"
                style={{
                  height: "2.5rem",
                  alignItems: "center",
                  textAlign: "center",
                }}
              />

              <Column
                className="border "
                body={(data) => (
                  <div
                    style={{
                      minWidth: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {hasPermission(loggedUser, 23) && (
                      <i
                        onClick={(e) => {
                          setTax({
                            name: data.name,
                            rate: data.rate,
                            active: data.active,
                          });
                          setEditTaxState(data.id);
                        }}
                        className="dropdown-item-icon mdi mdi-pen text-primary me-2"
                      />
                    )}
                    {hasPermission(loggedUser, 24) && (
                      <i
                        onClick={(e) => deleteTax(data.id)}
                        className="dropdown-item-icon mdi mdi-delete-forever text-primary me-2"
                      />
                    )}
                  </div>
                )}
                header="ACTIONS"
              />
            </DataTable>
          </div>
        </Box>
      </Modal>
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-12">
            <div className="d-flex justify-content-between m-1 p-1 align-items-baseline ">
              <h3 className="ukhd mb-3">Add Item</h3>
              <button
                type="button"
                className="btn btn-warning btn-sm"
                onClick={() => navigate("/items")}
              >
                <div className="d-flex justify-content-center">
                  <i className="mdi mdi mdi-keyboard-backspace"></i>
                  <span>Back</span>
                </div>
              </button>
            </div>
            <div className="row flex-grow">
              <div className="col-12 grid-margin stretch-card">
                <div className="card card-rounded">
                  <div className="card-body">
                    <div className="d-flex mt-2 mb-2 justify-content-start">
                      <h5>ITEM INFORMATION</h5>
                    </div>

                    <form className="row" onSubmit={onSubmitHandler}>
                      <div className="col-lg-3  col-md-4 col-sm-6 mb-2">
                        <label htmlFor="name " className="w-100  mb-2">
                          {isProduct ? "Product Name *" : "Service Name *"}
                        </label>
                        <input
                          value={addItemData.name || ""}
                          onChange={(e) => {
                            handleInputChangeWithCondition(e, /^[\s\S]*$/);
                          }}
                          type="text"
                          className="form-control w-100"
                          placeholder={
                            isProduct ? "Product Name" : "Service Name"
                          }
                          name="name"
                          required
                        />
                      </div>
                      <div className="col-lg-3  col-md-4 col-sm-6 mb-2">
                        <label htmlFor="p-name " className="w-100  mb-2">
                          {isProduct ? "Product Variant" : "Service Variant"}
                        </label>
                        <input
                          value={addItemData.varient || ""}
                          onChange={(e) => {
                            handleInputChangeWithCondition(e, /^[\s\S]*$/);
                          }}
                          type="text"
                          className="form-control w-100"
                          placeholder={
                            isProduct ? "Product Variant" : "Service Variant"
                          }
                          name="varient"
                        />
                      </div>
                      <div className="col-lg-3  col-md-4 col-sm-6 mb-2">
                        <label
                          htmlFor="p-name "
                          className="w-100 d-flex justify-content-start align-items-center  mb-2"
                        >
                          Category{" "}
                          {hasPermission(loggedUser, 16) && (
                            <span className="" style={{ cursor: "pointer" }}>
                              <i
                                onClick={() => setAddCategoryVisible(true)}
                                className="mdi text-success  fw-bold mdi-plus"
                              />
                            </span>
                          )}
                        </label>

                        <Select
                          name="categoryName"
                          className="w-100"
                          value={{
                            label: addItemData.categoryName || "",
                            value: addItemData.categoryName || "",
                          }}
                          onChange={(selectedOption) => {
                            onChangeInput(
                              selectedOption?.value || "",
                              "categoryName"
                            );
                          }}
                          isClearable
                          options={categoryArray.map((category) => ({
                            label: category.name,
                            value: category.name,
                          }))}
                          styles={selectCustomStyle}
                        />
                      </div>
                      <div className="col-lg-3  col-md-4 col-sm-6 mb-2">
                        <label
                          htmlFor="p-name "
                          className="w-100 d-flex justify-content-start align-items-center  mb-2"
                        >
                          Brand{" "}
                          {hasPermission(loggedUser, 19) && (
                            <span className="" style={{ cursor: "pointer" }}>
                              <i
                                onClick={(e) => setAddBrandVisible(true)}
                                className="mdi text-success  fw-bold mdi-plus"
                              />
                            </span>
                          )}
                        </label>
                        <Select
                          name="brandName"
                          className="w-100"
                          value={{
                            label: addItemData.brandName || "",
                            value: addItemData.brandName || "",
                          }}
                          onChange={(selectedOption) => {
                            onChangeInput(
                              selectedOption?.value || "",
                              "brandName"
                            );
                          }}
                          isClearable
                          options={brandArray.map((brand) => ({
                            label: brand.name,
                            value: brand.name,
                          }))}
                          styles={selectCustomStyle}
                        />
                      </div>
                      <div className="col-lg-3  col-md-4 col-sm-6 mb-2">
                        <label
                          htmlFor="p-name "
                          className="w-100 d-flex justify-content-start align-items-center  mb-2"
                        >
                          Type{" "}
                        </label>
                        <Select
                          name="type"
                          className="w-100"
                          value={{
                            label: addItemData.type || "",
                            value: addItemData.type || "",
                          }}
                          onChange={(selectedOption) => {
                            handleItemTypeChange(
                              selectedOption?.value || "",
                              "type"
                            );
                          }}
                          options={[
                            { label: "product", value: "product" },
                            { label: "service", value: "service" },
                          ]}
                          styles={selectCustomStyle}
                        />
                      </div>

                      <div className="col-lg-3  col-md-4 col-sm-6 mb-2">
                        <label htmlFor="p-name " className="w-100  mb-2">
                          {isProduct ? "HSN/SAC Code" : "SAC Code"}
                        </label>
                        <Select
                          name={isProduct ? "hsn_code" : "sac_code"}
                          className="w-100"
                          value={{
                            label: isProduct
                              ? addItemData.hsn_code || ""
                              : addItemData.sac_code || "",
                            value: isProduct
                              ? addItemData.hsn_code || ""
                              : addItemData.sac_code || "",
                          }}
                          onChange={(selectedOption) => {
                            onChangeInput(
                              selectedOption?.value || "",
                              isProduct ? "hsn_code" : "sac_code"
                            );
                          }}
                          isClearable
                          options={codeArray.map(({ code }) => ({
                            label: code,
                            value: code,
                          }))}
                          styles={selectCustomStyle}
                        />
                      </div>
                      <div className="col-lg-3  col-md-4 col-sm-6 mb-2">
                        <label
                          htmlFor="p-name "
                          className="w-100 d-flex justify-content-start align-items-center  mb-2"
                        >
                          Tax{" "}
                          {hasPermission(loggedUser, 22) && (
                            <span className="" style={{ cursor: "pointer" }}>
                              <i
                                onClick={() => setAddTaxVisible(true)}
                                className="mdi text-success  fw-bold mdi-plus"
                              />
                            </span>
                          )}
                        </label>
                        <Select
                          name="taxName"
                          className="w-100"
                          value={{
                            label: addItemData.taxName || "",
                            value: addItemData.taxName || "",
                          }}
                          onChange={(selectedOption) => {
                            onChangeInput(
                              selectedOption?.value || "",
                              "taxName"
                            );
                          }}
                          isClearable
                          options={taxArray.map((tax) => ({
                            label: tax.name,
                            value: tax.name,
                          }))}
                          styles={selectCustomStyle}
                        />
                      </div>

                      <div className="col-lg-3  col-md-4 col-sm-6 mb-2">
                        <label htmlFor="p-name " className="w-100  mb-2">
                          MRP Price
                        </label>
                        <input
                          type="text"
                          className="form-control w-100"
                          placeholder="MRP Price"
                          name="mrp_price"
                          value={addItemData.mrp_price || ""}
                          onChange={(e) =>
                            handleInputChangeWithConditonTypeNumber(
                              e,
                              /^[0-9\b]+$/
                            )
                          }
                        />
                      </div>
                      <div className="col-lg-3  col-md-4 col-sm-6 mb-2">
                        <label htmlFor="p-name " className="w-100  mb-2">
                          Purchase Price
                        </label>
                        <input
                          value={addItemData.p_price || ""}
                          onChange={(e) =>
                            handleInputChangeWithConditonTypeNumber(
                              e,
                              /^[0-9\b]+$/
                            )
                          }
                          type="text"
                          className="form-control w-100"
                          placeholder="Purchase Price"
                          name="p_price"
                        />
                      </div>
                      <div className="col-lg-3  col-md-4 col-sm-6 mb-2">
                        <label htmlFor="p-name " className="w-100  mb-2">
                          Sales Price*(Net)
                        </label>
                        <input
                          value={addItemData.s_price || ""}
                          onChange={(e) =>
                            handleInputChangeWithConditonTypeNumber(
                              e,
                              /^[0-9\b]+$/
                            )
                          }
                          type="text"
                          className="form-control w-100"
                          placeholder="Sales Price*(Net)"
                          name="s_price"
                          required
                        />
                      </div>
                      <div className="col-lg-3  col-md-4  col-sm-6 mb-2">
                        <label
                          htmlFor="p-name "
                          className="w-100 d-flex justify-content-start align-items-center  mb-2"
                        >
                          UoM (Unit Of Measurement){" "}
                        </label>
                        <Select
                          name="UOM"
                          className="w-100"
                          value={{
                            label: addItemData.UOM || "",
                            value: addItemData.UOM || "",
                          }}
                          onChange={(selectedOption = {}) => {
                            onChangeInput(selectedOption?.value || "", "UOM");
                          }}
                          isClearable
                          options={unitOfMeasurement.map((UoM) => ({
                            label: UoM,
                            value: UoM,
                          }))}
                          styles={selectCustomStyle}
                        />
                      </div>
                      <div className="col-lg-3  col-md-4  col-sm-6 mb-2">
                        <label htmlFor="p-name " className="w-100  mb-2">
                          Opening Qty per
                        </label>
                        <input
                          type="text"
                          className="form-control w-100"
                          placeholder="Opening Qty per"
                          name="opening_qty_per"
                          value={addItemData.opening_qty_per || ""}
                          onChange={(e) =>
                            handleInputChangeWithConditonTypeNumber(
                              e,
                              /^[0-9\b]+$/
                            )
                          }
                        />
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                        <label htmlFor="p-name " className="w-100  mb-2">
                          Description
                        </label>
                        <textarea
                          style={{
                            height: "auto",
                            minHeight: "100px", // Adjust this value based on your requirement
                          }}
                          className="form-control w-100"
                          placeholder="Description"
                          name="description"
                          value={addItemData.description || ""}
                          onChange={onChangeInput}
                        />
                      </div>

                      <div className="col-12">
                        <button
                          className="btn btn-primary d-flex justify-content-center px-3 py-2"
                          type="submit"
                        >
                          <i className="mdi mdi-content-save me-1"></i> Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProductService;
