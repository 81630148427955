import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setSiderBarActiveLink,
  setSiderBarActiveList,
} from "../redux-store/sidebarActiveList";
import { expiryDetails } from "../utils/helpers";
import SecondSpinner from "../spinner/third_spinner/SecondSpinner";

const PleaseUpgrade = () => {
  const navigate = useNavigate();
  const { companyData } = useSelector((state) => state.Company);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (companyData && !isEmpty(companyData)) {
      if (expiryDetails(companyData?.expired)?.isShow) {
        setIsPageLoading(false);
      } else {
        navigate("/");
      }
    }
  }, [companyData]);

  useEffect(() => {
    dispatch(setSiderBarActiveList({ name: null }));
    dispatch(setSiderBarActiveLink({ link: null }));
  }, []);
  return (
    <div className="main-panel">
      <div className="content-wrapper">
        {isPageLoading ? (
          <SecondSpinner />
        ) : (
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-rounded">
                <div
                  className="card-body"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #FFC371, #FF5F6D)",
                    backgroundSize: "cover", // Makes the image cover the entire div
                    backgroundRepeat: "no-repeat",
                    borderRadius: "12px",
                  }}
                >
                  <div className="col-12" style={{ textAlign: "right" }}>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={() => navigate("/")}
                    ></button>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }} className="mt-4">
                    <h4>
                      To enjoy uninterrupted services, subscribe right away.
                      Pick a plan that works for you.
                    </h4>
                  </div>
                  <form className="col-12 d-flex align-items-center justify-content-center mt-4">
                    <button
                      type="button"
                      className="btn btn-success d-flex align-items-center justify-content-center"
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                      }}
                      onClick={() => navigate("/pick/plan")}
                    >
                      Upgrade
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PleaseUpgrade;

function isEmpty(obj) {
  return obj && Object.keys(obj).length === 0;
}
