import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import toast from "react-hot-toast";
import { Button } from "primereact/button";
import Select from "react-select";
import Modal from "@mui/material/Modal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Footer from "../../helper/Footer";
import { paymentApi } from "../../assets/apis";
import { dateFormateDDMMYYYY, hasPermission } from "../../utils/helpers";
import { Box } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { selectCustomStyle, selectStyle } from "../../assets/selectCustomStyle";
import { firstDayOfYear, lastDayOfYear, todayDate } from "../../assets/Date";
import {
  setSiderBarActiveLink,
  setSiderBarActiveList,
} from "../../redux-store/sidebarActiveList";
import { Link, useNavigate } from "react-router-dom";

const paymentMethods = [
  { label: "Cash", value: "Cash" },
  { label: "UPI", value: "UPI" },
  { label: "Credit Card", value: "Credit Card" },
  { label: "Cheque", value: "Cheque" },
  { label: "Bank Transfer", value: "Bank Transfer" },
  { label: "Pay Slip", value: "Pay Slip" },
  { label: "EMI", value: "EMI" },
  { label: "Other", value: "Other" },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Payments = () => {
  const { FYear } = useSelector((store) => store.FinancialYear);
  const { loggedUser } = useSelector((store) => store.Auth);
  const { companyData } = useSelector((state) => state.Company);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [editState, setEditState] = useState(false);
  const [visible, setVisible] = useState(false);
  const [paymentArray, setPaymentArray] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [addPayment, setAddPayment] = useState({ date: todayDate });
  const [apiInProccess, setApiInProccess] = useState(false);

  const [filters, setFilters] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });

  const [queryParams, setQueryParams] = useState({ page: 1, pageSize: 10 });

  //get all payments login
  const fetchPayments = async ({ queryKey }) => {
    const [, queryParamsData] = queryKey;
    const queryParams = new URLSearchParams(queryParamsData);
    try {
      const response = await axios.get(`${paymentApi}?${queryParams}`);
      const { payments, totalPages } = response.data;
      setPaymentArray(payments || []);
      setTotalPages(totalPages || 1);
      return response.data;
    } catch (error) {
      if (error?.response?.status === 403) return navigate("/upgrade");
    }
  };

  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: ["payment", queryParams],
    queryFn: fetchPayments,
    retry: false,
  });

  //filter search logic
  const onChangeFilterHandler = (e) => {
    const { name, value } = e.target;
    setFilters((pre) => ({ ...pre, [name]: value }));
  };
  const onChangeFilterHandlerWithCondition = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setFilters((prev) => ({ ...prev, [name]: value }));
    }
  };

  const onSearchHandler = (e) => {
    e.preventDefault();
    const { startDate, endDate } = filters;
    if (startDate && endDate && startDate > endDate)
      return toast.error("Start date must be less than or equal to end date");
    setQueryParams((pre) => ({ ...pre, ...filters, ...pagination }));
  };

  const onResetHandler = () => {
    setFilters({});
    setQueryParams((pre) => ({ page: 1, pageSize: 10 }));
    setPagination({ page: 1, pageSize: 10 });
  };

  useEffect(() => {
    setQueryParams((pre) => ({ ...pre, ...pagination }));
  }, [pagination.page, pagination.pageSize, pagination]);

  //create new payment logic
  const savePayment = async (e) => {
    e.preventDefault();
    if (parseFloat(addPayment.amount) < parseFloat(addPayment.pay)) {
      return toast.error("You can not pay more than invoiced amount");
    } else if (
      !addPayment.invoice ||
      !addPayment.date ||
      !parseFloat(addPayment.pay)
    ) {
      return toast.error("Please fill all required fields");
    }
    const payload = {
      name: addPayment.name,
      invoice_no: addPayment.invoice.label,
      invoice_amount: parseFloat(addPayment.amount),
      paid_amount: parseFloat(addPayment.pay),
      method: addPayment.method.label,
      date: addPayment.date,
      note: addPayment.note,
      type: 0,
    };
    setApiInProccess(true);

    try {
      const response = await axios.post(paymentApi, payload);

      if (response.status === 200) {
        toast.success("Payment added successfully");
        setVisible(false);
        refetch();
        fetchInv();
        setAddPayment({ date: todayDate });
      } else {
        if (error?.response?.status === 403) return navigate("/upgrade");
        toast.error("Failed to add payment. Please try again.");
      }
    } catch (error) {
      if (error?.response?.status === 403) return navigate("/upgrade");
      console.error(error);
      const message=error?.response?.data?.message
      toast.error(message||"An error occurred while adding payment");
    } finally {
      setApiInProccess(false);
    }
  };

  // const editPayment = async () => {
  //   try {
  //     const response = await axios.put(
  //       `${paymentApi}/${editState}`,
  //       addPayment
  //     );

  //     if (response.status === 200) {
  //       toast.success("Payment edited successfully");
  //       setVisible(false);
  //       refetch();
  //       setAddPayment({
  //         name: "",
  //         amount: "",
  //         description: "",
  //       });
  //       setEditState(false);
  //     } else {
  //       toast.error("Failed to edit payment. Please try again.");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     toast.error("An error occurred while editing payment");
  //   }
  // };

  // const deletePayment = async (id) => {
  //   try {
  //     const response = await axios.delete(`${paymentApi}/${id}`);

  //     if (response.status === 200) {
  //       toast.success("Payment deleted successfully");
  //       refetch();
  //     } else {
  //       toast.error("Failed to delete payment. Please try again.");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     toast.error("An error occurred while deleting payment");
  //   }
  // };

  // const setEditData = (data) => {
  //   setEditState(data.name);
  //   setAddPayment({
  //     name: data.name,
  //     amount: data.amount,
  //     description: data.description,
  //   });
  //   setVisible(true);
  // };

  const onHandleInputChange = (e, select_name) => {
    setAddPayment((prev) => {
      if (select_name) {
        if (select_name === "invoice") {
          const name = e?.name || "";
          const amount = e?.balance || "";
          const pay = e?.balance;
          return { ...prev, invoice: e, name, amount, pay };
        }
        return { ...prev, [select_name]: e };
      }
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleInputChangeWithConditonTypeNumber = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setAddPayment((prev) => ({ ...prev, [name]: Number(value) }));
    }
  };

  const handleAddSalesInputChangeWithConditonTypeFloat = (
    e,
    validationRegex
  ) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setAddPayment((prev) => ({ ...prev, [name]: value }));
    }
  };

  //fetch all invoice
  const fetchInv = async () => {
    try {
      const respon = await axios.get(paymentApi + "/get_inv");
      if (respon.status === 200) {
        setInvoices(
          respon.data.salesInvoices?.map((i) => ({
            ...i,
            label: i.invoice,
            value: i.invoice,
          })) || []
        );
      }
    } catch (error) {
      if (error?.response?.status === 403) return navigate("/upgrade");
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    refetch();
    fetchInv();
    return () => {
      setVisible(false);
    };
  }, [FYear.value]);

  useEffect(() => {
    dispatch(setSiderBarActiveList({ name: "Invoices" }));
    dispatch(setSiderBarActiveLink({ link: "payment_records" }));
  }, []);

  return (
    <div className="main-panel">
      <Modal
        open={visible}
        onClose={() => {
          setVisible(false);
          setEditState(false);
          setAddPayment({
            date: todayDate,
          });
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        header="Add Payment"
      >
        <Box sx={style}>
          <form className="row" onSubmit={savePayment}>
            <div className="col-lg-4 mt-2">
              <label>Invoice No *</label>
              <Select
                styles={selectCustomStyle}
                name="invoice"
                className="w-100"
                value={addPayment.invoice}
                onChange={(val) => onHandleInputChange(val, "invoice")}
                options={invoices}
                required
              />
            </div>
            <div className="col-lg-4 mt-2">
              <label>Client Name *</label>
              <input
                type="text"
                className="form-control"
                placeholder="name"
                name="name"
                value={addPayment.name || ""}
                readOnly={true}
                required
              />
            </div>

            <div className="col-lg-4 mt-2">
              <label>Invoice Amount*</label>{" "}
              <input
                type="number"
                className="form-control"
                placeholder="Amount"
                name="amount"
                value={addPayment.amount || ""}
                readOnly={true}
                required
              />
            </div>
            <div className="col-lg-4 mt-2">
              <label>Payment Method*</label>
              <Select
                styles={selectCustomStyle}
                name="method"
                className="w-100"
                value={addPayment.method}
                onChange={(val) => onHandleInputChange(val, "method")}
                options={paymentMethods}
                required
              />
            </div>
            <div className="col-lg-4 mt-2">
              <label>Pay*</label>{" "}
              <input
                type="text"
                className="form-control"
                placeholder="amount"
                name="pay"
                value={addPayment.pay || ""}
                onChange={(e) =>
                  handleAddSalesInputChangeWithConditonTypeFloat(
                    e,
                    /^\d*\.?\d{0,2}$/
                  )
                }
                required
              />
            </div>
            <div className="col-lg-4 mt-2">
              <label>Date*</label>{" "}
              <input
                type="date"
                className="form-control"
                placeholder="date"
                min={firstDayOfYear}
                max={lastDayOfYear}
                name="date"
                value={addPayment.date || ""}
                onChange={(e) => onHandleInputChange(e)}
                required
              />
            </div>
            <div className="col-lg-6 mt-2">
              <label>Note</label>{" "}
              <input
                type="text"
                className="form-control"
                placeholder="note"
                name="note"
                value={addPayment.note || ""}
                onChange={(e) => onHandleInputChange(e)}
              />
            </div>
            <div className="col-lg-7 mt-2">
              <Button
                className="btn btn-primary px-3 py-2"
                type="submit"
                disabled={apiInProccess}
              >
                Save
              </Button>
            </div>
          </form>
        </Box>
      </Modal>

      <div className="content-wrapper  ">
        <div id="first" className="card col-lg-12 p-3">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="card-title">
              {" "}
              Payment Records{" "}
              <i
                className="fa fa-info-circle text-secondary hover-cursor"
                data-toggle="tooltip"
                title="Balance of the amount received from the client against multiple invoices"
                aria-hidden="true"
                data-original-title="Contacts who can become our Future clients"
              ></i>
            </div>
            {hasPermission(loggedUser, 40) && (
              <button
                className="btn btn-primary px-2 py-1"
                onClick={() => setVisible(true)}
              >
                Add Payment
              </button>
            )}
          </div>
          <form className="row" onSubmit={onSearchHandler}>
            <div className="col-lg-2">
              <div className="form-group">
                <label className="form-control-label" htmlFor="name">
                  Client Name
                </label>
                <input
                  type="text"
                  className="form-control form-control-alternative"
                  name="name"
                  id="name"
                  value={filters.name || ""}
                  onChange={(e) =>
                    onChangeFilterHandlerWithCondition(e, /^[\s\S]*$/)
                  }
                />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="form-group">
                <label className="form-control-label" htmlFor="method">
                  Payment Method
                </label>
                <select
                  className="form-control form-control-alternative"
                  name="method"
                  id="method"
                  style={selectStyle}
                  value={filters.method || ""}
                  onChange={onChangeFilterHandler}
                >
                  <option value="">-&nbsp;-select-&nbsp;-</option>
                  {paymentMethods.map((method, index) => (
                    <option key={index} value={method.value}>
                      {method.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="form-group">
                <label className="form-control-label" htmlFor="type">
                  Type
                </label>
                <select
                  className="form-control form-control-alternative"
                  name="type"
                  id="type"
                  style={selectStyle}
                  value={filters.type || ""}
                  onChange={onChangeFilterHandler}
                >
                  <option value="">-&nbsp;-select-&nbsp;-</option>
                  <option value="sales">Sales</option>
                  <option value="expense">Expense</option>
                </select>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="form-group">
                <label className="form-control-label" htmlFor="startDate">
                  Start Date
                </label>
                <input
                  type="date"
                  className="form-control form-control-alternative"
                  name="startDate"
                  id="startDate"
                  value={filters.startDate || ""}
                  onChange={onChangeFilterHandler}
                />
              </div>
            </div>

            <div className="col-lg-2">
              <div className="form-group">
                <label className="form-control-label" htmlFor="endDate">
                  End Date
                </label>
                <input
                  type="date"
                  className="form-control form-control-alternative"
                  name="endDate"
                  id="endDate"
                  value={filters.endDate || ""}
                  onChange={onChangeFilterHandler}
                />
              </div>
            </div>

            <div
              className="col-lg-2 text-right"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <div className="form-group mt-1">
                <button
                  type="submit"
                  id="search"
                  className="btn btn-primary btn-sm mt-4"
                >
                  search
                </button>
              </div>
              <div className="form-group mt-1">
                <button
                  type="button"
                  id="reset"
                  className="btn btn-primary btn-sm mt-4"
                  onClick={onResetHandler}
                >
                  Reset
                </button>
              </div>
            </div>
          </form>

          <div className="d-flex flex-column  align-items-center">
            <DataTable
              value={paymentArray}
              rowsPerPageOptions={[5, 10, 20]}
              style={{ width: "100%" }}
              className="mytable"
            >
              <Column
                className="border-bottom border-top"
                header="SR. No."
                body={(rowData, { rowIndex }) => (
                  <p>
                    {pagination.page * pagination.pageSize -
                      pagination.pageSize +
                      rowIndex +
                      1}
                  </p>
                )}
                style={{minWidth:"4rem"}}
              />

              <Column
                className="border-bottom border-top"
                field="name"
                header="NAME"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "6rem",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="invoice_no"
                header="INVOICE"
                body={(rowData) => {
                  const prefix =
                  rowData.type === 1
                  ? companyData?.purchase_inv_prefix
                  : companyData?.sales_inv_prefix;
                  const invoiceNo = `${prefix?prefix: rowData.type === 1?"EXP-":"INV-"}${rowData.invoice_no}`;
                  return (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={
                        rowData.type === 1
                          ? `/invoices/view/expenses/${rowData.invoice_no}`
                          : `/invoices/view/sales/${rowData.invoice_no}`
                      }
                      target="_blank"
                    >
                      {invoiceNo}
                    </Link>
                  );
                }}
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "6rem",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="method"
                header="METHOD"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "6rem",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="total"
                header="Total"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "6rem",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="total_paid_amount"
                header="Paid"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "6rem",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="balance"
                header="Balance"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "6rem",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="date"
                body={(data) => dateFormateDDMMYYYY(data.date)}
                header="DATE"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "6rem",
                }}
              />
              <Column
                className="border-bottom border-top"
                header="TYPE"
                body={(data) => (data.type === 1 ? "Expense" : "Sales")}
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "6rem",
                }}
              />
              {/* <Column
                className="border-bottom border-top"
                header="NOTE"
                body={(data) => {
                  const truncatedNote = data.note
                    ? `${data.note.substring(0, 10)}...`
                    : "--";
                  return (
                    <span className="hover-cursor" title={data.note || "--"}>
                      {truncatedNote}
                    </span>
                  );
                }}
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "6rem",
                }}
              /> */}
              <Column
                className="border-bottom border-top"
                header="ACTIONS"
                body={(data) => (
                  <div
                    style={{
                      minWidth: "50%",
                      // padding:"0 1rem",
                      display: "flex",
                      // justifyContent: "space-around",
                    }}
                  >
                    <i
                      style={{ cursor: "pointer" }}
                      className="dropdown-item-icon mdi mdi-eye text-primary me-2"
                      title="View Payment Details"
                      onClick={() =>
                        navigate(
                          `/invoices/pay/${
                            data.type === 1 ? "expenses" : "sales"
                          }/${data.invoice_no}`
                        )
                      }
                    ></i>
                  </div>
                )}
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "6rem",
                }}
              />
            </DataTable>
            <div
              className=""
              style={{
                display: "flex",
                width: "80%",
                justifyContent: "space-evenly",
                margin: "10px 0 0 0",
              }}
            >
              <button
                style={{ border: "none", fontSize: "24px" }}
                disabled={pagination.page === 1}
                onClick={() =>
                  setPagination((prev) => {
                    return { ...prev, page: prev.page - 1 };
                  })
                }
              >
                <i className="mdi mdi-chevron-left" />
              </button>
              <span>
                Page {pagination.page} of {totalPages}
              </span>
              <button
                style={{ border: "none", fontSize: "24px" }}
                disabled={pagination.page === totalPages}
                onClick={() =>
                  setPagination((prev) => {
                    return { ...prev, page: prev.page + 1 };
                  })
                }
              >
                <i className="mdi mdi-chevron-right" />
              </button>
              <select
                value={pagination.pageSize}
                onChange={(e) =>
                  setPagination((prev) => {
                    return { ...prev, pageSize: e.target.value };
                  })
                }
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="30">30</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payments;
