// styles.js
export const tableStyle = {
    tableLayout: "fixed",
    width: "100%",
    border: "1px solid gray"
  };
  
  export const tdThStyle = {
    padding: ".8rem .8rem",
    border: "1px solid gray",
    lineHeight: 1.6,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "wrap",
  };
  