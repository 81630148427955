export const selectCustomStyle = {
  control: (provided) => ({
    ...provided,
    width: "100%",
    padding: "0.125rem",
    fontSize: "0.875rem",
    lineHeight: "normal",
    fontWeight: 400,
    color: "#212529",
    // backgroundColor: "white",
    backgroundClip: "padding-box",
    border: "1px solid #ced4da",
    borderRadius: "4px",
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
    // appearance: "none",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#212529",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#212529",
  }),
};

export const selectStyle = {
  width: "100%",
  padding: "0.675rem 0.675rem",
  fontSize: "0.875rem",
  fontWeight: "400",
  lineHeight: "normal",
  color: "#212529",
  background: "white", // Corrected background color
  backgroundClip: "padding-box",
  appearance: "auto",
  borderRadius: "4px", // Corrected borderRadius
  transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
};
