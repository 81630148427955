import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { categoryApi } from "../../../assets/apis";
import {
  setSiderBarActiveLink,
  setSiderBarActiveList,
} from "../../../redux-store/sidebarActiveList";
import { useDispatch } from "react-redux";
const AddCategory = () => {
  const [addCategory, setAddCategory] = useState({ active: true });
  const [apiInProccess, setApiInProccess] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setAddCategory((pre) => ({
      ...pre,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleInputChangeWithCondition = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setAddCategory((prev) => ({ ...prev, [name]: value }));
    }
  };

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data) => {
      return axios.post(categoryApi, data);
    },
    onSuccess: () => {
      setApiInProccess(false);
      queryClient.invalidateQueries(["category"]);
      toast.success("Category saved successfully!");
      navigate("/categories");
    },
    onError: (error) => {
      setApiInProccess(false);
      const errorMessage = error.response?.data?.message;
      toast.error(` ${errorMessage}`);
    },
  });

  const handleSaveCategory = (e) => {
    e.preventDefault();
    const { name, description } = addCategory;
    let updatedCategory = {
      ...addCategory,
      name: name.trim(),
      ...(description && { description: description.trim() }),
    };
    setAddCategory(updatedCategory);
    if (!updatedCategory.name) return toast.error("Please enter Category name");
    setApiInProccess(true);

    mutation.mutate(updatedCategory);
  };

  useEffect(() => {
    dispatch(setSiderBarActiveList({ name: "Items" }));
    dispatch(setSiderBarActiveLink({ link: "categories" }));
  }, []);

  return (
    <div className={`main-panel`}>
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-12">
            <div className="d-flex justify-content-between m-1 p-1 align-items-baseline ">
              <h3 className="ukhd mb-3">Add Category</h3>
              <button
                type="button"
                className="btn btn-warning btn-sm"
                onClick={() => navigate("/categories")}
              >
                <div className="d-flex justify-content-center">
                  <i className="mdi mdi mdi-keyboard-backspace"></i>
                  <span>Back</span>
                </div>
              </button>
            </div>
            <div className="row flex-grow">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card card-rounded">
                  <div className="card-body">
                    <div className="col-lg-12">
                      <div className="col-lg-12 ">
                        <h5>CATEGORY INFORMATION</h5>
                      </div>
                      <form onSubmit={handleSaveCategory}>
                        <div className="col-lg-4 mt-4">
                          <label>Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Category"
                            name="name"
                            required
                            value={addCategory.name || ""}
                            onChange={(e) => {
                              handleInputChangeWithCondition(
                                e,
                                /^[\s\S]*$/
                              );
                            }}
                          />
                        </div>
                        <div className="col-lg-6 mt-4">
                          <label>Active ? </label>{" "}
                          <input
                            type="checkbox"
                            name="active"
                            checked={addCategory.active || ""}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="col-lg-9 mt-4">
                          <label
                            className="form-control-label"
                            htmlFor="input-address"
                          >
                            Description
                          </label>
                          <textarea
                            className="form-control form-control-alternative"
                            rows="7"
                            style={{
                              borderRadius: "5px",
                              resize: "none",
                              minHeight: "80px",
                            }}
                            name="description"
                            value={addCategory.description || ""}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="col-lg-9 mt-2">
                          <button
                            type="submit"
                            className="btn btn-primary d-flex justify-content-center px-3 py-2"
                            disabled={apiInProccess}
                          >
                            <i className="mdi mdi-content-save me-1"></i> Save
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;
