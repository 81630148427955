import React, { useState } from "react";
import AppLogo from "../../assets/AppLogo";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { forgotPasswordApi } from "../../assets/apis";
import toast from "react-hot-toast";

const Forgot = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await axios.post(forgotPasswordApi, { email });
      const response = result?.data;
      toast.success(
        response?.message ||
          "A reset email has been sent please reset your password"
      );
      setTimeout(() => navigate("/auth/login"), 3000);
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong";
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="content-wrapper d-flex align-items-center auth px-0" style={{height:"100vh"}}>
      <div className="row w-100 mx-0">
        <div className="col-lg-4 mx-auto">
          <div className="auth-form-light text-left py-5 px-4 px-sm-5">
            <div className="brand-logo" style={{ height: "40px" }}>
              <AppLogo />
            </div>
            <div>
              <div className="d-flex justify-content-between">
                <h4>Forgot Password</h4>
              </div>
            </div>
            <form className="pt-3" onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control form-control-lg"
                  id="email"
                  placeholder="Email address"
                  autoComplete="current-password"
                  onChange={handleInputChange}
                  value={email || ""}
                  required
                />
              </div>
              <button
                className="btn btn-block btn-primary"
                type="submit"
                disabled={loading}
              >
                Send
              </button>
            </form>
            <div className="text-center mt-4 fw-light">
              Do you remember your password?
              <Link to="/auth/login" className="text-primary">
                login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgot;
