import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  token: localStorage.getItem("Token") || null,
  loggedUser: {},
};

if (initialState.token) {
  axios.defaults.headers["token"] = initialState.token;
}

const AuthSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    loginSuccess(state, action) {
      const { user, token } = action.payload;
      localStorage.setItem("Token", action.payload.token);
      axios.defaults.headers["token"] = action.payload.token;
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        loggedUser: user,
        token: token,
      };
    },
    setLogout(state) {
      // localStorage.clear(); for clear all localStorage for this site
      localStorage.removeItem("Token");
      axios.defaults.headers["token"] = null;
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        loggedUser: {},
      };
    },
    loadUserRequest(state, action) {
      return {
        ...state,
        isAuthenticated: false,
        loading: true,
      };
    },
    loadUserSuccess(state, action) {
      const { user } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        loggedUser: user,
      };
    },
    loadUserFail(state) {
      // localStorage.clear(); for clear all localStorage for this site
      localStorage.removeItem("Token");
      axios.defaults.headers["token"] = null;
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        loggedUser: {},
      };
    },
  },
});

export default AuthSlice.reducer;
export const {
  loginSuccess,
  setLogout,
  loadUserRequest,
  loadUserSuccess,
  loadUserFail,
} = AuthSlice.actions;
