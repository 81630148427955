import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  setSiderBarActiveLink,
  setSiderBarActiveList,
} from "../../../redux-store/sidebarActiveList";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { deliveryChallanApi } from "../../../assets/apis";
import {
  dateFormateDDMMYYYY,
  formatNumberWithIndianCommas,
  hasPermission,
} from "../../../utils/helpers";
import { selectStyle } from "../../../assets/selectCustomStyle";
import toast from "react-hot-toast";
import ConfirmPortal from "../../../uiModals/confirmModel/ConfirmPortal";
import Normal from "../../../printsLayouts/DeliveryChallan/Normal/Normal";
import { setDeliveryChallanData } from "../../../redux-store/invoice";
import { useReactToPrint } from "react-to-print";

const DeliveryChallan = () => {
  const { FYear } = useSelector((store) => store.FinancialYear);
  const { DeliveryChallanData } = useSelector((store) => store.Invoice);
  const { loggedUser } = useSelector((store) => store.Auth);
  const { companyData } = useSelector((state) => state.Company);
  const printRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [apiInProccess, setApiInProccess] = useState(false);
  const [openPortal, setOpenPortal] = useState(false);
  const [openMailModal, setOpenMailModal] = useState(false);
  const [mailData, setMailData] = useState({ email: "", challan: "" });
  const [deletingItem, setDeletingItem] = useState(null);
  const [isCancel, setIsCancel] = useState(null);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });
  const [queryParams, setQueryParams] = useState({ page: 1, pageSize: 10 });

  //Delivery challan get logic
  const fetchDeliveryChallans = async ({ queryKey }) => {
    const [, queryParamsData] = queryKey;
    const queryParams = new URLSearchParams(queryParamsData);
    try {
      const response = await axios.get(`${deliveryChallanApi}?${queryParams}`);
      const { totalPages, deliveryChallans } = response.data;
      setData(deliveryChallans || []);
      setTotalPages(totalPages || 1);
      return response.data;
    } catch (error) {
      if (error?.response?.status === 403) return navigate("/upgrade");
      const errorMessage = error.response?.data?.message || "An error occurred";
      // toast.error(errorMessage, toast)
    }
  };

  const {
    isLoading,
    isError,
    data: deliveryChallanData,
    error,
    refetch,
  } = useQuery({
    queryKey: ["challan", queryParams],
    queryFn: fetchDeliveryChallans,
    retry: false,
  });

  //filter search logic
  const onChangeFilterHandler = (e) => {
    const { name, value } = e.target;
    setFilters((pre) => ({ ...pre, [name]: value }));
  };

  const onChangeFilterHandlerWithCondition = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setFilters((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleInputChangeWithConditonTypeNumber = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setFilters((prev) => ({ ...prev, [name]: Number(value) }));
    }
  };

  const onResetHandler = () => {
    setFilters({});
    setQueryParams((pre) => ({ page: 1, pageSize: 10 }));
    setPagination({ page: 1, pageSize: 10 });
  };

  const onSearchHandler = (e) => {
    e.preventDefault();
    const { startDate, endDate } = filters;
    if (startDate && endDate && startDate > endDate)
      return toast.error("Start date must be less than or equal to end date");
    setQueryParams((pre) => ({ ...pre, ...filters, ...pagination }));
  };

  const handleOpenIsCancelPortal = (data) => {
    setIsCancel(data);
    setOpenPortal(true);
  };

  const handleClosePortal = () => {
    setDeletingItem(null);
    setIsCancel(null);
    setOpenPortal(false);
  };

  const onClickPdfHandler = (data) => {
    dispatch(setDeliveryChallanData(data));
  };

  const printBillHandler = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Challan-${DeliveryChallanData?.challan}`,
    onBeforeGetContent: () => {},
    onBeforePrint: () => {},
    onAfterPrint: () => {
      dispatch(setDeliveryChallanData(null));
    },
    onPrintError: () => {},
  });

  useEffect(() => {
    if (DeliveryChallanData) {
      printBillHandler();
    }
  }, [DeliveryChallanData]);

  const deleteInvoice = async (id) => {
    // try {
    //   toast.success("Delivery Challan deleted successfully");
    //   refetch();
    // } catch (error) {
    //   if (error?.response?.status === 403) return navigate("/upgrade");
    //   const errorMessage = error.response?.data?.message || "An error occurred";
    //   toast.error(errorMessage);
    // } finally {
    //   handleClosePortal();
    // }
  };

  const handleCancel = async (id) => {
    try {
      await axios.patch(
        `${deliveryChallanApi}/cancel_delivery_challan_by_id/${id}`,
        {
          is_cancelled: true,
        }
      );
      toast.success("Delivery challan cancelled successfully");
      refetch();
    } catch (error) {
      if (error?.response?.status === 403) return navigate("/upgrade");
      if (error.response?.data?.message)
        return toast.error(error.response?.data?.message);
      else toast.error("something went wrong ");
    } finally {
      handleClosePortal();
    }
  };

  useEffect(() => {
    setQueryParams((pre) => ({ ...pre, ...pagination }));
  }, [pagination.page, pagination.pageSize, pagination]);

  useEffect(() => {
    refetch();
  }, [FYear.value]);

  useEffect(() => {
    dispatch(setSiderBarActiveList({ name: "Invoices" }));
    dispatch(setSiderBarActiveLink({ link: "delivery_challan" }));
  }, []);

  return (
    <div className="main-panel  ">
      <div className="content-wrapper">
        <div id="first" className="card col-lg-12 p-3">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="card-title"> Delivery Challan</div>
            <button
              className="btn btn-primary d-flex justify-content-center align-items-center px-2 py-1"
              onClick={() => navigate("/invoices/add/delivery_challan")}
            >
              {/* <i className="mdi  mdi-receipt me-1"></i> */}
              Add Delivery Challan
            </button>
          </div>

          <form onSubmit={onSearchHandler}>
            <div className="row">
              <div className="col-lg-3">
                <div className="form-group">
                  <label className="form-control-label" htmlFor="name">
                    Customer Name
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-alternative"
                    name="name"
                    id="name"
                    value={filters.name || ""}
                    onChange={(e) =>
                      onChangeFilterHandlerWithCondition(e, /^[\s\S]*$/)
                    }
                  />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <label className="form-control-label" htmlFor="challan">
                    Challan Number
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-alternative"
                    name="challan"
                    id="challan"
                    value={filters.challan || ""}
                    onChange={(e) =>
                      handleInputChangeWithConditonTypeNumber(e, /^[0-9\b]+$/)
                    }
                  />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <label className="form-control-label" htmlFor="startDate">
                    Start Date
                  </label>
                  <input
                    type="date"
                    className="form-control form-control-alternative"
                    name="startDate"
                    id="startDate"
                    value={filters.startDate || ""}
                    onChange={onChangeFilterHandler}
                  />
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-group">
                  <label className="form-control-label" htmlFor="endDate">
                    End Date
                  </label>
                  <input
                    type="date"
                    className="form-control form-control-alternative"
                    name="endDate"
                    id="endDate"
                    value={filters.endDate || ""}
                    onChange={onChangeFilterHandler}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-lg-3">
                <div className="form-group">
                  <label
                    className="form-control-label"
                    htmlFor="payment_status"
                  >
                    Status
                  </label>
                  <select
                    className="form-control form-control-alternative"
                    name="payment_status"
                    id="payment_status"
                    value={filters.payment_status || ""}
                    onChange={onChangeFilterHandler}
                    style={selectStyle}
                  >
                    <option value="">-&nbsp;-select-&nbsp;-</option>
                    <option value="Paid">Paid</option>
                    <option value="Unpaid">Unpaid</option>
                    <option value="Partial">Partial</option>
                  </select>
                </div>
              </div> */}

              <div
                className="col-lg-12 text-right"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                <div className="form-group mt-1">
                  <button
                    type="submit"
                    id="search"
                    className="btn btn-primary btn-sm mt-4"
                  >
                    search
                  </button>
                </div>
                <div className="form-group mt-1">
                  <button
                    type="button"
                    id="reset"
                    className="btn btn-primary btn-sm mt-4"
                    onClick={onResetHandler}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div className="d-flex flex-column align-items-center datatable">
            <DataTable
              value={data}
              style={{ width: "100%" }}
              id="invoice-table"
              className="mytable"
            >
              <Column
                className="border-bottom border-top"
                header="SR. No."
                style={{
                  height: "3rem",
                  textAlign: "start",
                  minWidth: "4rem",
                }}
                body={(rowData, { rowIndex }) => (
                  <p>
                    {pagination.page * pagination.pageSize -
                      pagination.pageSize +
                      rowIndex +
                      1}
                  </p>
                )}
              />
              <Column
                className="border-bottom border-top"
                field="challan"
                header="CHALLAN.NO."
                body={(rowData) => rowData.challan}
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "8rem",
                }}
              />

              <Column
                className="border-bottom border-top"
                field="name"
                header="NAME"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  maxWidth: "8rem",
                  minWidth: "8rem",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="date"
                body={(data) => dateFormateDDMMYYYY(data.date)}
                header="DATE"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "6rem",
                }}
              />
              {/* <Column
                className="border-bottom border-top"
                field="amount"
                header="AMOUNT"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "6rem",
                }}
              /> */}
              {/* <Column
                className="border-bottom border-top"
                field="total_tax"
                header="TAX"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "6rem",
                }}
              /> */}
              {/* <Column
                className="border-bottom border-top"
                body={(data) => data.discount || "-"}
                header="DISCOUNT"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "6rem",
                }}
              /> */}
              <Column
                className="border-bottom border-top"
                field="total"
                header="TOTAL"
                body={(data) => formatNumberWithIndianCommas(data.total || 0)}
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "6rem",
                }}
              />

              <Column
                className="border-bottom border-top"
                field="paid_amount"
                body={(data) =>
                  formatNumberWithIndianCommas(data.paid_amount || 0)
                }
                header="PAID"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "6rem",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="balance"
                header="BALANCE"
                body={(data) => formatNumberWithIndianCommas(data.balance || 0)}
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "6rem",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="payment_status"
                header="STATUS"
                body={(data) => (
                  <span>
                    {data?.is_cancelled ? (
                      <>
                        <span style={{ color: "#ffaf00" }}>Cancelled</span>
                        <br />
                      </>
                    ) : (
                      "--"
                    )}
                    {/* {data?.payment_status} */}
                  </span>
                )}
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "6rem",
                }}
              />
              {/* <Column
                className="border-bottom border-top"
                body={(data) => data.paid_date || "-"}
                header="PAID DATE"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "8rem",
                }}
              /> */}
              <Column
                className="border-bottom border-top"
                body={(data) => (
                  <div
                    style={{
                      minWidth: "50%",
                      // padding:"0 1rem",
                      display: "flex",
                      // justifyContent: "space-around",
                    }}
                  >
                    {data?.is_cancelled ? (
                      ""
                    ) : (
                      <>
                        {/* {hasPermission(loggedUser, 40) && (
                          <i
                            style={{ cursor: "pointer" }}
                            className="dropdown-item-icon mdi mdi-credit-card text-primary me-2"
                            title="Add Payment"
                          ></i>
                        )} */}
                        {/* {companyData?.mailPermissions?.challan ? (
                          <i
                            className="dropdown-item-icon mdi mdi-email text-primary me-2 hover-cursor"
                            title="Send Challan"
                            // onClick={() => sendEmail(data)}
                          ></i>
                        ) : (
                          ""
                        )} */}
                      </>
                    )}

                    <i
                      style={{ cursor: "pointer" }}
                      className="dropdown-item-icon mdi mdi-information text-primary me-2"
                      title="View Challan"
                      onClick={() =>
                        navigate(
                          `/invoices/view/delivery_challan/${data.challan}`
                        )
                      }
                    />
                    {data?.is_cancelled ? (
                      ""
                    ) : (
                      <>
                        {" "}
                        {data.payment_status !== "Paid" && (
                          <>
                            {hasPermission(loggedUser, 56) && (
                              <i
                                style={{ cursor: "pointer" }}
                                className="dropdown-item-icon mdi mdi-pen text-primary me-2"
                                title="Edit"
                                onClick={() =>
                                  navigate(
                                    `/invoices/edit/delivery_challan/${data.challan}`
                                  )
                                }
                              />
                            )}
                            {/* {hasPermission(loggedUser, 57) && (
                          <i
                            style={{ cursor: "pointer" }}
                            onClick={() => handleOpenPortal(data)}
                            className="dropdown-item-icon mdi mdi-delete-forever text-primary me-2"
                            title="Delete"
                          />
                        )} */}
                          </>
                        )}
                      </>
                    )}
                    <i
                      style={{ cursor: "pointer" }}
                      className="dropdown-item-icon mdi mdi-printer text-primary me-2"
                      onClick={() => onClickPdfHandler(data)}
                      title="Print"
                    />
                    {data?.is_cancelled ? (
                      ""
                    ) : (
                      <i
                        className="dropdown-item-icon mdi mdi-cancel text-primary me-2 hover-cursor"
                        title="Cancel"
                        onClick={() => handleOpenIsCancelPortal(data)}
                      />
                    )}
                  </div>
                )}
                header="ACTIONS"
              />
            </DataTable>
            <div
              className=""
              style={{
                display: "flex",
                width: "80%",
                justifyContent: "space-evenly",
                margin: "10px 0 0 0",
              }}
            >
              <button
                style={{ border: "none", fontSize: "24px" }}
                disabled={pagination.page === 1}
                onClick={() =>
                  setPagination((prev) => {
                    return { ...prev, page: prev.page - 1 };
                  })
                }
              >
                <i className="mdi mdi-chevron-left" />
              </button>
              <span>
                Page {pagination.page} of {totalPages}
              </span>
              <button
                style={{ border: "none", fontSize: "24px" }}
                disabled={pagination.page === totalPages}
                onClick={() =>
                  setPagination((prev) => {
                    return { ...prev, page: prev.page + 1 };
                  })
                }
              >
                <i className="mdi mdi-chevron-right" />
              </button>
              <select
                value={pagination.pageSize}
                onChange={(e) =>
                  setPagination((prev) => {
                    return { ...prev, pageSize: e.target.value };
                  })
                }
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="30">30</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      {openPortal && (
        <ConfirmPortal
          onClose={handleClosePortal}
          type={"Challan"}
          isCancel={isCancel}
          name={`Challan-${
            isCancel ? isCancel?.challan : deletingItem?.challan
          }`}
          id={isCancel ? isCancel.id : deletingItem.id}
          handleDalete={deleteInvoice}
          handleCancel={handleCancel}
        ></ConfirmPortal>
      )}
      {companyData?.pdfLayout === "normal" ? (
        <div style={{ display: "none" }}>
          <Normal printRef={printRef} />
        </div>
      ) : (
        <div style={{ display: "none" }}>
          <Normal printRef={printRef} />
        </div>
      )}
    </div>
  );
};

export default DeliveryChallan;
