const hasPermission = (loggedUser, permissionValue) => {
  return loggedUser?.user_permission?.some(
    (permission) =>
      permission.value === 0 || permission.value === permissionValue
  );
};

const hasMultiplePermissions = (loggedUser, permissionArray) => {
  return loggedUser?.user_permission?.some(
    (permission) =>
      permission.value === 0 || permissionArray.includes(permission.value)
  );
};

const hasPermissionToRedirect = (loggedUser) => {
  if (!loggedUser?.user_permission) return "/support"; // Default route if no permissions

  const permissionRoutes = [
    { permissionArray: [1, 2, 3, 4, 5], redirectUrl: "/contacts" },
    { permissionArray: [6, 7, 8, 9, 10], redirectUrl: "/leads" },
    { permissionArray: [11, 12, 13, 14, 15], redirectUrl: "/items" },
    { permissionArray: [16, 17, 18], redirectUrl: "/categories" },
    { permissionArray: [19, 20, 21], redirectUrl: "/brand" },
    { permissionArray: [22, 23, 24], redirectUrl: "/tax" },
    { permissionArray: [25, 26, 27], redirectUrl: "/gsthsn" },
    { permissionArray: [28, 29, 30], redirectUrl: "/gstsac" },
    { permissionArray: [31, 32, 33], redirectUrl: "/invoices/sales" },
    { permissionArray: [34, 35, 36], redirectUrl: "/invoices/expenses" },
    { permissionArray: [37, 38, 39], redirectUrl: "/invoices/quotation" },
    {
      permissionArray: [55, 56, 57],
      redirectUrl: "/invoices/delivery_challan",
    },
    { permissionArray: [40, 41, 42], redirectUrl: "/payment_records" },
    { permissionArray: [43], redirectUrl: "/summary" },
    { permissionArray: [44, 45, 46], redirectUrl: "/users" },
  ];

  for (const permission of loggedUser.user_permission) {
    for (const row of permissionRoutes) {
      if (row?.permissionArray?.includes(permission.value)) {
        return row?.redirectUrl;
      }
    }
  }

  return "/support"; // Default route if no matching permission found
};

const dateFormateDDMMYYYY = (date) => {
  if (date) {
    const DDMMYYYY = date.split("-").reverse().join("-");
    return DDMMYYYY;
  } else return "";
};

const parseFloatWithFixedValue = (value) => {
  return parseFloat(value).toFixed(2);
};

const expiryDetails = (expiryTime) => {
  if (!expiryTime) return;

  const expiryDate = new Date(expiryTime);
  const now = new Date();
  const remainingTime = expiryDate - now;
  const isExpired = remainingTime <= 0;
  const remainingDays = Math.ceil(remainingTime / (1000 * 60 * 60 * 24));
  const isShow = remainingDays <= 3;

  return {
    isExpired,
    remainingTime: isExpired ? 0 : remainingTime,
    isShow,
    remainingDays: remainingDays > 0 ? remainingDays : 0,
  };
};

const formatNumberWithIndianCommas = (input) => {
  const currency = "₹";

  // Attempt to convert the input to a number
  const inNumber = isNaN(Number(input)) ? 0 : Number(input);

  // Ensure the number has two decimal places
  const formattedNumber = inNumber.toFixed(2);

  const numberParts = formattedNumber.split(".");
  const integerPart = numberParts[0];
  const decimalPart = numberParts[1];

  // Apply regex to format the integer part in Indian format
  const lastThreeDigits = integerPart.slice(-3);
  const otherDigits = integerPart.slice(0, -3);
  const formattedIntegerPart =
    otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
    (otherDigits ? "," : "") +
    lastThreeDigits;

  // Return the formatted number with the decimal part
  return `${currency}${" "}${formattedIntegerPart}.${decimalPart}`;
};

export {
  hasPermission,
  hasMultiplePermissions,
  dateFormateDDMMYYYY,
  parseFloatWithFixedValue,
  expiryDetails,
  formatNumberWithIndianCommas,
  hasPermissionToRedirect,
};
