import React, { useEffect, useState } from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import FullLayout from "../layouts/FullLayout/FullLayout";
import BlankLayout from "../layouts/BlankLayout/BlankLayout";
import Login from "./auth/Login";
import SignUp from "./auth/signup/SignUp";
import Forgot from "./auth/Forgot";
import UserProfile from "./UserProfile";
import Support from "./Support";
import Dashboard from "./Dashboard/Dashboard";
import Category from "./Items/category/Category";
import AddCategory from "./Items/category/AddCategory";
import EditCategory from "./Items/category/EditCategory";
import Brand from "./Items/brands/Brand";
import AddBrand from "./Items/brands/AddBrand";
import EditBrand from "./Items/brands/EditBrand";
import ProductServices from "./Items/ProductServices/ProductServices";
import AddProductService from "./Items/ProductServices/AddProductService";
import EditProductService from "./Items/ProductServices/EditProductService";
import Tax from "./Taxes/Tax/Tax";
import AddTax from "./Taxes/Tax/AddTax";
import EditTax from "./Taxes/Tax/EditTax";
import Leads from "./contacts/leads/Leads";
import AddLead from "./contacts/leads/AddLead";
import EditLead from "./contacts/leads/EditLead";
import Contacts from "./contacts/contacts/Contacts";
import AddContact from "./contacts/contacts/AddContact";
import EditContact from "./contacts/contacts/EditContact";
import Bank from "./banks/Bank";
import AddBank from "./banks/AddBank";
import EditBank from "./banks/EditBank";
import GstHsn from "./Taxes/gsthsn/GstHsn";
import AddGstHsn from "./Taxes/gsthsn/AddGstHsn";
import EditGstHsn from "./Taxes/gsthsn/EditGstHsn";
import GstSac from "./Taxes/gstsac/GstSac";
import AddGstSac from "./Taxes/gstsac/AddGstSac";
import EditGstSac from "./Taxes/gstsac/EditGstSac";
import Sales from "./invoice-section/sales/Sales";
import AddSales from "./invoice-section/sales/AddSales";
import EditSales from "./invoice-section/sales/EditSales";
import Expense from "./invoice-section/expense/Expense";
import AddExpense from "./invoice-section/expense/AddExpense";
import EditExpense from "./invoice-section/expense/EditExpense";
import AddQuotation from "./invoice-section/quotation/AddQuotation";
import EditQuotation from "./invoice-section/quotation/EditQuotation";
import Quotation from "./invoice-section/quotation/Quotation";
import ImportData from "./importdata/ImportData";
import PdfSelect from "./pfdselect/PdfSelect";
import MailPermissions from "./mail/MailPermissions";
import Payments from "./invoice-section/Payments";
import Summary from "./reports/Summary";
import NotFound from "./NotFound";
import AuthNotFound from "./AuthNotFound";
import Settings from "./settings/Settings";
import Pay from "./invoice-section/sales/Pay";
import ExpensePay from "./invoice-section/expense/ExpensePay";
import Users from "./Users/Users";
import AddUser from "./Users/AddUser";
import EditUser from "./Users/EditUser";
import { useSelector } from "react-redux";
import ViewSales from "./invoice-section/sales/ViewSales";
import ViewExpense from "./invoice-section/expense/ViewExpense";
import ViewQuotation from "./invoice-section/quotation/ViewQuotation";
import ManageCompany from "./Company/ManageCompany";
import ViewCompany from "./Company/ViewCompany";
import {
  expiryDetails,
  hasMultiplePermissions,
  hasPermissionToRedirect,
} from "../utils/helpers";
import Plan from "./Plan/Plan";
import PleaseUpgrade from "../Upgrage/PleaseUpgrade";
import SecondSpinner from "../spinner/third_spinner/SecondSpinner";
import MySubscription from "./mySubscription/MySubscription";
import DeliveryChallan from "./invoice-section/deliveryChallan/DeliveryChallan";
import AddDeliveryChallan from "./invoice-section/deliveryChallan/AddDeliveryChallan";
import EditDeliveryChallan from "./invoice-section/deliveryChallan/EditDeliveryChallan";
import ViewDeliveryChallan from "./invoice-section/deliveryChallan/ViewDeliveryChallan";

const Router = () => {
  const { loggedUser } = useSelector((state) => state.Auth);
  const { companyData } = useSelector((state) => state.Company);

  const AuthorisedRoute = ({ permissionValue, children }) => {
    const hasPermission = loggedUser.user_permission.some(
      (permission) =>
        permission.value === 0 || permission.value === permissionValue
    );

    return hasPermission ? children : <Navigate to="/" />;
  };
  const AuthForDashboard = ({ permissionValue, children }) => {
    const hasPermission = loggedUser.user_permission.some(
      (permission) =>
        permission.value === 0 || permission.value === permissionValue
    );

    return hasPermission ? (
      children
    ) : (
      <Navigate to={`${hasPermissionToRedirect(loggedUser)}`} />
    );
  };

  const IsExpiredAccount = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);

    function isEmpty(obj) {
      return obj && Object.keys(obj).length === 0;
    }

    useEffect(() => {
      if (companyData && !isEmpty(companyData)) {
        setIsLoading(false);
      }
    }, [companyData]);

    if (isLoading) return <SecondSpinner />;

    if (expiryDetails(companyData?.expired)?.isExpired)
      return <Navigate to="/upgrade" />;
    return children;
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <FullLayout />,
      children: [
        {
          path: "/",
          element: (
            <AuthForDashboard permissionValue={54}>
              <Dashboard />
            </AuthForDashboard>
          ),
        },
        {
          path: "/profile",
          element: <UserProfile />,
        },
        {
          path: "/support",
          element: <Support />,
        },
        {
          path: "/manage_company",
          element: (
            <AuthorisedRoute permissionValue={52}>
              <ManageCompany />
            </AuthorisedRoute>
          ),
        },
        {
          path: "/view/manage_company",
          element: <ViewCompany />,
        },
        {
          path: "/categories",
          element: hasMultiplePermissions(loggedUser, [16, 17, 18]) ? (
            <Category />
          ) : (
            <Navigate to="/" />
          ),
        },

        {
          path: "/add/category",
          element: (
            <AuthorisedRoute permissionValue={16}>
              <AddCategory />
            </AuthorisedRoute>
          ),
        },
        {
          path: "/edit/category/:id",
          element: (
            <AuthorisedRoute permissionValue={17}>
              <EditCategory />
            </AuthorisedRoute>
          ),
        },
        {
          path: "/mysubscription",
          element: <MySubscription />,
        },
        {
          path: "/brand",
          element: hasMultiplePermissions(loggedUser, [19, 20, 21]) ? (
            <Brand />
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/add/brand",
          element: (
            <AuthorisedRoute permissionValue={19}>
              <AddBrand />
            </AuthorisedRoute>
          ),
        },
        {
          path: "/edit/brand/:id",
          element: (
            <AuthorisedRoute permissionValue={20}>
              <EditBrand />
            </AuthorisedRoute>
          ),
        },
        {
          path: "/items",
          element: hasMultiplePermissions(loggedUser, [11, 12, 13, 14, 15]) ? (
            <ProductServices />
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/add/item",
          element: (
            <AuthorisedRoute permissionValue={11}>
              <AddProductService />
            </AuthorisedRoute>
          ),
        },
        {
          path: "/edit/item/:id",
          element: (
            <AuthorisedRoute permissionValue={12}>
              <EditProductService />
            </AuthorisedRoute>
          ),
        },
        {
          path: "/tax",
          element: hasMultiplePermissions(loggedUser, [22, 23, 24]) ? (
            <Tax />
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/add/tax",
          element: (
            <AuthorisedRoute permissionValue={22}>
              <AddTax />
            </AuthorisedRoute>
          ),
        },
        {
          path: "/edit/tax/:id",
          element: (
            <AuthorisedRoute permissionValue={23}>
              <EditTax />
            </AuthorisedRoute>
          ),
        },
        {
          path: "/leads",
          element: hasMultiplePermissions(loggedUser, [6, 7, 8, 9, 10]) ? (
            <Leads />
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/add/lead",
          element: (
            <AuthorisedRoute permissionValue={6}>
              <AddLead />
            </AuthorisedRoute>
          ),
        },
        {
          path: "/edit/lead/:id",
          element: (
            <AuthorisedRoute permissionValue={7}>
              <EditLead />
            </AuthorisedRoute>
          ),
        },
        {
          path: "/contacts",
          element: hasMultiplePermissions(loggedUser, [1, 2, 3, 4, 5]) ? (
            <Contacts />
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/add/contact",
          element: (
            <AuthorisedRoute permissionValue={1}>
              <AddContact />
            </AuthorisedRoute>
          ),
        },
        {
          path: "/edit/contact/:id",
          element: (
            <AuthorisedRoute permissionValue={2}>
              <EditContact />
            </AuthorisedRoute>
          ),
        },
        {
          path: "/banks",
          element: hasMultiplePermissions(loggedUser, [47, 48, 49]) ? (
            <Bank />
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/add/bank",
          element: (
            <AuthorisedRoute permissionValue={47}>
              <AddBank />
            </AuthorisedRoute>
          ),
        },
        {
          path: "/edit/bank/:id",
          element: (
            <AuthorisedRoute permissionValue={48}>
              <EditBank />
            </AuthorisedRoute>
          ),
        },
        {
          path: "/gsthsn",
          element: hasMultiplePermissions(loggedUser, [25, 26, 27]) ? (
            <GstHsn />
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/add/gsthsn",
          element: (
            <AuthorisedRoute permissionValue={25}>
              <AddGstHsn />
            </AuthorisedRoute>
          ),
        },
        {
          path: "/edit/gsthsn/:id",
          element: (
            <AuthorisedRoute permissionValue={26}>
              <EditGstHsn />
            </AuthorisedRoute>
          ),
        },
        {
          path: "/gstsac",
          element: hasMultiplePermissions(loggedUser, [28, 29, 30]) ? (
            <GstSac />
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/add/gstsac",
          element: (
            <AuthorisedRoute permissionValue={28}>
              <AddGstSac />
            </AuthorisedRoute>
          ),
        },
        {
          path: "/edit/gstsac/:id",
          element: (
            <AuthorisedRoute permissionValue={29}>
              <EditGstSac />
            </AuthorisedRoute>
          ),
        },
        {
          path: "/invoices/sales",
          element: hasMultiplePermissions(loggedUser, [31, 32, 33]) ? (
            <IsExpiredAccount>
              <Sales />
            </IsExpiredAccount>
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/invoices/add/sales",
          element: (
            <IsExpiredAccount>
              <AuthorisedRoute permissionValue={31}>
                <AddSales />
              </AuthorisedRoute>
            </IsExpiredAccount>
          ),
        },
        {
          path: "/invoices/edit/sales/:id",
          element: (
            <IsExpiredAccount>
              <AuthorisedRoute permissionValue={32}>
                <EditSales />
              </AuthorisedRoute>
            </IsExpiredAccount>
          ),
        },
        {
          path: "/invoices/view/sales/:id",

          element: hasMultiplePermissions(loggedUser, [31, 32, 33]) ? (
            <IsExpiredAccount>
              <ViewSales />
            </IsExpiredAccount>
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/invoices/pay/sales/:id",
          element: (
            <IsExpiredAccount>
              <AuthorisedRoute permissionValue={40}>
                <Pay />
              </AuthorisedRoute>
            </IsExpiredAccount>
          ),
        },
        {
          path: "/invoices/expenses",
          element: hasMultiplePermissions(loggedUser, [34, 35, 36]) ? (
            <IsExpiredAccount>
              <Expense />
            </IsExpiredAccount>
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/invoices/add/expenses",
          element: (
            <IsExpiredAccount>
              <AuthorisedRoute permissionValue={34}>
                <AddExpense />
              </AuthorisedRoute>
            </IsExpiredAccount>
          ),
        },
        {
          path: "/invoices/edit/expenses/:id",
          element: (
            <IsExpiredAccount>
              <AuthorisedRoute permissionValue={35}>
                <EditExpense />
              </AuthorisedRoute>
            </IsExpiredAccount>
          ),
        },
        {
          path: "/invoices/view/expenses/:id",
          element: hasMultiplePermissions(loggedUser, [34, 35, 36]) ? (
            <IsExpiredAccount>
              <ViewExpense />
            </IsExpiredAccount>
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/invoices/pay/expenses/:id",
          element: (
            <IsExpiredAccount>
              <AuthorisedRoute permissionValue={40}>
                <ExpensePay />
              </AuthorisedRoute>
            </IsExpiredAccount>
          ),
        },
        {
          path: "/invoices/quotation",
          element: hasMultiplePermissions(loggedUser, [37, 38, 39]) ? (
            <IsExpiredAccount>
              <Quotation />
            </IsExpiredAccount>
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/invoices/add/quotation",
          element: (
            <IsExpiredAccount>
              <AuthorisedRoute permissionValue={37}>
                <AddQuotation />
              </AuthorisedRoute>
            </IsExpiredAccount>
          ),
        },
        {
          path: "/invoices/edit/quotation/:id",
          element: (
            <IsExpiredAccount>
              <AuthorisedRoute permissionValue={38}>
                <EditQuotation />
              </AuthorisedRoute>
            </IsExpiredAccount>
          ),
        },
        {
          path: "/invoices/view/quotation/:id",
          element: hasMultiplePermissions(loggedUser, [37, 38, 39]) ? (
            <IsExpiredAccount>
              <ViewQuotation />
            </IsExpiredAccount>
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/invoices/delivery_challan",
          element: hasMultiplePermissions(loggedUser, [55, 56, 57]) ? (
            <IsExpiredAccount>
              <DeliveryChallan />
            </IsExpiredAccount>
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/invoices/add/delivery_challan",
          element: (
            <IsExpiredAccount>
              <AuthorisedRoute permissionValue={55}>
                <AddDeliveryChallan />
              </AuthorisedRoute>
            </IsExpiredAccount>
          ),
        },
        {
          path: "/invoices/edit/delivery_challan/:challan",
          element: (
            <IsExpiredAccount>
              <AuthorisedRoute permissionValue={56}>
                <EditDeliveryChallan />
              </AuthorisedRoute>
            </IsExpiredAccount>
          ),
        },
        {
          path: "/invoices/view/delivery_challan/:challan",
          element: hasMultiplePermissions(loggedUser, [55, 56, 57]) ? (
            <IsExpiredAccount>
              <ViewDeliveryChallan />
            </IsExpiredAccount>
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/users",
          element: hasMultiplePermissions(loggedUser, [44, 45, 46]) ? (
            <Users />
          ) : (
            <Navigate to="/" />
          ),
        },

        {
          path: "/add/user",
          element: (
            <AuthorisedRoute permissionValue={44}>
              <AddUser />
            </AuthorisedRoute>
          ),
        },
        {
          path: "/edit/user/:id",
          element: (
            <AuthorisedRoute permissionValue={45}>
              <EditUser />{" "}
            </AuthorisedRoute>
          ),
        },
        {
          path: "/import/data",
          element: hasMultiplePermissions(loggedUser, [4, 5, 9, 10, 14, 15]) ? (
            <ImportData />
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/pdf/select",
          element: (
            <AuthorisedRoute permissionValue={50}>
              <PdfSelect />
            </AuthorisedRoute>
          ),
        },
        {
          path: "/mail/select",
          element: (
            <AuthorisedRoute permissionValue={51}>
              <MailPermissions />
            </AuthorisedRoute>
          ),
        },

        {
          path: "/payment_records",
          element: hasMultiplePermissions(loggedUser, [40, 41, 42]) ? (
            <IsExpiredAccount>
              <Payments />
            </IsExpiredAccount>
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/summary",
          element: (
            <IsExpiredAccount>
              <AuthorisedRoute permissionValue={43}>
                <Summary />
              </AuthorisedRoute>
            </IsExpiredAccount>
          ),
        },
        {
          path: "/pick/plan",
          element: <Plan />,
        },
        {
          path: "/upgrade",
          element: <PleaseUpgrade />,
        },
        {
          path: "/settings",
          element: (
            <AuthorisedRoute permissionValue={53}>
              <Settings />
            </AuthorisedRoute>
          ),
        },
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
    {
      path: "/auth",
      element: <BlankLayout />,
      children: [
        { path: "/auth/login", element: <Login /> },
        { path: "/auth/signup", element: <SignUp /> },
        { path: "/auth/forgot", element: <Forgot /> },
        { path: "*", element: <AuthNotFound /> },
      ],
    },
  ]);
  return <RouterProvider router={router} />;
};

export default Router;
