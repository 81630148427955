import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  expiryDetails: {
    isExpired: false,
    remainingTime: 0,
  },
};

const PlanExpireSlice = createSlice({
  name: "PlanExpire",
  initialState,
  reducers: {
    setExpiryDetails(state, action) {
      const expiryDate = new Date(action.payload);
      const now = new Date();
      const isExpired = expiryDate <= now;
      const remainingTime = isExpired ? 0 : expiryDate - now;

      state.expiryDetails = {
        isExpired,
        remainingTime,
      };
    },
  },
});

export default PlanExpireSlice.reducer;
export const { setExpiryDetails } = PlanExpireSlice.actions;
