import React from "react";

const ResultNotFound = ({ message }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "#737f8b !important",
      }}
    >
      <i className="fa fa-frown-o fa-3x" aria-hidden="true" />
      <span style={{ fontSize: "1em" }}>{message}</span>
    </div>
  );
};

export default ResultNotFound;
