import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { bankApi } from "../../assets/apis";
import toast from "react-hot-toast";
import Spinner from "../../spinner/first_spinner/Spinner";
import CoverSpin from "../../spinner/second_spinner/CoverSpin";
import SecondSpinner from "../../spinner/third_spinner/SecondSpinner";

const EditBank = () => {
  const [editedBankData, setEditBankData] = useState({});
  const navigate = useNavigate();

  const { id } = useParams();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    editedBankData((pre) => ({ ...pre, [name]: value }));
  };

  const handleInputChangeWithCondition = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      if (name === "ifsc")
        return setEditBankData((prev) => ({
          ...prev,
          [name]: value.toUpperCase(),
        }));
      setEditBankData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name,holder_name, branch, description } = editedBankData;
    const updatedAddBankData = {
      ...editedBankData,
      name: name.trim(),
      holder_name:holder_name?.trim(),
      branch: branch.trim(),
      ...(description && { description: description.trim() }),
    };
    if (!updatedAddBankData.name) return toast.error("Please enter bank name");
    if (!updatedAddBankData.name) return toast.error("Please enter account holder name");
    if (!updatedAddBankData.branch)
      return toast.error("Please enter branch name");
    setEditBankData(updatedAddBankData);
    try {
      await axios.put(`${bankApi}/${id}`, updatedAddBankData);
      toast.success("Bank information saved successfully");
      navigate("/banks");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const { data, isError, isLoading, error } = useQuery({
    queryKey: [id],
    queryFn: () =>
      axios.get(`${bankApi}/${id}`).then((response) => response.data.data),
  });

  useEffect(() => {
    if (data) {
      const { createdAt, updatedAt, companyId, id, ...rest } = data;
      setEditBankData(rest);
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className="main-panel">
        <div className="content-wrapper">
          <SecondSpinner />
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div>
        {" "}
        {error?.response?.data?.message ||
          "Bank does not exist or does not belong to the corrent company or financial year"}
      </div>
    );
  }

  return (
    <div className={`main-panel`}>
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-12">
            <div className="d-flex justify-content-between m-1 p-1 align-items-baseline ">
              <h3 className="ukhd mb-3">Edit Bank</h3>
              <button
                type="button"
                className="btn btn-warning btn-sm"
                onClick={() => navigate("/banks")}
              >
                <div className="d-flex justify-content-center">
                  <i className="mdi mdi mdi-keyboard-backspace"></i>
                  <span>Back</span>
                </div>
              </button>
            </div>
            <div className="row flex-grow">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card card-rounded">
                  <div className="card-body">
                    <div className="col-lg-12">
                      <div className="col-lg-12 ">
                        <h5>BANK INFORMATION *</h5>
                      </div>
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-lg-6 mt-2">
                            <label>Bank Name *</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Bank"
                              name="name"
                              required
                              value={editedBankData.name || ""}
                              onChange={(e) =>
                                handleInputChangeWithCondition(
                                  e,
                                  /^[a-zA-Z\s\b]+$/
                                )
                              }
                            />
                          </div>
                          <div className="col-lg-6 mt-2">
                            <label>Account Holder Name *</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Holders Name"
                              name="holder_name"
                              required
                              value={editedBankData.holder_name || ""}
                              onChange={(e) =>
                                handleInputChangeWithCondition(
                                  e,
                                  /^[a-zA-Z\s\b]+$/
                                )
                              }
                            />
                          </div>
                          <div className="col-lg-6 mt-2">
                            <label>Account No. *</label>{" "}
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Account No."
                              name="account_no"
                              required
                              minLength={9}
                              maxLength={20}
                              value={editedBankData.account_no || ""}
                              onChange={(e) =>
                                handleInputChangeWithCondition(e, /^[0-9\b]+$/)
                              }
                            />
                          </div>
                          <div className="col-lg-6 mt-2">
                            <label>Branch Name *</label>{" "}
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Branch"
                              name="branch"
                              required
                              value={editedBankData.branch || ""}
                              onChange={(e) =>
                                handleInputChangeWithCondition(
                                  e,
                                  /^[a-zA-Z\s\b]+$/
                                )
                              }
                            />
                          </div>
                          <div className="col-lg-6 mt-2">
                            <label>IFSC Code *</label>{" "}
                            <input
                              type="text"
                              className="form-control"
                              placeholder="IFSC Code"
                              name="ifsc"
                              required
                              minLength={11}
                              maxLength={11}
                              value={editedBankData.ifsc || ""}
                              onChange={(e) =>
                                handleInputChangeWithCondition(
                                  e,
                                  /^[a-zA-Z0-9]*$/
                                )
                              }
                            />
                          </div>
                          <div className="col-lg-12 mt-2">
                            <label>Description</label>{" "}
                            <textarea
                              type="text"
                              className="form-control"
                              style={{
                                height: "100px",
                                resize: "none",
                                overflow: "hidden",
                              }}
                              name="description"
                              value={editedBankData.description || ""}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>

                        <div className="col-lg-9 mt-2">
                          <button
                            type="submit"
                            className="btn btn-primary d-flex justify-content-center px-3 py-2"
                          >
                            <i className="mdi mdi-content-save me-1"></i> Save
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditBank;
