import React from "react";
import { Link } from "react-router-dom";

const TopClient = ({ mostVisitedClients }) => {
  return (
    <div className="table-responsive" style={{ height: "8cm" }}>
      <table className="table align-items-center table-flush">
        <thead className="thead-light">
          <tr>
            <th style={{ paddingLeft: "0", paddingRight: "0" }} scope="col">
              Sr.No.
            </th>
            <th scope="col">Name</th>
            <th scope="col">PHONE</th>
            <th scope="col">STATE</th>
          </tr>
        </thead>
        <tbody>
          {(mostVisitedClients || [])?.map((client, index) => (
            <tr key={client.clientId}>
              <td style={{ paddingLeft: "0", paddingRight: "0" }}>
                {index + 1}
              </td>
              <td scope="row">
                <Link to={`/edit/contact/${client.clientId}`}>
                  {client?.details?.name}
                </Link>
              </td>
              <td>{client?.details?.phone}</td>
              <td>{client?.details?.billing_state}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TopClient;
