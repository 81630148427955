import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { leadApi } from "../../../assets/apis";
import { useDispatch, useSelector } from "react-redux";
import ConfirmPortal from "../../../uiModals/confirmModel/ConfirmPortal";
import {
  setSiderBarActiveLink,
  setSiderBarActiveList,
} from "../../../redux-store/sidebarActiveList";
import { hasPermission } from "../../../utils/helpers";
const Leads = () => {
  const { FYear } = useSelector((store) => store.FinancialYear);
  const { loggedUser } = useSelector((store) => store.Auth);
  const dispatch = useDispatch();
  const [openPortal, setOpenPortal] = useState(false);
  const [deletingItem, setDeletingItem] = useState(null);
  const [allLeads, setAllLeads] = useState([]);
  const [filters, setFilters] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });

  const [queryParams, setQueryParams] = useState({ page: 1, pageSize: 10 });

  const navigate = useNavigate();

  const deleteLead = async (id) => {
    handleClosePortal();

    try {
      const response = await axios.delete(`${leadApi}/${id}`);
      toast.success("Lead deleted successfully");
      refetch();
    } catch (error) {
      handleApiError(error);
    }
  };

  // Function to close the modal
  const handleOpenPortal = (data) => {
    setDeletingItem(data);
    setOpenPortal(true);
  };
  const handleClosePortal = () => {
    setDeletingItem(null);
    setOpenPortal(false);
  };

  const handleApiError = (error) => {
    const errorMessage = error.response?.data?.message || "An error occurred";
    toast.error(errorMessage);
  };

  const onChangeFilterHandler = (e) => {
    const { name, value } = e.target;
    setFilters((pre) => ({ ...pre, [name]: value }));
  };

  const handleInputChangeWithCondition = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setFilters((prev) => ({ ...prev, [name]: value }));
    }
  };

  const onSearchHandler = (e) => {
    e.preventDefault();
    setQueryParams((pre) => ({ ...pre, ...filters, ...pagination }));
  };

  const onResetHandler = () => {
    setFilters({});
    setQueryParams((pre) => ({ page: 1, pageSize: 10 }));
    setPagination({ page: 1, pageSize: 10 });
  };

  const fetchLeads = async ({ queryKey }) => {
    const [, queryParamsData] = queryKey;
    const queryParams = new URLSearchParams(queryParamsData);
    try {
      const response = await axios.get(`${leadApi}?${queryParams}`);
      setAllLeads(response.data.leads);
      setTotalPages(response.data.totalPages);
      return response.data.leads;
    } catch (error) {
      handleApiError(error);
    }
  };

  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: ["leads", queryParams],
    queryFn: fetchLeads,
  });

  useEffect(() => {
    setQueryParams((pre) => ({ ...pre, ...pagination }));
  }, [pagination.page, pagination.pageSize, pagination]);

  useEffect(() => {
    refetch();
  }, [FYear.value]);

  useEffect(() => {
    dispatch(setSiderBarActiveList({ name: "Contacts" }));
    dispatch(setSiderBarActiveLink({ link: "leads" }));
  }, []);

  return (
    <div className="main-panel  ">
      <div className="content-wrapper">
        <div id="first" className="card col-lg-12 p-3">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="card-title">
              {" "}
              All Leads{" "}
              <i
                className="fa fa-info-circle text-secondary hover-cursor"
                data-toggle="tooltip"
                title="Contacts who can become our Future clients"
                aria-hidden="true"
                data-original-title="Contacts who can become our Future clients"
              ></i>
            </div>
            {hasPermission(loggedUser, 6) && (
              <button
                className="btn btn-primary d-flex justify-content-center px-2 py-1 align-items-center"
                onClick={() => navigate("/add/lead")}
              >
                {/* <i className="mdi  mdi-account-plus me-1"></i> */}
                Add Lead
              </button>
            )}
          </div>
          <form className="row" onSubmit={onSearchHandler}>
            <div className="col-lg-2">
              <div className="form-group">
                <label className="form-control-label" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control form-control-alternative"
                  name="name"
                  id="name"
                  value={filters.name || ""}
                  onChange={(e) =>
                    handleInputChangeWithCondition(e, /^[\s\S]*$/)
                  }
                />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="form-group">
                <label className="form-control-label" htmlFor="phone">
                  Mobile Number
                </label>
                <input
                  type="text"
                  className="form-control form-control-alternative"
                  name="phone"
                  id="phone"
                  value={filters.phone || ""}
                  minLength={10}
                  maxLength={10}
                  onChange={(e) =>
                    handleInputChangeWithCondition(e, /^[0-9\b]+$/)
                  }
                />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="form-group">
                <label className="form-control-label" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control form-control-alternative"
                  name="email"
                  id="email"
                  value={filters.email || ""}
                  onChange={onChangeFilterHandler}
                />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="form-group">
                <label className="form-control-label" htmlFor="billing_city">
                  City
                </label>
                <input
                  type="text"
                  className="form-control form-control-alternative"
                  name="billing_city"
                  id="billing_city"
                  value={filters.billing_city || ""}
                  onChange={onChangeFilterHandler}
                />
              </div>
            </div>

            <div className="col-lg-4 text-right">
              <div
                className="form-group mt-1"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                <button
                  type="submit"
                  id="search"
                  className="btn btn-primary btn-sm mt-4"
                >
                  Search
                </button>
                <button
                  type="button"
                  id="reset"
                  className="btn btn-primary btn-sm mt-4"
                  onClick={onResetHandler}
                >
                  Reset
                </button>
              </div>
            </div>
          </form>

          <div className="d-flex flex-column  align-items-center datatable">
            <DataTable
              value={allLeads}
              style={{ width: "100%" }}
              id="review-table"
              className="mytable"
            >
              <Column
                className="border-bottom border-top "
                header="SR. No."
                body={(rowData, { rowIndex }) =>
                  pagination.page * pagination.pageSize -
                  pagination.pageSize +
                  rowIndex +
                  1
                }
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "4rem",
                }}
              />

              <Column
                className="border-bottom border-top"
                field="name"
                header="NAME"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "9rem",
                }}
              />

              <Column
                className="border-bottom border-top"
                field="phone"
                header="PHONE"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "5rem",
                  padding: "0 5px",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="email"
                header="EMAIL"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "5rem",
                  padding: "0 5px",
                }}
              />
              <Column
                className="border-bottom border-top"
                body={(data) => data.status || "-"}
                header="STATUS"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "5rem",
                  padding: "0 5px",
                }}
              />
              {/* <Column
                className="border-bottom border-top"
                body={(data) => "false"}
                header="CONVERTED"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "5rem",
                  padding: "0 5px",
                }}
              /> */}

              <Column
                className="border-bottom border-top"
                body={(data) => (
                  <div
                    style={{
                      minWidth: "50%",
                      maxWidth: "2rem",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    {hasPermission(loggedUser, 7) && (
                      <i
                        style={{ cursor: "pointer" }}
                        onClick={(e) => navigate(`/edit/lead/${data.id}`)}
                        className="dropdown-item-icon mdi mdi-pen text-primary me-2"
                      />
                    )}
                    {hasPermission(loggedUser, 8) && (
                      <i
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          handleOpenPortal(data);
                        }}
                        className="dropdown-item-icon mdi mdi-delete-forever text-primary me-2"
                      />
                    )}
                  </div>
                )}
                header="ACTIONS"
              />
            </DataTable>
            <div
              className=""
              style={{
                display: "flex",
                width: "80%",
                justifyContent: "space-evenly",
                margin: "10px 0 0 0",
              }}
            >
              <select
                value={pagination.pageSize}
                onChange={(e) =>
                  setPagination((prev) => {
                    return { ...prev, pageSize: e.target.value };
                  })
                }
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="30">30</option>
              </select>
              <button
                style={{ border: "none", fontSize: "24px" }}
                disabled={pagination.page === 1}
                onClick={() =>
                  setPagination((prev) => {
                    return { ...prev, page: prev.page - 1 };
                  })
                }
              >
                <i className="mdi mdi-chevron-left" />
              </button>
              <span>
                Page {pagination.page} of {totalPages}
              </span>
              <button
                style={{ border: "none", fontSize: "24px" }}
                disabled={pagination.page === totalPages}
                onClick={() =>
                  setPagination((prev) => {
                    return { ...prev, page: prev.page + 1 };
                  })
                }
              >
                <i className="mdi mdi-chevron-right" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {openPortal && (
        <ConfirmPortal
          onClose={handleClosePortal}
          type={"Lead"}
          name={deletingItem.name}
          id={deletingItem.id}
          handleDalete={deleteLead}
        ></ConfirmPortal>
      )}
    </div>
  );
};

export default Leads;
