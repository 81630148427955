import axios from "axios";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { contactApi } from "../../../assets/apis";
import { validateContact } from "./contactValidation";
import { selectCustomStyle } from "../../../assets/selectCustomStyle";
import { countryList } from "../../../assets/countryList";
import { state } from "../../../assets/StateList";
import {
  setSiderBarActiveLink,
  setSiderBarActiveList,
} from "../../../redux-store/sidebarActiveList";
import { useDispatch } from "react-redux";
const AddContact = () => {
  const [contactType, setContactType] = useState("customer");
  const [customerData, setCustomerData] = useState({});
  const [apiInProccess, setApiInProccess] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const updateCustomerData = (e, select_name) => {
    if (select_name) {
      setCustomerData((prev) => ({ ...prev, [select_name]: e }));
    } else {
      const { name, value, type, checked } = e.target;
      const inputValue = type === "checkbox" ? checked : value;
      if (name === "PAN")
        return setCustomerData((prev) => ({
          ...prev,
          [name]: inputValue.toUpperCase(),
        }));
      if (name === "GSTIN")
        return setCustomerData((prev) => ({
          ...prev,
          [name]: inputValue.toUpperCase(),
        }));
      setCustomerData((prev) => ({ ...prev, [name]: inputValue }));
    }
  };

  const handleInputChange = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setCustomerData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const isBilling_countryIndia = customerData.billing_country === "India";
  const isShiping_countryIndia = customerData.shipping_country === "India";

  const handleCountryChange = (value, type) => {
    setCustomerData((prev) => ({
      ...prev,
      [`${type}_country`]: value,
      [`${type}_state`]: "",
    }));
  };

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data) => axios.post(contactApi, data),
    onSuccess: () => {
      setApiInProccess(false);

      queryClient.invalidateQueries(["contacts"]);
      toast.success("Contact saved successfully!");
      navigate("/contacts");
    },
    onError: (error) => {
      setApiInProccess(false);

      const errorMessage = error.response?.data?.message;
      toast.error(` ${errorMessage}`);
    },
  });

  const saveContact = async (e) => {
    e.preventDefault();
    const {
      name,
      display_name,
      shipping_name,
      shipping_display_name,
      isBillAndShipAddressSame,
    } = customerData;

    let updatedCustomerData = {
      ...customerData,
      name: name.trim(),
      display_name: display_name.trim(),
      ...(shipping_name && { shipping_name: shipping_name.trim() }),
      ...(shipping_display_name && {
        shipping_display_name: shipping_display_name.trim(),
      }),
      contactType,
    };

    if (contactType === "supplier" || isBillAndShipAddressSame) {
      updatedCustomerData = {
        ...updatedCustomerData,
        shipping_name: "",
        shipping_display_name: "",
        shipping_phone: "",
        shipping_email: "",
        shipping_address: "",
        shipping_country: "",
        shipping_state: "",
        shipping_city: "",
        shipping_pin_code: "",
      };
    }

    setCustomerData(updatedCustomerData);
    if (validateContact(updatedCustomerData, contactType)) {
      setApiInProccess(true);

      mutation.mutate(updatedCustomerData);
    }
  };

  useEffect(() => {
    dispatch(setSiderBarActiveList({ name: "Contacts" }));
    dispatch(setSiderBarActiveLink({ link: "contacts" }));
  }, []);

  return (
    <div className={`main-panel`}>
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-12">
            <div className="d-flex justify-content-between m-1 p-1 align-items-baseline ">
              <h3 className="ukhd mb-3">Add Contact</h3>
              <button
                type="button"
                className="btn btn-warning btn-sm"
                onClick={() => navigate("/contacts")}
              >
                <div className="d-flex justify-content-center">
                  <i className="mdi mdi mdi-keyboard-backspace"></i>
                  <span>Back</span>
                </div>
              </button>
            </div>
            <div className="row flex-grow">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card card-rounded">
                  <form className="card-body" onSubmit={saveContact}>
                    <div className="col-lg-12 ">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <input
                              checked={contactType === "customer"}
                              onChange={() => {
                                setContactType("customer");
                              }}
                              type="radio"
                              className="form-check-input"
                              id="option1"
                              name="contactType"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="option1"
                            >
                              Customer
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group ">
                            <input
                              checked={contactType === "supplier"}
                              onChange={() => {
                                setContactType("supplier");
                              }}
                              type="radio"
                              className="form-check-input"
                              id="option2"
                              name="contactType"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="option2"
                            >
                              Supplier
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 ">
                      <h5>CONTACT INFORMATION</h5>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>
                            {contactType === "supplier"
                              ? "Supplier"
                              : "Customer"}{" "}
                            Name *
                          </label>
                          <input
                            required
                            value={customerData.name || ""}
                            onChange={(e) => handleInputChange(e, /^[\s\S]*$/)}
                            name="name"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Display Name *</label>
                          <input
                            required
                            value={customerData.display_name || ""}
                            onChange={(e) => handleInputChange(e, /^[\s\S]*$/)}
                            name="display_name"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Phone *</label>
                          <input
                            required
                            value={customerData.phone || ""}
                            onChange={(e) => handleInputChange(e, /^[0-9\b]+$/)}
                            name="phone"
                            minLength={10}
                            maxLength={10}
                            type="tel"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            value={customerData.email || ""}
                            onChange={updateCustomerData}
                            name="email"
                            type="email"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>GSTIN</label>
                          <input
                            value={customerData.GSTIN || ""}
                            onChange={updateCustomerData}
                            name="GSTIN"
                            maxLength="15"
                            minLength="15"
                            pattern="[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}"
                            title="GSTIN should be in the format: 11POPLP4627X3Z2"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>TIN</label>
                          <input
                            value={customerData.TIN || ""}
                            onChange={updateCustomerData}
                            name="TIN"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>PAN</label>
                          <input
                            value={customerData.PAN || ""}
                            onChange={updateCustomerData}
                            name="PAN"
                            maxLength="10"
                            minLength="10"
                            pattern="[A-Z]{5}[0-9]{4}[A-Z]"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>VAT NO</label>
                          <input
                            value={customerData.VAT || ""}
                            onChange={updateCustomerData}
                            name="VAT"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>DL. No.</label>
                          <input
                            value={customerData.DL || ""}
                            onChange={updateCustomerData}
                            name="DL"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Billing Address</label>
                          <input
                            value={customerData.billing_address || ""}
                            onChange={updateCustomerData}
                            name="billing_address"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Billing Country *</label>
                          <Select
                            required
                            name="billing_country"
                            className=""
                            value={{
                              value: customerData.billing_country || "",
                              label: customerData.billing_country || "",
                            }}
                            onChange={({ value }) =>
                              handleCountryChange(value, "billing")
                            }
                            options={countryList.map((country) => ({
                              label: country,
                              value: country,
                            }))}
                            styles={selectCustomStyle}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>
                            Billing State {isBilling_countryIndia && "*"}
                          </label>
                          {isBilling_countryIndia ? (
                            <Select
                              name="billing_state"
                              className="w-100"
                              required={isBilling_countryIndia}
                              value={{
                                value: customerData.billing_state || "",
                                label: customerData.billing_state || "",
                              }}
                              onChange={({ value }) =>
                                updateCustomerData(value, "billing_state")
                              }
                              options={state.map((state) => ({
                                label: state,
                                value: state,
                              }))}
                              styles={selectCustomStyle}
                            />
                          ) : (
                            <input
                              value={customerData.billing_state || ""}
                              onChange={updateCustomerData}
                              name="billing_state"
                              type="text"
                              className="form-control"
                            />
                          )}
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Billing City</label>
                          <input
                            value={customerData.billing_city || ""}
                            onChange={updateCustomerData}
                            name="billing_city"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Billing PIN Code</label>
                          <input
                            value={customerData.billing_PIN_Code || ""}
                            onChange={(e) => handleInputChange(e, /^[0-9\b]+$/)}
                            minLength={6}
                            maxLength={6}
                            name="billing_PIN_Code"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>

                      {contactType === "customer" && (
                        <div className="col-lg-12 mb-3">
                          <input
                            checked={!!customerData.isBillAndShipAddressSame}
                            onChange={updateCustomerData}
                            type="checkbox"
                            className="form-check-input"
                            id="isBillAndShipAddressSame"
                            name="isBillAndShipAddressSame"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="isBillAndShipAddressSame"
                          >
                            Billing and shipping addresses are the same
                          </label>
                        </div>
                      )}
                      {contactType === "customer" &&
                        !customerData.isBillAndShipAddressSame && (
                          <div className="row">
                            <div className="col-lg-12 mt-3">
                              <h6>SHIPPING INFORMATION</h6>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Contact Name *</label>
                                <input
                                  required
                                  value={customerData.shipping_name || ""}
                                  onChange={(e) =>
                                    handleInputChange(e, /^[\s\S]*$/)
                                  }
                                  name="shipping_name"
                                  type="text"
                                  className="form-control"
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Display Name *</label>
                                <input
                                  required
                                  value={
                                    customerData.shipping_display_name || ""
                                  }
                                  onChange={(e) =>
                                    handleInputChange(e, /^[\s\S]*$/)
                                  }
                                  name="shipping_display_name"
                                  type="text"
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Shipping Phone</label>
                                <input
                                  value={customerData.shipping_phone || ""}
                                  onChange={(e) =>
                                    handleInputChange(e, /^[0-9\b]+$/)
                                  }
                                  minLength={10}
                                  maxLength={10}
                                  name="shipping_phone"
                                  type="tel"
                                  className="form-control"
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Shipping Email</label>
                                <input
                                  value={customerData.shipping_email || ""}
                                  onChange={updateCustomerData}
                                  name="shipping_email"
                                  type="email"
                                  className="form-control"
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Shipping Address</label>
                                <input
                                  value={customerData.shipping_address || ""}
                                  onChange={updateCustomerData}
                                  name="shipping_address"
                                  type="text"
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Shipping Country *</label>
                                <Select
                                  name="shipping_country"
                                  required
                                  className="w-100"
                                  value={{
                                    value: customerData.shipping_country || "",
                                    label: customerData.shipping_country || "",
                                  }}
                                  onChange={({ value }) =>
                                    handleCountryChange(value, "shipping")
                                  }
                                  options={countryList.map((country) => ({
                                    label: country,
                                    value: country,
                                  }))}
                                  styles={selectCustomStyle}
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>
                                  Shipping State {isShiping_countryIndia && "*"}
                                </label>
                                {isShiping_countryIndia ? (
                                  <Select
                                    name="shipping_state"
                                    required={isShiping_countryIndia}
                                    className="w-100"
                                    value={{
                                      value: customerData.shipping_state || "",
                                      label: customerData.shipping_state || "",
                                    }}
                                    onChange={({ value }) =>
                                      updateCustomerData(
                                        value,
                                        "shipping_state"
                                      )
                                    }
                                    options={state.map((state) => ({
                                      label: state,
                                      value: state,
                                    }))}
                                    styles={selectCustomStyle}
                                  />
                                ) : (
                                  <input
                                    value={customerData.shipping_state || ""}
                                    onChange={updateCustomerData}
                                    name="shipping_state"
                                    type="text"
                                    className="form-control"
                                  />
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Shipping City</label>
                                <input
                                  value={customerData.shipping_city || ""}
                                  onChange={updateCustomerData}
                                  name="shipping_city"
                                  type="text"
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>shipping PIN Code</label>
                                <input
                                  value={customerData.shipping_pin_code || ""}
                                  onChange={(e) =>
                                    handleInputChange(e, /^[0-9\b]+$/)
                                  }
                                  minLength={6}
                                  maxLength={6}
                                  name="shipping_pin_code"
                                  type="text"
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                    <div className="col-lg-12">
                      <button
                        type="submit"
                        className="btn btn-primary d-flex justify-content-center px-3 py-2"
                        disabled={apiInProccess}
                      >
                        <i className="mdi mdi-content-save me-1"></i> Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddContact;
