import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import toast from "react-hot-toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import Footer from "../../helper/Footer";
import { userApi } from "../../assets/apis"; // Assuming you have an API file for users
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setSiderBarActiveLink,
  setSiderBarActiveList,
} from "../../redux-store/sidebarActiveList";
import { Flex } from "antd";
import ConfirmPortal from "../../uiModals/confirmModel/ConfirmPortal";
import { hasPermission } from "../../utils/helpers";

export const fetchUsers = async () => {
  const response = await axios.get(userApi);
  return response.data;
};

const Users = () => {
  const { loggedUser } = useSelector((state) => state.Auth);

  const [userData, setUserData] = useState([]);
  const [openPortal, setOpenPortal] = useState(false);
  const [deletingItem, setDeletingItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  // Function to close the modal
  const handleOpenPortal = (data) => {
    setDeletingItem(data);
    setOpenPortal(true);
  };
  const handleClosePortal = () => {
    setDeletingItem(null);
    setOpenPortal(false);
  };

  const fetchUser = async () => {
    try {
      const result = await axios.get(userApi);
      const response = result.data;
      setUserData(response.users || []);
      return response.data;
    } catch (error) {}
  };

  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: ["users"],
    queryFn: fetchUser,
  });

  const mutation = useMutation({
    mutationFn: (id) => {
      return axios.delete(`${userApi}/${id}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);
      toast.success("User information delete successfully!");
      handleClosePortal();
    },
    onError: (error) => {
      const errorMessage = error.response?.data?.message;
      toast.error(` ${errorMessage}`);
    },
  });

  const deleteUser = async (id) => {
    mutation.mutate(id);
  };

  const filteredData = userData.filter((item) =>
    Object.values(item).some((val) =>
      val?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  useEffect(() => {
    dispatch(setSiderBarActiveList({ name: null }));
    dispatch(setSiderBarActiveLink({ link: "users" }));
  }, []);

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div id="first" className="card col-lg-12 p-3">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="card-title"> Users</div>
            {hasPermission(loggedUser, 44) && (
              <button
                className="btn btn-primary px-2 py-1"
                onClick={() => navigate("/add/user")}
              >
                Add User
              </button>
            )}
          </div>

          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label className="form-control-label" htmlFor="name"></label>
                <input
                  type="text"
                  className="form-control "
                  name="name"
                  id="name"
                  placeholder="search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-column align-items-center">
            <DataTable
              value={filteredData}
              style={{ width: "100%" }}
              id="review-table"
              className="mytable"
            >
              <Column
                className="border-bottom border-top"
                header="SR. No."
                body={(rowData, { rowIndex }) => rowIndex + 1}
              />

              <Column
                className="border-bottom border-top"
                field="name"
                header="NAME "
                style={{
                  height: "4rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "3rem",
                  padding: "0 5px",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="email"
                header="EMAIL"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "9rem",
                  padding: "0 5px",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="mobile"
                header="PHONE"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "9rem",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="designation"
                header="DESIGNATION"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "9rem",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="user_permissions"
                header="USER PERMISSIONS"
                body={(data) => {
                  return data?.user_permission?.map((Permissions) => (
                    <div
                      style={{ display: Flex, flexDirection: "column" }}
                      key={Permissions.value}
                    >
                      <span>{Permissions.label}</span>
                    </div>
                  ));
                }}
                style={{
                  height: "3rem",
                  padding: ".5rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "9rem",
                }}
              />
              <Column
                className="border-bottom border-top"
                body={(data) => (
                  <div
                    style={{
                      minWidth: "50%",
                      maxWidth: "2rem",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    {data.email !== loggedUser.email ? (
                      <>
                        {hasPermission(loggedUser, 45) && (
                          <i
                            onClick={(e) => navigate(`/edit/user/${data.id}`)}
                            className="dropdown-item-icon mdi mdi-pen text-primary me-2 hover-cursor"
                          />
                        )}
                        {hasPermission(loggedUser, 46) && (
                          <i
                            onClick={(e) => {
                              handleOpenPortal(data);
                            }}
                            className="dropdown-item-icon mdi mdi-delete-forever text-primary me-2 hover-cursor"
                          />
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                header="ACTIONS"
              />
            </DataTable>
          </div>
        </div>
      </div>
      {openPortal && (
        <ConfirmPortal
          onClose={handleClosePortal}
          type={"User"}
          name={deletingItem.name}
          id={deletingItem.id}
          handleDalete={deleteUser}
        ></ConfirmPortal>
      )}
    </div>
  );
};

export default Users;
