// contactValidation.js
import { toast } from "react-hot-toast";

export const validateContact = (customerData, contactType) => {
  const {
    name,
    display_name,
    phone,
    billing_country,
    billing_state,
    shipping_name,
    shipping_display_name,
    shipping_country,
    shipping_state,
  } = customerData;

  const isBilling_countryIndia = billing_country === "India";
  const isShipping_countryIndia = shipping_country === "India";

  if (!name) {
    toast.error("Please enter a valid name");
    return false;
  }
  if (!display_name) {
    toast.error("Please enter a valid display name");
    return false;
  }
  if (!phone || !phone.trim().length || phone.trim().length < 10) {
    toast.error("Please enter valid phone number");
    return false;
  }
  if (!billing_country || !billing_country.trim().length) {
    toast.error("Please select a Billing Country");
    return false;
  }
  if (
    isBilling_countryIndia &&
    (!billing_state || !billing_state.trim().length)
  ) {
    toast.error("Please select a billing state");
    return false;
  }
  if (contactType === "customer" && !customerData.isBillAndShipAddressSame) {
    if (!shipping_name) {
      toast.error("Please enter a valid shipping name");
      return false;
    }
    if (!shipping_display_name) {
      toast.error("Please enter a valid shipping display name");
      return false;
    }
    if (!shipping_country || !shipping_country.trim().length) {
      toast.error("Please select a shipping country");
      return false;
    }
    if (
      isShipping_countryIndia &&
      (!shipping_state || !shipping_state.trim().length)
    ) {
      toast.error("Please select a shipping state");
      return false;
    }
  }

  return true;
};
