import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setSiderBarActiveLink,
  setSiderBarActiveList,
} from "../../../redux-store/sidebarActiveList";
import Select from "react-select";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { todayDate } from "../../../assets/Date";
import axios from "axios";
import {
  allContactApi,
  allProductApi,
  contactApi,
  deliveryChallanApi,
  productApi,
  taxApi,
} from "../../../assets/apis";
import { ChallanTypeList } from "../../../assets/challanTypeList";
import toast from "react-hot-toast";
import {
  hasPermission,
  parseFloatWithFixedValue,
} from "../../../utils/helpers";
import { validateContact } from "../../contacts/contacts/contactValidation";
import { fetchHsns } from "../../Taxes/gsthsn/GstHsn";
import { fetchSacs } from "../../Taxes/gstsac/GstSac";
import { productValidation } from "../../Items/ProductServices/productValidation";
import { fetchCategories } from "../../Items/category/Category";
import { fetchBanks } from "../../banks/Bank";
import { Modal } from "@mui/material";
import { Box } from "@mui/material";
import { unitOfMeasurement } from "../../../assets/UnitOfmeasure";
import { fetchTaxes } from "../../Taxes/Tax/Tax";
import { fetchBrands } from "../../Items/brands/Brand";
import { countryList } from "../../../assets/countryList";
import {
  selectCustomStyle,
  selectStyle,
} from "../../../assets/selectCustomStyle";
import { state } from "../../../assets/StateList";
import PreviewDeliveryChallan from "./PreviewDeliveryChallan";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",

  p: 4,
};

const uomArray = unitOfMeasurement.map((obj, idx) => {
  return {
    label: obj,
    value: obj,
  };
});

const fetchNextDeliveryChallan = async () => {
  const response = await axios.get(`${deliveryChallanApi}/challan_no`);
  return response.data;
};

const AddDeliveryChallan = () => {
  const { FYear } = useSelector((store) => store.FinancialYear);
  const { loggedUser } = useSelector((store) => store.Auth);
  const { companyData } = useSelector((state) => state.Company);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPreview, setIsPreview] = useState(false);
  const [payload, setPayload] = useState({});
  const [apiInProccess, setApiInProccess] = useState(false);
  const [trackIndex, setTrackIndex] = useState(null);
  const queryClient = useQueryClient();
  const [bankArray, setBankArray] = useState([]);
  const [addDeliveryChallan, setAddDeliveryChallan] = useState({
    type: "product",
    date: todayDate,
    dueDate: todayDate,
    quantity: 1,
    discountType: "1",
    invoiceType: "item_wise_discount_and_tax",
    challan_Type: "Job Work",
  });
  const [invoice, setInvoice] = useState([]);
  const [previewInvoice, setPreviewInvoice] = useState([]);
  const [otherCharges, setOtherCharges] = useState({});
  const [otherInfo, setOtherInfo] = useState({
    client_type: { label: "Customer", value: "Customer" },
  });
  const [checkedCharges, setCheckedCharges] = useState({});
  const [addTaxVisible, setAddTaxVisible] = useState(false);
  const [customerData, setCustomerData] = useState({ contactType: "customer" });
  const [addContactVisible, setAddContactVisible] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [hsnArray, setHnsArray] = useState([]);
  const [sacArray, setSacArray] = useState([]);
  const [codeArray, setCodeArray] = useState([]);
  const [addProduct, setAddProduct] = useState({
    type: "product",
  });

  const [addProductVisible, setAddProductVisible] = useState(false);
  const [productArray, setProductArray] = useState([]);
  const [categoryArray, setCategoryArray] = useState([]);
  const [brandArray, setBrandArray] = useState([]);
  const [taxArray, setTaxArray] = useState([]);
  const [finalAmounts, setFinalAmounts] = useState({});
  const [tax, setTax] = useState({ active: true });
  const [addInvoiceLoading, setAddInvoiceLoading] = useState(false);
  const { data: challanNumberData, refetch: fetchNextDeliveryChallanRefetch } =
    useQuery({
      queryKey: ["get_delivery_challan_number"],
      queryFn: fetchNextDeliveryChallan,
    });

  useEffect(() => {
    if (challanNumberData?.challan) {
      setAddDeliveryChallan((prev) => ({
        ...prev,
        challan: challanNumberData.challan,
      }));
    }
  }, [challanNumberData]);
  //up this new states

  useEffect(() => {
    fetchNextDeliveryChallanRefetch();
  }, [FYear.value]);

  const currency = "₹";

  const editItem = (index) => {
    const matchedData = invoice[index];
    const filteredData = invoice.filter((ele, i) => i !== index);
    setInvoice((pre) => [...filteredData]);
    setPreviewInvoice((pre) => [...filteredData]);
    const { quantity, UOM, taxName, code, discount, price, product } =
      matchedData;
    setAddDeliveryChallan((pre) => ({
      ...pre,
      quantity,
      UOM,
      taxName,
      code,
      discount,
      price,
      product,
    }));
  };
  const isClientCompanyStateSame =
    addDeliveryChallan?.checkout_details?.billing_state === companyData?.state;

  const isItemWiseDiscountAndTax =
    addDeliveryChallan?.invoiceType === "item_wise_discount_and_tax";

  const removeItem = (index) => {
    const filteredData = invoice.filter((ele, i) => i !== index);
    setInvoice((pre) => [...filteredData]);
    setPreviewInvoice((pre) => [...filteredData]);
    if (filteredData.length === 0) {
      setAddDeliveryChallan((pre) => ({
        ...pre,
        taxName: "",
        discountValue: "",
      }));
    }
  };

  // my functions here
  const handleSubmit = async (e) => {
    if (!addDeliveryChallan?.name)
      return toast.error("Please Select a Customer");
    if (!addDeliveryChallan?.date) return toast.error("Please Enter a date");
    if (!addDeliveryChallan?.dueDate)
      return toast.error("Please Enter a due date");
    if (!+finalAmounts?.totalAmount)
      return toast.error("Please add atleast one product or service");
    if (+addDeliveryChallan?.paid_amount > +finalAmounts?.grandTotal)
      return toast.error(
        "Paid Amount must be less than or equal to total amount"
      );
    e.preventDefault();
    const payload = {
      type: addDeliveryChallan?.type,
      name: addDeliveryChallan?.name,
      date: addDeliveryChallan?.date,
      dueDate: addDeliveryChallan.dueDate || "",
      challan: +addDeliveryChallan?.challan,
      challan_Type: addDeliveryChallan?.challan_Type,
      invoiceType: addDeliveryChallan?.invoiceType,
      paid_amount: +addDeliveryChallan?.paid_amount || 0,
      discountType: addDeliveryChallan?.discountType,
      taxName: addDeliveryChallan?.taxName,
      round_off: addDeliveryChallan?.round_off,
      round_off_value: +addDeliveryChallan?.round_off_value || 0,
      discountValue: +addDeliveryChallan?.discountValue || 0,
      shipping_address: addDeliveryChallan?.shipping_address,
      checkout_details: addDeliveryChallan?.checkout_details,
      ...(+addDeliveryChallan?.paid_amount && { paid_date: todayDate }),
      balance: +finalAmounts?.balance,
      amount: +finalAmounts?.totalAmount,
      discount: +finalAmounts?.discount,
      GST: +finalAmounts?.GST,
      total_tax: +finalAmounts?.total_tax,
      total: +finalAmounts?.grandTotal,
      SGST: +finalAmounts?.SGST,
      CGST: +finalAmounts?.CGST,
      IGST: +finalAmounts?.IGST,
      all_products: invoice,
      other_charges: otherCharges,
      other_info: otherInfo,
      all_checks: checkedCharges,
    };

    setApiInProccess(true);
    try {
      await axios.post(deliveryChallanApi, payload);
      toast.success("Delivery challan successfully submitted");
      navigate("/invoices/delivery_challan");
    } catch (error) {
      if (error?.response?.status === 403) return navigate("/upgrade");
      if (error.response?.data?.message)
        return toast.error(error.response?.data?.message);
      else toast.error("something went wrong ");
    } finally {
      setApiInProccess(false);
      setPayload({});
    }
  };

  const handlePreview = async () => {
    const payload = {
      type: addDeliveryChallan?.type,
      name: addDeliveryChallan?.name,
      date: addDeliveryChallan?.date,
      dueDate: addDeliveryChallan.dueDate || "",
      challan_Type: addDeliveryChallan?.challan_Type,
      challan: +addDeliveryChallan?.challan,
      invoiceType: addDeliveryChallan?.invoiceType,
      paid_amount: +addDeliveryChallan?.paid_amount || 0,
      discountType: addDeliveryChallan?.discountType,
      taxName: addDeliveryChallan?.taxName,
      round_off: addDeliveryChallan?.round_off,
      round_off_value: +addDeliveryChallan?.round_off_value || 0,
      discountValue: +addDeliveryChallan?.discountValue || 0,
      shipping_address: addDeliveryChallan?.shipping_address,
      checkout_details: addDeliveryChallan?.checkout_details,
      ...(+addDeliveryChallan?.paid_amount && { paid_date: todayDate }),
      balance: +finalAmounts?.balance,
      amount: +finalAmounts?.totalAmount,
      discount: +finalAmounts?.discount,
      GST: +finalAmounts?.GST,
      total_tax: +finalAmounts?.total_tax,
      total: +finalAmounts?.grandTotal,
      SGST: +finalAmounts?.SGST,
      CGST: +finalAmounts?.CGST,
      IGST: +finalAmounts?.IGST,
      all_products: invoice,
      other_charges: otherCharges,
      other_info: otherInfo,
      all_checks: checkedCharges,
    };
    setPayload(payload);
    setIsPreview(true);
  };

  const updateaddDeliveryChallan = (e, selectName) => {
    const updateState = (key, value) => {
      setAddDeliveryChallan((prev) => ({ ...prev, [key]: value }));
    };

    if (selectName) {
      if (selectName === "name") {
        const address =
          e && e.isBillAndShipAddressSame
            ? `${e.billing_address ?? ""} ${e.billing_city ?? ""} ${
                e.billing_state ?? ""
              } ${e.billing_PIN_Code ?? ""} ${e.billing_country ?? ""}`.trim()
            : `${e.shipping_address ?? ""} ${e.shipping_city ?? ""} ${
                e.shipping_state ?? ""
              } ${e.shipping_pin_code ?? ""} ${
                e.shipping_country ?? ""
              }`.trim();
        updateState("name", e.name);
        updateState("shipping_address", address.trim());
        updateState("checkout_details", e);
      } else {
        updateState(selectName, e);
      }
    } else {
      const { name, value } = e.target;
      if (name === "discountType") {
        setAddDeliveryChallan((prev) => ({
          ...prev,
          [name]: value,
          discountValue: 0,
        }));
        return;
      }
      updateState(name, value);
    }
  };

  const handleRoundOff = (e) => {
    const { name, checked } = e.target;
    setAddDeliveryChallan((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleAddDeliveryChallanInputChangeWithConditonTypeNumber = (
    e,
    validationRegex
  ) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      if (name === "discount" && Number(value) > addDeliveryChallan.price) {
        window.alert("Discount Cannot Be Greater Than Unit Price");
        setAddDeliveryChallan((prev) => ({ ...prev, [name]: Number(0) }));
        return;
      }
      if (
        addDeliveryChallan.discountType === "1" &&
        name === "discountValue" &&
        Number(value) > 100
      ) {
        window.alert("Discount cannot be greater than 100");
        setAddDeliveryChallan((prev) => ({ ...prev, [name]: Number(0) }));
        return;
      }
      if (
        addDeliveryChallan.discountType === "2" &&
        name === "discountValue" &&
        Number(value) > Number(finalAmounts?.totalAmount)
      ) {
        window.alert("Discount cannot be greater sub total");
        setAddDeliveryChallan((prev) => ({ ...prev, [name]: Number(0) }));
        return;
      }

      setAddDeliveryChallan((prev) => ({ ...prev, [name]: Number(value) }));
    }
  };
  const handleAddDeliveryChallanInputChangeWithConditonTypeFloat = (
    e,
    validationRegex
  ) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setAddDeliveryChallan((prev) => ({ ...prev, [name]: value }));
    }
  };

  const updateaddDeliveryChallanType = (e, selectName) => {
    if (e === "service") {
      setAddDeliveryChallan((prev) => {
        return {
          ...prev,
          [selectName]: e,
          invoiceType: "invoice_wise_discount_and_tax",
          discount: 0,
          quantity: 1,
          product: "",
          UOM: "",
          code: "",
          taxName: "",
          price: "",
        };
      });
    }
    setAddDeliveryChallan((prev) => {
      return {
        ...prev,
        [selectName]: e,
        discount: 0,
        quantity: 1,
        product: "",
        UOM: "",
        code: "",
        taxName: "",
        price: "",
      };
    });
  };

  const updateaddDeliveryChallanProduct = (e, selectName) => {
    if (!e)
      return setAddDeliveryChallan((prev) => {
        return {
          ...prev,
          discount: 0,
          quantity: 1,
          product: "",
          UOM: "",
          code: "",
          taxName: "",
          price: "",
        };
      });
    const { UOM, hsn_code, sac_code, taxName, s_price } = e;
    const code = addDeliveryChallan.type === "product" ? hsn_code : sac_code;
    const findTax = taxArray.find((tax) => tax.name === taxName);
    const findUOM = uomArray.find((uom) => uom.value === UOM);

    setAddDeliveryChallan((prev) => ({
      ...prev,
      [selectName]: e,
      UOM: findUOM,
      code,
      ...(addDeliveryChallan?.invoiceType === "item_wise_discount_and_tax"
        ? { taxName: findTax }
        : {}),
      price: s_price,
    }));
  };

  const updateOtherInfo = (e, selectName) => {
    if (selectName) {
      const name = selectName;
      const value = e;
      setOtherInfo((prev) => ({ ...prev, [name]: value }));
    } else {
      const name = e.target.name;
      const value = e.target.value;

      setOtherInfo((prev) => ({ ...prev, [name]: value }));
    }
  };

  const updateOtherCharges = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setOtherCharges((prev) => ({ ...prev, [name]: value }));
  };

  const handleOtherChargesInputChangeWithConditonTypeNumber = (
    e,
    validationRegex
  ) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setOtherCharges((prev) => ({ ...prev, [name]: value }));
    }
  };
  const handleOtherChargesInputChangeWithConditonTypeFloat = (
    e,
    validationRegex
  ) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setOtherCharges((prev) => ({ ...prev, [name]: value }));
    }
  };

  const updateCheckBox = (e) => {
    setCheckedCharges((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
    if (!e.target.checked) {
      const name = e.target.name;
      setOtherCharges((prev) => ({ ...prev, [name]: 0 }));
    }
  };

  //tax logic
  const { data: taxData } = useQuery({
    queryKey: ["tax"],
    queryFn: fetchTaxes,
  });

  useEffect(() => {
    if (taxData?.taxes) {
      const temp = taxData?.taxes.map((cat) => {
        return { ...cat, value: cat.name, label: cat.name };
      });
      setTaxArray(temp);
    }
  }, [taxData]);

  const handleTaxInputChangeWithCondition = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setTax((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleTaxInputChangeWithConditonTypeNumber = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setTax((prev) => ({ ...prev, [name]: Number(value) }));
    }
  };

  const createTaxApiCallHandler = async (e) => {
    e.preventDefault();
    const { name, rate, description } = tax;
    const updatedTaxData = {
      ...tax,
      name: name.trim(),
      ...(description && { description: description.trim() }),
    };
    setTax(updatedTaxData);
    if (!updatedTaxData.name) return toast.error("Please enter a name");
    if (+rate > 100) return toast.error("Please enter a valid rate");
    try {
      await axios.post(taxApi, updatedTaxData);
      toast.success("Tax added successfully");
      queryClient.invalidateQueries("tax");
      setTax({
        actve: true,
      });
      setAddTaxVisible(false);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const addInvoice = async (e) => {
    e.preventDefault();
    if (!addDeliveryChallan?.name)
      return toast.error("Please Select a Customer");
    setAddInvoiceLoading(true);
    try {
      if (invoice.length === 0) {
        const response = await axios.get(
          `${deliveryChallanApi}/check_challan_no?challan=${addDeliveryChallan.challan}`
        );
        if (!response.data.success) {
          toast.error("This challan no is not available");
          return;
        }
      }
    } catch (error) {
    } finally {
      setAddInvoiceLoading(false);
    }
    calculateInvoicesTable(addDeliveryChallan);
  };

  function calculateInvoicesTable(addDeliveryChallan) {
    const { quantity, discount, taxName, price } = addDeliveryChallan;

    let amount = Number(quantity) * Number(price);
    let preSubTotal = amount;
    amount = amount - Number(discount || 0);
    let subTotal = amount;
    let itemTax = 0;
    if (taxName && isItemWiseDiscountAndTax) {
      itemTax = parseFloat(((amount * Number(taxName?.rate)) / 100).toFixed(2));
      amount = amount + itemTax;
    }

    const { UOM, code, product, type } = addDeliveryChallan;
    let result = {
      UOM,
      code,
      product,
      quantity,
      discount: 0,
      discountType: "1",
      discountValue: 0,
      taxName,
      subTotal,
      preSubTotal,
      price,
      amount,
      itemTax,
      type,
    };
    setInvoice((pre) => [...pre, result]);
    setPreviewInvoice((pre) => [...pre, result]);
    setAddDeliveryChallan((pre) => ({
      ...pre,
      quantity: 1,
      UOM: "",
      code: "",
      discount: 0,
      price: "",
      product: "",
    }));
  }

  const calculatedAmounts = useMemo(() => {
    let amount = 0;
    let total = 0;
    let paid_amount = 0;
    let balance = 0;
    let total_tax = 0;
    let discount = 0;
    let grandTotal = 0;
    let CGST = 0;
    let SGST = 0;
    let IGST = 0;

    if (isItemWiseDiscountAndTax) {
      invoice.forEach((item) => {
        total = total + Number(item.preSubTotal);
        discount = parseFloatWithFixedValue(
          Number(discount) + Number(item.discount)
        );
        total_tax = total_tax + Number(item.itemTax);
        amount = amount + Number(item.amount);
      });
    } else {
      const {
        discountValue = 0,
        discountType = "2",
        taxName,
      } = addDeliveryChallan;

      invoice.forEach((item) => {
        total += Number(item.preSubTotal);
      });
      amount = total;
      if (discountType === "1" && Number(discountValue)) {
        discount = parseFloatWithFixedValue(
          (Number(amount) * Number(discountValue)) / 100
        );
        amount = amount - discount;
      } else if (discountType === "2" && Number(discountValue)) {
        if (Number(amount) > Number(discountValue)) {
          discount = parseFloatWithFixedValue(Number(discountValue));
          amount = amount - discount;
        } else {
          setAddDeliveryChallan((pre) => ({ ...pre, discountValue: "" }));
        }
      }

      if (taxName && taxName.rate) {
        total_tax = parseFloatWithFixedValue(
          (Number(amount) * Number(taxName.rate)) / 100
        );
        amount = amount + Number(total_tax);
      }
    }

    grandTotal = parseFloatWithFixedValue(amount);

    // if (checkedCharges?.discount && grandTotal > 0) {
    //   if (otherCharges?.discount) {
    //     discount = parseFloat(
    //       ((grandTotal * Number(otherCharges?.discount || 0)) / 100).toFixed(2)
    //     );

    //     grandTotal = parseFloat((grandTotal - discount).toFixed(2));
    //   }
    // }

    if (checkedCharges?.shipping_charges && grandTotal > 0) {
      if (otherCharges?.shipping_charges)
        grandTotal = parseFloatWithFixedValue(
          Number(grandTotal) + Number(otherCharges?.shipping_charges || 0)
        );
    }
    // if (checkedCharges?.e_commerce_gst && grandTotal > 0) {
    //   if (otherCharges?.e_commerce_gst)
    //     GST = Number(otherCharges?.e_commerce_gst || 0);
    //   grandTotal = grandTotal + GST;
    // }

    // if (checkedCharges?.transport && grandTotal > 0) {
    //   if (otherCharges?.transport)
    //     grandTotal = Number(grandTotal) + Number(otherCharges?.transport || 0);
    // }

    if (addDeliveryChallan?.round_off) {
      const roundedGrandTotal = Math.round(Number(grandTotal));
      const roundOffValue = parseFloatWithFixedValue(
        Math.abs(roundedGrandTotal - Number(grandTotal))
      );
      grandTotal = parseFloatWithFixedValue(roundedGrandTotal);
      setAddDeliveryChallan((pre) => ({
        ...pre,
        round_off_value: roundOffValue,
      }));
    }
    balance = grandTotal;
    // if (addDeliveryChallan.paid_amount && grandTotal > 0) {
    //   paid_amount = Number(addDeliveryChallan.paid_amount);
    //   balance = Number((grandTotal - paid_amount).toFixed(2));
    // }
    if (Number(total_tax)) {
      if (isClientCompanyStateSame) {
        CGST = parseFloatWithFixedValue(total_tax / 2);
        SGST = parseFloatWithFixedValue(total_tax / 2);
      } else {
        IGST = parseFloatWithFixedValue(total_tax);
      }
    }
    return {
      totalAmount: parseFloatWithFixedValue(total),
      CGST,
      SGST,
      IGST,
      GST: total_tax,
      total_tax,
      discount,
      paid_amount,
      balance,
      grandTotal,
    };
  }, [
    invoice,
    otherCharges,
    checkedCharges,
    addDeliveryChallan.discountValue,
    addDeliveryChallan.discountType,
    addDeliveryChallan.taxName,
    addDeliveryChallan.round_off,
    isItemWiseDiscountAndTax,
    isClientCompanyStateSame,
    otherCharges,
    checkedCharges,
  ]);

  useEffect(() => {
    setFinalAmounts(calculatedAmounts);
  }, [calculatedAmounts]);
  const handleTempInvoiceInputChangeWithConditonTypeNumber = (
    e,
    validationRegex,
    index,
    taxName
  ) => {
    setTrackIndex(index);
    if (taxName === "taxName") {
      const updatedPreviewInvoice = [...previewInvoice];
      updatedPreviewInvoice[index] = {
        ...updatedPreviewInvoice[index],
        [taxName]: e,
      };
      setPreviewInvoice(updatedPreviewInvoice);
      return;
    }
    const { name, value } = e.target;
    if (name === "discountType") {
      const updatedPreviewInvoice = [...previewInvoice];
      updatedPreviewInvoice[index] = {
        ...updatedPreviewInvoice[index],
        [name]: value,
        discountValue: 0,
        discount: 0,
      };
      setPreviewInvoice(updatedPreviewInvoice);
      return;
    }
    if (
      [...previewInvoice][index].discountType === "1" &&
      name === "discountValue" &&
      (value === "" || validationRegex.test(value)) &&
      Number(value) > 100
    ) {
      window.alert("Discount cannot be greater than 100");
      setPreviewInvoice([...invoice]);
      return;
    }
    if (
      [...previewInvoice][index].discountType === "2" &&
      name === "discountValue" &&
      (value === "" || validationRegex.test(value)) &&
      Number(value) > Number(finalAmounts?.totalAmount)
    ) {
      window.alert("Discount cannot be greater sub total");
      setPreviewInvoice([...invoice]);
      return;
    }

    if (name === "price") {
      const updatedPreviewInvoice = [...previewInvoice];
      updatedPreviewInvoice[index] = {
        ...updatedPreviewInvoice[index],
        [name]: value,
      };
      setPreviewInvoice(updatedPreviewInvoice);
      return;
    }

    if (value === "" || validationRegex.test(value)) {
      const updatedPreviewInvoice = [...previewInvoice];
      updatedPreviewInvoice[index] = {
        ...updatedPreviewInvoice[index],
        [name]: Number(value),
      };
      setPreviewInvoice(updatedPreviewInvoice);
    }
  };

  const handleInputBlur = async () => {
    if (trackIndex !== null) {
      const updatedInvoice = [...previewInvoice];

      let { quantity, product, discount, price } = updatedInvoice[trackIndex];

      if (quantity < 1) {
        setPreviewInvoice([...invoice]); // Reset preview if quantity is invalid
        return;
      }

      let amount =
        Number(updatedInvoice[trackIndex].quantity) *
        Number(updatedInvoice[trackIndex].price);

      let preSubTotal = amount;
      if (
        updatedInvoice[trackIndex].discountType === "1" &&
        Number(updatedInvoice[trackIndex].discountValue)
      ) {
        discount = parseFloatWithFixedValue(
          (Number(amount) * Number(updatedInvoice[trackIndex].discountValue)) /
            100
        );
      } else if (
        updatedInvoice[trackIndex].discountType === "2" &&
        Number(updatedInvoice[trackIndex].discountValue)
      ) {
        discount = parseFloatWithFixedValue(
          Number(updatedInvoice[trackIndex].discountValue)
        );
      } else {
        discount = 0;
      }
      amount = amount - discount;

      const { taxName } = updatedInvoice[trackIndex];
      let subTotal = amount;
      let itemTax = 0;

      if (taxName) {
        itemTax = parseFloat(
          ((amount * Number(taxName?.rate)) / 100).toFixed(2)
        );
        amount += itemTax;
      }

      updatedInvoice[trackIndex] = {
        ...updatedInvoice[trackIndex],
        subTotal: parseFloatWithFixedValue(subTotal),
        preSubTotal: parseFloatWithFixedValue(preSubTotal),
        discount,
        itemTax,
        quantity,
        taxName,
        price,
        amount: parseFloatWithFixedValue(amount),
      };

      setInvoice(updatedInvoice); // Update invoice
      setPreviewInvoice(updatedInvoice); // Also update previewInvoice
      setTrackIndex(null); // Clear trackIndex
    }
  };

  //contact logic
  const updateCustomerData = (e, select_name) => {
    if (select_name) {
      setCustomerData((prev) => ({ ...prev, [select_name]: e }));
    } else {
      const { name, value, type, checked } = e.target;
      const inputValue = type === "checkbox" ? checked : value;
      if (name === "PAN")
        return setCustomerData((prev) => ({
          ...prev,
          [name]: inputValue.toUpperCase(),
        }));
      if (name === "GSTIN")
        return setCustomerData((prev) => ({
          ...prev,
          [name]: inputValue.toUpperCase(),
        }));
      setCustomerData((prev) => ({ ...prev, [name]: inputValue }));
    }
  };

  const handleInputChange = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setCustomerData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const isBilling_countryIndia = customerData.billing_country === "India";
  const isShiping_countryIndia = customerData.shipping_country === "India";

  const handleCountryChange = (value, type) => {
    setCustomerData((prev) => ({
      ...prev,
      [`${type}_country`]: value,
      [`${type}_state`]: "",
    }));
  };

  const saveContact = async (e) => {
    e.preventDefault();
    const { name, display_name, shipping_name, shipping_display_name } =
      customerData;

    let updatedCustomerData = {
      ...customerData,
      name: name.trim(),
      display_name: display_name.trim(),
      ...(shipping_name && { shipping_name: shipping_name.trim() }),
      ...(shipping_display_name && {
        shipping_display_name: shipping_display_name.trim(),
      }),
      isBillAndShipAddressSame: true,
    };

    setCustomerData(updatedCustomerData);
    if (validateContact(updatedCustomerData, updatedCustomerData.contactType)) {
      try {
        await axios.post(contactApi, updatedCustomerData);
        toast.success("Contact added successfully");
        queryClient.invalidateQueries("contacts");
        resetFormAndHideModal();
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  const fetchContacts = async () => {
    try {
      const response = await axios.get(`${allContactApi}`);
      setContacts(
        response.data.contacts
          .filter((i) => i.contactType === "customer")
          .map((item) => ({
            ...item,
            label: item.name,
            value: item.name,
          }))
      );
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };

  const {
    isLoading,
    isError,
    data: contactData,
    error,
  } = useQuery({
    queryKey: ["contacts"],
    queryFn: fetchContacts,
  });

  const resetFormAndHideModal = () => {
    setAddContactVisible(false);
    setCustomerData({ contactType: "customer" });
  };

  const handleApiError = (error) => {
    const errorMessage = error.response?.data?.message || "An error occurred";
    toast.error(errorMessage);
  };

  const handleItemTypeChange = (e, selectName) => {
    setAddProduct((pre) => ({
      ...pre,
      [selectName]: e,
      sac_code: "",
      hsn_code: "",
    }));
  };

  const isProduct = addProduct.type === "product";

  //hsn logic
  const { data: hsnData } = useQuery({
    queryKey: ["hsns"],
    queryFn: fetchHsns,
  });

  const hsnMemoriesArray = useMemo(() => {
    if (!hsnData || !hsnData.gsthsns) return [];

    return hsnData.gsthsns.reduce((acc, hsn) => {
      const { companyId, createdAt, updatedAt, ...rest } = hsn;
      acc.push(rest);
      return acc;
    }, []);
  }, [hsnData]);

  useEffect(() => {
    setHnsArray(hsnMemoriesArray);
  }, [hsnMemoriesArray]);

  //sac logic
  const { data: sacData } = useQuery({
    queryKey: ["sacs"],
    queryFn: fetchSacs,
  });

  const sacMemoriesArray = useMemo(() => {
    if (!sacData || !sacData.gstsacs) return [];
    return sacData.gstsacs.reduce((acc, sac) => {
      const { companyId, createdAt, updatedAt, ...rest } = sac;
      acc.push(rest);
      return acc;
    }, []);
  }, [sacData]);

  useEffect(() => {
    setSacArray(sacMemoriesArray);
  }, [sacMemoriesArray]);

  useEffect(() => {
    let combineArray = [];

    if (isProduct) {
      combineArray = [
        ...hsnMemoriesArray.map(
          ({ name_of_commodity, gst_rate, active, hsn_code, hsn_code_no }) => ({
            name_of_commodity,
            gst_rate,
            active,
            code: hsn_code,
            code_no: hsn_code_no,
          })
        ),
        ...sacMemoriesArray.map(
          ({ name_of_commodity, gst_rate, active, sac_code, sac_code_no }) => ({
            name_of_commodity,
            gst_rate,
            active,
            code: sac_code,
            code_no: sac_code_no,
          })
        ),
      ];
    } else {
      combineArray = sacMemoriesArray.map(
        ({ name_of_commodity, gst_rate, active, sac_code, sac_code_no }) => ({
          name_of_commodity,
          gst_rate,
          active,
          code: sac_code,
          code_no: sac_code_no,
        })
      );
    }

    setCodeArray(combineArray);
    return () => {
      setCodeArray([]);
    };
  }, [isProduct, hsnMemoriesArray, sacMemoriesArray]);

  // product section
  const fetchProducts = async () => {
    const response = await axios.get(`${allProductApi}`);
    const { products } = response.data;
    setProductArray(
      products.map((item) => ({
        ...item,
        label: item.name,
        value: item.name,
      }))
    );
    return response.data;
  };

  const {
    isProductLoading,
    isProductError,
    data: pData,
    productError,
  } = useQuery({
    queryKey: ["product"],
    queryFn: fetchProducts,
  });

  const updateAddProduct = (e, selectName) => {
    const updatedData = selectName
      ? { [selectName]: e }
      : { [e.target.name]: e.target.value };

    setAddProduct((prev) => ({
      ...prev,
      ...updatedData,
    }));
  };

  const handleInputChangeWithCondition = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setAddProduct((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleInputChangeWithConditonTypeNumber = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setAddProduct((prev) => ({ ...prev, [name]: Number(value) }));
    }
  };

  const createProductAPICallHandler = async (e) => {
    e.preventDefault();
    const { name, varient, description } = addProduct;
    let updatedItemData = {
      ...addProduct,
      name: name.trim(),
      ...(varient && { varient: varient.trim() }),
      ...(description && { description: description.trim() }),
    };
    setAddProduct(updatedItemData);
    if (productValidation(updatedItemData)) {
      try {
        await axios.post(productApi, updatedItemData);
        toast.success("Product was successfully submitted");
        queryClient.invalidateQueries("product");
        setAddProduct({
          type: "product",
        });
        setAddProductVisible(false);
      } catch (error) {
        if (error.response?.data?.message)
          return toast.error(error.response?.data?.message);
        else return toast.error("something went wrong ");
      }
    }
  };

  // brand logic
  const {
    isBrandLoading,
    isBrandError,
    data: brandData,
    brandeError,
  } = useQuery({
    queryKey: ["brand"],
    queryFn: fetchBrands,
  });

  useEffect(() => {
    if (brandData?.brands) {
      const temp = brandData.brands.map((cat) => {
        return { ...cat, value: cat.name, label: cat.name };
      });
      setBrandArray(temp);
    }
  }, [brandData]);

  // category logic
  const { data: categoryData } = useQuery({
    queryKey: ["category"],
    queryFn: fetchCategories,
  });

  useEffect(() => {
    if (categoryData?.categories) {
      const temp = categoryData.categories.map((cat) => {
        return { ...cat, value: cat.name, label: cat.name };
      });
      setCategoryArray(temp);
    }
  }, [categoryData]);

  // bank section
  const { data: bankData } = useQuery({
    queryKey: ["bank"],
    queryFn: fetchBanks,
  });

  useEffect(() => {
    if (bankData?.banks) {
      setBankArray(
        bankData.banks.map((item) => ({
          ...item,
          label: item.name,
          value: item.name,
        }))
      );
    }
  }, [bankData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(setSiderBarActiveList({ name: "Invoices" }));
    dispatch(setSiderBarActiveLink({ link: "delivery_challan" }));
  }, []);

  return isPreview ? (
    <PreviewDeliveryChallan
      data={payload}
      handleSubmit={handleSubmit}
      apiInProccess={apiInProccess}
      setIsPreview={setIsPreview}
      setPayload={setPayload}
    />
  ) : (
    <div className={`main-panel`}>
      <div className="content-wrapper">
        <Modal
          open={addContactVisible}
          onClose={() => {
            setAddContactVisible(false);
            setCustomerData({ contactType: "customer" });
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "80%",
                p: 4,
              }}
              className=" card d-flex flex-row rounded rounded-2"
            >
              <form onSubmit={saveContact}>
                <div className="col-lg-12 ">
                  <h5>CONTACT INFORMATION</h5>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Customer Name *</label>
                      <input
                        required
                        value={customerData.name || ""}
                        onChange={(e) => handleInputChange(e, /^[\s\S]*$/)}
                        name="name"
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Display Name *</label>
                      <input
                        required
                        value={customerData.display_name || ""}
                        onChange={(e) => handleInputChange(e, /^[\s\S]*$/)}
                        name="display_name"
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Phone *</label>
                      <input
                        required
                        value={customerData.phone || ""}
                        onChange={(e) => handleInputChange(e, /^[0-9\b]+$/)}
                        name="phone"
                        minLength={10}
                        maxLength={10}
                        type="tel"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        value={customerData.email || ""}
                        onChange={updateCustomerData}
                        name="email"
                        type="email"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>GSTIN</label>
                      <input
                        value={customerData.GSTIN || ""}
                        onChange={updateCustomerData}
                        name="GSTIN"
                        maxLength="15"
                        minLength="15"
                        pattern="[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}"
                        title="GSTIN should be in the format: 11POPLP4627X3Z2"
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>TIN</label>
                      <input
                        value={customerData.TIN || ""}
                        onChange={updateCustomerData}
                        name="TIN"
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>PAN</label>
                      <input
                        value={customerData.PAN || ""}
                        onChange={updateCustomerData}
                        name="PAN"
                        maxLength="10"
                        minLength="10"
                        pattern="[A-Z]{5}[0-9]{4}[A-Z]"
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>VAT NO</label>
                      <input
                        value={customerData.VAT || ""}
                        onChange={updateCustomerData}
                        name="VAT"
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>DL. No.</label>
                      <input
                        value={customerData.DL || ""}
                        onChange={updateCustomerData}
                        name="DL"
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Billing Address</label>
                      <input
                        value={customerData.billing_address || ""}
                        onChange={updateCustomerData}
                        name="billing_address"
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div
                      className="form-group "
                      style={{ flexDirection: "column" }}
                    >
                      <label>Billing Country *</label>
                      <Select
                        required
                        name="billing_country"
                        className=""
                        value={{
                          value: customerData.billing_country || "",
                          label: customerData.billing_country || "",
                        }}
                        onChange={({ value }) =>
                          handleCountryChange(value, "billing")
                        }
                        options={countryList.map((country) => ({
                          label: country,
                          value: country,
                        }))}
                        styles={selectCustomStyle}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>
                        Billing State {isBilling_countryIndia && "*"}
                      </label>
                      {isBilling_countryIndia ? (
                        <Select
                          name="billing_state"
                          className="w-100"
                          required={isBilling_countryIndia}
                          value={{
                            value: customerData.billing_state || "",
                            label: customerData.billing_state || "",
                          }}
                          onChange={({ value }) =>
                            updateCustomerData(value, "billing_state")
                          }
                          options={state.map((state) => ({
                            label: state,
                            value: state,
                          }))}
                          styles={selectCustomStyle}
                        />
                      ) : (
                        <input
                          value={customerData.billing_state || ""}
                          onChange={updateCustomerData}
                          name="billing_state"
                          type="text"
                          className="form-control"
                        />
                      )}
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Billing City</label>
                      <input
                        value={customerData.billing_city || ""}
                        onChange={updateCustomerData}
                        name="billing_city"
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Billing PIN Code</label>
                      <input
                        value={customerData.billing_PIN_Code || ""}
                        onChange={(e) => handleInputChange(e, /^[0-9\b]+$/)}
                        minLength={6}
                        maxLength={6}
                        name="billing_PIN_Code"
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 py-2">
                  <button
                    type="submit"
                    className="btn btn-primary d-flex justify-content-center px-3 py-2"
                  >
                    <i className="mdi mdi-content-save me-1"></i> Save
                  </button>
                </div>
              </form>
            </Box>
          </>
        </Modal>
        {/* Product Modal here */}
        <Modal
          open={addProductVisible}
          onClose={() => {
            setAddProductVisible(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
            className=" card d-flex flex-row rounded rounded-2"
          >
            <div className="">

              <div className="d-flex mt-2 mb-2 justify-content-start">
                <h5>ITEM INFORMATION</h5>
              </div>

              <form className="row py-2" onSubmit={createProductAPICallHandler}>
                <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
                  <label htmlFor="name " className="w-100  mb-2">
                    {isProduct ? "Product Name *" : "Service Name *"}
                  </label>
                  <input
                    value={addProduct.name || ""}
                    onChange={(e) => {
                      handleInputChangeWithCondition(e, /^[\s\S]*$/);
                    }}
                    type="text"
                    className="form-control w-100"
                    placeholder={isProduct ? "Product Name" : "Service Name"}
                    name="name"
                    required
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
                  <label htmlFor="p-name " className="w-100  mb-2">
                    {isProduct ? "Product Variant" : "Service Variant"}
                  </label>
                  <input
                    value={addProduct.varient || ""}
                    onChange={(e) => {
                      handleInputChangeWithCondition(e, /^[\s\S]*$/);
                    }}
                    type="text"
                    className="form-control w-100"
                    placeholder={
                      isProduct ? "Product Variant" : "Service Variant"
                    }
                    name="varient"
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
                  <label
                    htmlFor="p-name "
                    className="w-100 d-flex justify-content-start align-items-center  mb-2"
                  >
                    Category{" "}
                  </label>

                  <Select
                    name="categoryName"
                    className="w-100"
                    value={{
                      label: addProduct.categoryName || "",
                      value: addProduct.categoryName || "",
                    }}
                    onChange={(selectedOption) => {
                      updateAddProduct(
                        selectedOption?.value || "",
                        "categoryName"
                      );
                    }}
                    isClearable
                    options={categoryArray.map((category) => ({
                      label: category.name,
                      value: category.name,
                    }))}
                    styles={selectCustomStyle}
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
                  <label
                    htmlFor="p-name "
                    className="w-100 d-flex justify-content-start align-items-center  mb-2"
                  >
                    Brand{" "}
                  </label>
                  <Select
                    name="brandName"
                    className="w-100"
                    value={{
                      label: addProduct.brandName || "",
                      value: addProduct.brandName || "",
                    }}
                    onChange={(selectedOption) => {
                      updateAddProduct(
                        selectedOption?.value || "",
                        "brandName"
                      );
                    }}
                    isClearable
                    options={brandArray.map((brand) => ({
                      label: brand.name,
                      value: brand.name,
                    }))}
                    styles={selectCustomStyle}
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
                  <label
                    htmlFor="p-name "
                    className="w-100 d-flex justify-content-start align-items-center  mb-2"
                  >
                    Type{" "}
                  </label>
                  <Select
                    name="type"
                    className="w-100"
                    value={{
                      label: addProduct.type || "",
                      value: addProduct.type || "",
                    }}
                    onChange={(selectedOption) => {
                      handleItemTypeChange(selectedOption?.value || "", "type");
                    }}
                    options={[
                      { label: "product", value: "product" },
                      { label: "service", value: "service" },
                    ]}
                    styles={selectCustomStyle}
                  />
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
                  <label htmlFor="p-name " className="w-100  mb-2">
                    {isProduct ? "HSN/SAC Code " : "SAC Code"}
                  </label>
                  <Select
                    name={isProduct ? "hsn_code" : "sac_code"}
                    className="w-100"
                    value={{
                      label: isProduct
                        ? addProduct.hsn_code || ""
                        : addProduct.sac_code || "",
                      value: isProduct
                        ? addProduct.hsn_code || ""
                        : addProduct.sac_code || "",
                    }}
                    onChange={(selectedOption) => {
                      updateAddProduct(
                        selectedOption?.value || "",
                        isProduct ? "hsn_code" : "sac_code"
                      );
                    }}
                    isClearable
                    options={codeArray.map(({ code }) => ({
                      label: code,
                      value: code,
                    }))}
                    styles={selectCustomStyle}
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
                  <label
                    htmlFor="p-name "
                    className="w-100 d-flex justify-content-start align-items-center  mb-2"
                  >
                    Tax{" "}
                  </label>
                  <Select
                    name="taxName"
                    className="w-100"
                    value={{
                      label: addProduct.taxName || "",
                      value: addProduct.taxName || "",
                    }}
                    onChange={(selectedOption) => {
                      updateAddProduct(selectedOption?.value || "", "taxName");
                    }}
                    isClearable
                    options={taxArray.map((tax) => ({
                      label: tax.name,
                      value: tax.name,
                    }))}
                    styles={selectCustomStyle}
                  />
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
                  <label htmlFor="p-name " className="w-100  mb-2">
                    MRP Price
                  </label>
                  <input
                    type="text"
                    className="form-control w-100"
                    placeholder="MRP Price"
                    name="mrp_price"
                    value={addProduct.mrp_price || ""}
                    onChange={(e) =>
                      handleInputChangeWithConditonTypeNumber(e, /^[0-9\b]+$/)
                    }
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
                  <label htmlFor="p-name " className="w-100  mb-2">
                    Purchase Price
                  </label>
                  <input
                    value={addProduct.p_price || ""}
                    onChange={(e) =>
                      handleInputChangeWithConditonTypeNumber(e, /^[0-9\b]+$/)
                    }
                    type="text"
                    className="form-control w-100"
                    placeholder="Purchase Price"
                    name="p_price"
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
                  <label htmlFor="p-name " className="w-100  mb-2">
                    Sales Price*(Net)
                  </label>
                  <input
                    value={addProduct.s_price || ""}
                    onChange={(e) =>
                      handleInputChangeWithConditonTypeNumber(e, /^[0-9\b]+$/)
                    }
                    type="text"
                    className="form-control w-100"
                    placeholder="Sales Price*(Net)"
                    name="s_price"
                    required
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
                  <label
                    htmlFor="p-name "
                    className="w-100 d-flex justify-content-start align-items-center  mb-2"
                  >
                    UoM (Unit Of Measurement){" "}
                  </label>
                  <Select
                    name="UOM"
                    className="w-100"
                    value={{
                      label: addProduct.UOM || "",
                      value: addProduct.UOM || "",
                    }}
                    onChange={(selectedOption = {}) => {
                      updateAddProduct(selectedOption?.value || "", "UOM");
                    }}
                    isClearable
                    options={unitOfMeasurement.map((UoM) => ({
                      label: UoM,
                      value: UoM,
                    }))}
                    styles={selectCustomStyle}
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
                  <label htmlFor="p-name " className="w-100  mb-2">
                    Opening Qty per
                  </label>
                  <input
                    type="text"
                    className="form-control w-100"
                    placeholder="Opening Qty per"
                    name="opening_qty_per"
                    value={addProduct.opening_qty_per || ""}
                    onChange={(e) =>
                      handleInputChangeWithConditonTypeNumber(e, /^[0-9\b]+$/)
                    }
                  />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                  <label htmlFor="p-name " className="w-100  mb-2">
                    Description
                  </label>
                  <textarea
                    style={{
                      height: "auto",
                      minHeight: "100px", // Adjust this value based on your requirement
                    }}
                    className="form-control w-100"
                    placeholder="Description"
                    name="description"
                    value={addProduct.description || ""}
                    onChange={updateAddProduct}
                  />
                </div>

                <div className="col-lg-12">
                  <button
                    className="btn btn-primary d-flex justify-content-center px-3 py-2"
                    type="submit"
                  >
                    <i className="mdi mdi-content-save me-1"></i> Save
                  </button>
                </div>
              </form>
            </div>
          </Box>
        </Modal>

        <Modal
          open={addTaxVisible}
          onClose={(e) => setAddTaxVisible(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          header="Add Category"
          className="col-sm-12"
        >
          <Box sx={style}>
            <h4>Add Tax</h4>
            <form onSubmit={createTaxApiCallHandler}>
              <div className="d-flex flex-row align-items-center gap-3 mb-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Tax Name"
                  name="name"
                  value={tax.name || ""}
                  onChange={(e) => {
                    handleTaxInputChangeWithCondition(e, /^[\s\S]*$/);
                  }}
                  required
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Tax (%)"
                  minLength={1}
                  maxLength={3}
                  name="rate"
                  value={tax.rate || ""}
                  onChange={(e) =>
                    handleTaxInputChangeWithConditonTypeNumber(e, /^[0-9\b]+$/)
                  }
                  required
                />
              </div>

              <div className="col-md-6 ">
                <label>Active ? </label>{" "}
                <input
                  type="checkbox"
                  name="active"
                  checked={tax.active || ""}
                  onChange={(e) =>
                    setTax((prev) => {
                      return { ...prev, active: e.target.checked };
                    })
                  }
                />
              </div>

              <button
                type="submit"
                className="btn btn-primary d-flex justify-content-center  mt-2 px-3 py-2"
              >
                Save
              </button>
            </form>
          </Box>
        </Modal>

        <div className="row">
          <div className="col-sm-12">
            <div className="d-flex justify-content-between m-1 p-1 align-items-baseline ">
              <h3 className="ukhd mb-3">Add Delivery Challan</h3>
              <button
                type="button"
                className="btn btn-warning btn-sm"
                onClick={() => navigate("/invoices/delivery_challan")}
              >
                <div className="d-flex justify-content-center">
                  <i className="mdi mdi mdi-keyboard-backspace"></i>
                  <span>Back</span>
                </div>
              </button>
            </div>

            <div className="row flex-grow">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card card-rounded">
                  <div className="card-body">
                    <form className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <div className="select-container ">
                            <label className="col-lg-12 ">Item Type</label>
                            <Select
                              // styles={customStyles}
                              name="type"
                              className="w-100"
                              placeholder="Select Type"
                              value={{
                                value: addDeliveryChallan.type || "",

                                label: addDeliveryChallan.type
                                  ? addDeliveryChallan.type === "product"
                                    ? "Product"
                                    : "Service"
                                  : "",
                              }}
                              onChange={(selectedOption = {}) => {
                                updateaddDeliveryChallanType(
                                  selectedOption?.value || "",
                                  "type"
                                );
                              }}
                              options={[
                                { label: "Product", value: "product" },
                                { label: "Service", value: "service" },
                              ]}
                              isDisabled={invoice?.length > 0}
                              styles={selectCustomStyle}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <div className="select-container ">
                            <label className="col-lg-12 ">* Invoice Type</label>
                            <Select
                              // styles={customStyles}
                              name="type"
                              className="w-100"
                              placeholder="Select Type"
                              value={{
                                value: addDeliveryChallan.invoiceType || "",
                                label: addDeliveryChallan.invoiceType
                                  ? addDeliveryChallan.invoiceType ===
                                    "item_wise_discount_and_tax"
                                    ? "Item wise discount and tax"
                                    : "Invoice wise discount and tax"
                                  : "",
                              }}
                              onChange={(selectedOption = {}) => {
                                updateaddDeliveryChallanType(
                                  selectedOption?.value || "",
                                  "invoiceType"
                                );
                              }}
                              options={[
                                {
                                  label: "Item wise discount and tax",
                                  value: "item_wise_discount_and_tax",
                                },
                                {
                                  label: "Invoice wise discount and tax",
                                  value: "invoice_wise_discount_and_tax",
                                },
                              ]}
                              isDisabled={
                                invoice?.length > 0 ||
                                addDeliveryChallan?.type === "service"
                              }
                              styles={selectCustomStyle}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <label>* Date</label>
                          <input
                            type="date"
                            className="form-control form-control-alternative"
                            name="date"
                            max={todayDate}
                            value={addDeliveryChallan.date || ""}
                            onChange={updateaddDeliveryChallan}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <label>* Due Date </label>
                          <input
                            type="date"
                            className="form-control form-control-alternative"
                            name="dueDate"
                            min={todayDate}
                            value={addDeliveryChallan.dueDate || ""}
                            onChange={updateaddDeliveryChallan}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 ">
                        <div className="row">
                          <div className="col-md-3">
                            <div className="form-group">
                              <div className="select-container ">
                                <label className="col-lg-12 ">
                                  {" "}
                                  * Customer{" "}
                                  {hasPermission(loggedUser, 1) && (
                                    <i
                                      className="mdi mdi-plus text-success fw-bold h4"
                                      data-toggle="modal"
                                      onClick={() => setAddContactVisible(true)}
                                    ></i>
                                  )}
                                </label>
                                <Select
                                  // styles={customStyles}
                                  name="name"
                                  className="w-100"
                                  value={{
                                    label: addDeliveryChallan.name || "",
                                    value: addDeliveryChallan.name || "",
                                  }}
                                  onChange={(val) =>
                                    updateaddDeliveryChallan(val, "name")
                                  }
                                  options={contacts}
                                  isDisabled={invoice?.length > 0}
                                  styles={selectCustomStyle}
                                />
                                <span style={{ color: "green" }}>
                                  {
                                    addDeliveryChallan?.checkout_details
                                      ?.billing_state
                                  }
                                  {", "}
                                  {
                                    addDeliveryChallan?.checkout_details
                                      ?.billing_country
                                  }
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="col-lg-12 ">
                                {" "}
                                Shipping Address
                              </label>

                              <input
                                type="text"
                                className="form-control form-control-alternative"
                                name="shipping_address"
                                defaultValue={
                                  addDeliveryChallan.shipping_address || ""
                                }
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <div className="select-container ">
                                <label className="col-lg-12 ">
                                  * Challan Type
                                </label>
                                <Select
                                  // styles={customStyles}
                                  name="type"
                                  className="w-100"
                                  placeholder="Select Type"
                                  value={{
                                    value:
                                      addDeliveryChallan.challan_Type || "",
                                    label:
                                      addDeliveryChallan.challan_Type || "",
                                  }}
                                  onChange={(selectedOption = {}) => {
                                    updateaddDeliveryChallanType(
                                      selectedOption?.value || "",
                                      "challan_Type"
                                    );
                                  }}
                                  options={ChallanTypeList}
                                  isDisabled={
                                    invoice?.length > 0 ||
                                    addDeliveryChallan?.type === "service"
                                  }
                                  styles={selectCustomStyle}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>Challan No.</label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control form-control-alternative"
                                  name="challan"
                                  placeholder={
                                    challanNumberData?.challan ||
                                    "Challan number"
                                  }
                                  value={addDeliveryChallan.challan || ""}
                                  onChange={(e) =>
                                    handleAddDeliveryChallanInputChangeWithConditonTypeNumber(
                                      e,
                                      /^[0-9\b]+$/
                                    )
                                  }
                                  readOnly={invoice.length > 0}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <div
                                className="select-container "
                                title="First select invoice type and customer"
                              >
                                <label
                                  htmlFor="p-name "
                                  className="w-100 d-flex justify-content-start align-items-center  mb-2"
                                >
                                  Item{" "}
                                  {hasPermission(loggedUser, 11) && (
                                    <span
                                      className=""
                                      style={{ cursor: "pointer" }}
                                    >
                                      <i
                                        onClick={() =>
                                          setAddProductVisible(true)
                                        }
                                        className="mdi text-success  fw-bold mdi-plus"
                                      />
                                    </span>
                                  )}
                                </label>
                                <Select
                                  // styles={customStyles}
                                  name="type"
                                  className="w-100"
                                  value={addDeliveryChallan.product || ""}
                                  onChange={(val) =>
                                    updateaddDeliveryChallanProduct(
                                      val,
                                      "product"
                                    )
                                  }
                                  isClearable
                                  options={productArray.filter(
                                    (item) =>
                                      item.type === addDeliveryChallan["type"]
                                  )}
                                  isOptionDisabled={(option) =>
                                    invoice?.some(
                                      (product) =>
                                        product?.product?.id === option?.id
                                    )
                                  }
                                  isDisabled={
                                    !addDeliveryChallan?.name ||
                                    !addDeliveryChallan?.invoiceType
                                  }
                                  styles={selectCustomStyle}
                                />
                              </div>
                            </div>
                          </div>

                          {/* <div className="col-3">
                              <div className="form-group">
                                <label> HSN/SAC Code </label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control form-control-alternative"
                                    placeholder="HSN/SAC Code"
                                    name="hsn_sac"
                                    defaultValue={addDeliveryChallan.code || ""}
                                    readOnly
                                  />
                                </div>
                              </div>
                            </div> */}
                          <div className="col-md-2 d-flex align-items-end justify-content-end">
                            <div
                              className="form-group"
                              title="First select invoice type and customer"
                            >
                              <button
                                type="submit"
                                className="btn btn-primary p-2"
                                onClick={addInvoice}
                                disabled={
                                  addDeliveryChallan.quantity < 1 ||
                                  !addDeliveryChallan.challan ||
                                  !addDeliveryChallan.product ||
                                  addDeliveryChallan.price < 1 ||
                                  addInvoiceLoading
                                }
                              >
                                <div className="d-flex justify-content-center">
                                  {" "}
                                  <i className="mdi mdi-plus fw-bold"></i>{" "}
                                  {addInvoiceLoading ? "Adding..." : "Add"}
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-2">
                          <div className="form-group">
                            <label> Quantity </label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control form-control-alternative"
                                placeholder="Quantity"
                                name="quantity"
                                value={addDeliveryChallan.quantity || ""}
                                onChange={(e) =>
                                  handleAddDeliveryChallanInputChangeWithConditonTypeNumber(
                                    e,
                                    /^[0-9\b]+$/
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label> Unit Price </label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control form-control-alternative"
                                placeholder="Unit Price"
                                name="price"
                                value={addDeliveryChallan.price || ""}
                                onChange={(e) =>
                                  handleAddDeliveryChallanInputChangeWithConditonTypeNumber(
                                    e,
                                    /^[0-9\b]+$/
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label> Discount ({currency})</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control form-control-alternative"
                                placeholder="Discount"
                                name="discount"
                                value={addDeliveryChallan.discount || ""}
                                onChange={(e) =>
                                  handleAddDeliveryChallanInputChangeWithConditonTypeNumber(
                                    e,
                                    /^[0-9\b]+$/
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <div className="select-container ">
                              <label className="col-12 ">
                                UoM (Unit of Measurement)
                              </label>
  
                              <input
                                type="text"
                                className="form-control form-control-alternative"
                                name="UOM"
                                placeholder=""
                                defaultValue={addDeliveryChallan?.UOM?.value || ""}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
  
                        <div className="col-2">
                          <div className="form-group">
                            <div className="select-container ">
                              <label
                                htmlFor="p-name "
                                className="w-100 d-flex justify-content-start align-items-center  mb-2"
                              >
                                Tax{" "}
                                {hasPermission(loggedUser, 22) && (
                                  <span
                                    className=""
                                    style={{ cursor: "pointer" }}
                                  >
                                    <i
                                      onClick={() => setAddTaxVisible(true)}
                                      className="mdi text-success  fw-bold mdi-plus"
                                    />
                                  </span>
                                )}
                              </label>
                              <Select
                                // styles={customStyles}
                                name="taxName"
                                className="w-100"
                                value={addDeliveryChallan.taxName || ""}
                                onChange={(val) =>
                                  updateaddDeliveryChallan(val, "taxName")
                                }
                                isClearable
                                options={taxArray}
                                styles={selectCustomStyle}
                              />
                            </div>
                          </div>
                        </div> */}
                    </form>
                    <div className="table-responsive table-alone mt-1 ">
                      <table className="table select-table ">
                        <thead>
                          <tr>
                            <th>S.No.</th>
                            <th> Item</th>
                            {/* <th>Hsn/SAC Code</th> */}
                            <th> Quantity</th>
                            <th> Net Price</th>
                            {isItemWiseDiscountAndTax ? <th>Discount</th> : ""}
                            <th>Amount</th>
                            {isItemWiseDiscountAndTax ? (
                              <th>
                                Tax{" "}
                                {hasPermission(loggedUser, 22) && (
                                  <span
                                    className=""
                                    style={{ cursor: "pointer" }}
                                  >
                                    <i
                                      onClick={() => setAddTaxVisible(true)}
                                      className="mdi text-success  fw-bold mdi-plus"
                                    />
                                  </span>
                                )}
                              </th>
                            ) : (
                              ""
                            )}
                            <th>Action</th>
                          </tr>
                        </thead>

                        {!previewInvoice.length ? (
                          <tbody>
                            <tr>
                              <td colSpan="7" className="text-center">
                                No data found
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          previewInvoice?.map((item, i) => {
                            const code = item?.code;
                            const name = item?.product?.value;
                            const type = item?.type;
                            const tax = item?.taxName?.rate || 0;
                            const discount = item?.discount || 0;
                            const subTotal = item?.subTotal || 0;

                            return (
                              <tbody className="border-table-row " key={i}>
                                <tr>
                                  <td>
                                    <h6 className="text-dark font-weight">
                                      {i + 1}
                                    </h6>
                                  </td>
                                  <td>
                                    <h5 className="text-dark font-weight">
                                      {name}
                                    </h5>
                                    {/* <span
                                        className={`table-color-col ${
                                          item.type ? "bg-success" : "bg-warning"
                                        } text-light font-weight`}
                                      >
                                        {type}
                                      </span> */}
                                  </td>
                                  {/* <td>
                                      <h6>{code}</h6>
                                    </td> */}
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control form-control-alternative responsiveinput"
                                      style={{
                                        maxWidth: "3.6rem",
                                        padding: ".4rem .6rem",
                                      }}
                                      placeholder=""
                                      name="quantity"
                                      value={item.quantity || ""}
                                      onChange={(e) =>
                                        handleTempInvoiceInputChangeWithConditonTypeNumber(
                                          e,
                                          /^[0-9\b]+$/,
                                          i
                                        )
                                      }
                                      onBlur={handleInputBlur}
                                      disabled={
                                        addDeliveryChallan?.type === "service"
                                      }
                                    />
                                  </td>
                                  <td>
                                    <h6>
                                      <input
                                        type="text"
                                        className="form-control form-control-alternative responsiveinput"
                                        style={{
                                          maxWidth: "4.6rem",
                                          padding: ".4rem .6rem",
                                        }}
                                        placeholder=""
                                        name="price"
                                        value={item.price || ""}
                                        onChange={(e) =>
                                          handleTempInvoiceInputChangeWithConditonTypeNumber(
                                            e,
                                            /^\d*\.?\d{0,2}$/,
                                            i
                                          )
                                        }
                                        onBlur={handleInputBlur}
                                      />
                                    </h6>
                                  </td>
                                  {isItemWiseDiscountAndTax ? (
                                    <td
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <select
                                        name="discountType"
                                        className="form-control form-control-alternative responsiveinput"
                                        style={{
                                          maxWidth: "3.6rem",
                                          padding: ".355rem .6rem",
                                          borderBottomRightRadius: "0",
                                          borderTopRightRadius: "0",
                                          color: "black",
                                          lineHeight: ".8rem",
                                        }}
                                        value={item.discountType || ""}
                                        onChange={(e) =>
                                          handleTempInvoiceInputChangeWithConditonTypeNumber(
                                            e,
                                            "",
                                            i
                                          )
                                        }
                                        onBlur={handleInputBlur}
                                      >
                                        <option value="2">₹</option>
                                        <option value="1">%</option>
                                      </select>
                                      <input
                                        type="text"
                                        className="form-control form-control-alternative responsiveinput"
                                        style={{
                                          maxWidth: "3.6rem",
                                          padding: ".4rem .6rem",
                                          borderBottomLeftRadius: "0",
                                          borderTopLeftRadius: "0",
                                        }}
                                        placeholder="0"
                                        name="discountValue"
                                        value={item.discountValue || ""}
                                        onChange={(e) =>
                                          handleTempInvoiceInputChangeWithConditonTypeNumber(
                                            e,
                                            /^[0-9\b]+$/,
                                            i
                                          )
                                        }
                                        onBlur={handleInputBlur}
                                      />
                                      {/* <input
                                          type="text"
                                          className="form-control form-control-alternative"
                                          style={{
                                            maxWidth: "3.6rem",
                                            padding: ".4rem .6rem",
                                          }}
                                          placeholder=""
                                          name="discount"
                                          value={item.discount || ""}
                                          onChange={(e) =>
                                            handleTempInvoiceInputChangeWithConditonTypeNumber(
                                              e,
                                              /^[0-9\b]+$/,
                                              i
                                            )
                                          }
                                          onBlur={handleInputBlur}
                                        /> */}
                                    </td>
                                  ) : (
                                    ""
                                  )}
                                  <td>
                                    <h6>
                                      {currency}&nbsp;
                                      {subTotal}
                                    </h6>
                                  </td>
                                  {isItemWiseDiscountAndTax ? (
                                    <td>
                                      <select
                                        name="taxName"
                                        style={{
                                          maxWidth: "7rem",
                                          padding: ".355rem .6rem",
                                          height: "2.2rem",
                                          color: "black",
                                          lineHeight: "1.2rem",
                                        }}
                                        onBlur={handleInputBlur}
                                        className="form-control form-control-alternative responsiveselect"
                                        onChange={(e) =>
                                          handleTempInvoiceInputChangeWithConditonTypeNumber(
                                            taxArray.find(
                                              (tax) =>
                                                tax.value === e.target.value
                                            ),
                                            "",
                                            i,
                                            "taxName"
                                          )
                                        }
                                        value={item?.taxName?.value || ""}
                                      >
                                        <option value="">
                                          -&nbsp;-select-&nbsp;-
                                        </option>
                                        {taxArray.length > 0
                                          ? taxArray.map((tax, index) => (
                                              <option
                                                value={tax.value}
                                                key={index}
                                              >
                                                {`${tax.label}${" "}(${
                                                  tax.rate
                                                }%)`}
                                              </option>
                                            ))
                                          : ""}
                                      </select>
                                    </td>
                                  ) : (
                                    ""
                                  )}

                                  <td>
                                    <div className="d-flex gap-1">
                                      {/* <i
                                          onClick={() => editItem(i)}
                                          className="dropdown-item-icon mdi mdi-pen text-primary me-2 hover-cursor"
                                        /> */}
                                      <i
                                        className="fs-30 mdi mdi-archive text-danger hover-cursor"
                                        onClick={() => {
                                          removeItem(i);
                                        }}
                                      ></i>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            );
                          })
                        )}
                      </table>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-lg-8">
                        {/* <h6 className="heading-small text-muted mb-4">
                            Other Charges
                          </h6> */}
                        {/* <div className="row">
                            <div className="col-lg">
                              <div className="form-group">
                                <input
                                  type="checkbox"
                                  className="is_checked"
                                  checked={!!checkedCharges.shipping_charges}
                                  name="shipping_charges"
                                  onChange={updateCheckBox}
                                />{" "}
                                <span className="other-charge-font">
                                  Shipping Charges ({currency})
                                </span>
                              </div>
                            </div>
                            <div className="col-lg">
                              <div className="form-group focused">
                                {checkedCharges.shipping_charges && (
                                  <input
                                    type="text"
                                    onChange={(e) =>
                                      handleOtherChargesInputChangeWithConditonTypeNumber(
                                        e,
                                        /^[0-9\b]+$/
                                      )
                                    }
                                    value={otherCharges.shipping_charges || ""}
                                    style={{}}
                                    className="form-control form-control-alternative extra-charge"
                                    name="shipping_charges"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="col-lg">
                              <div className="form-group">
                                <input
                                  type="checkbox"
                                  className="is_checked"
                                  checked={!!checkedCharges.e_commerce_gst}
                                  name="e_commerce_gst"
                                  onChange={updateCheckBox}
                                />{" "}
                                <span className="other-charge-font">
                                  E Commerce GST ({currency})
                                </span>
                              </div>
                            </div>
                            <div className="col-lg">
                              <div className="form-group">
                                {checkedCharges.e_commerce_gst && (
                                  <input
                                    type="text"
                                    id="e_commerce_gst"
                                    className="form-control form-control-alternative extra-charge"
                                    name="e_commerce_gst"
                                    onChange={(e) =>
                                      handleOtherChargesInputChangeWithConditonTypeNumber(
                                        e,
                                        /^[0-9\b]+$/
                                      )
                                    }
                                    value={otherCharges.e_commerce_gst || ""}
                                  />
                                )}
                              </div>
                            </div>
                          </div> */}

                        {/* <div className="row">
                            
                              <div className="col-lg">
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    className="is_checked"
                                    checked={!!checkedCharges.discount}
                                    name="discount"
                                    onChange={updateCheckBox}
                                  />{" "}
                                  <span className="other-charge-font">
                                    Total Discount(%)
                                  </span>
                                </div>
                              </div>
                            
                            <div className="col-lg">
                              <div className="form-group focused">
                                {checkedCharges.discount && (
                                  <input
                                    type="text"
                                    style={{}}
                                    name="discount"
                                    onChange={(e) =>
                                      handleOtherChargesInputChangeWithConditonTypeNumber(
                                        e,
                                        /^[0-9\b]+$/
                                      )
                                    }
                                    maxLength={3}
                                    value={otherCharges.discount || ""}
                                    className="form-control form-control-alternative extra-charge"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="col-lg">
                              <div className="form-group">
                                <input
                                  type="checkbox"
                                  className="is_checked"
                                  name="transport"
                                  checked={!!checkedCharges.transport}
                                  onChange={updateCheckBox}
                                />
                                <span className="other-charge-font">
                                  {" "}
                                  Transport ({currency})
                                </span>
                              </div>
                            </div>
                            <div className="col-lg">
                              <div className="form-group">
                                {checkedCharges.transport && (
                                  <input
                                    type="text"
                                    id="transport"
                                    name="transport"
                                    className="form-control form-control-alternative extra-charge"
                                    onChange={(e) =>
                                      handleOtherChargesInputChangeWithConditonTypeNumber(
                                        e,
                                        /^[0-9\b]+$/
                                      )
                                    }
                                    value={otherCharges.transport || ""}
                                  />
                                )}
                              </div>
                            </div>
                          </div> */}

                        <div className="row">
                          {/* <div className="col-lg">
                              <div className="form-group">
                                <input
                                  type="checkbox"
                                  className="is_checked"
                                  checked={!!checkedCharges.round_off}
                                  name="round_off"
                                  onChange={updateCheckBox}
                                />{" "}
                                <span className="other-charge-font">
                                  Round Off
                                </span>
                              </div>
                              </div> */}
                          {addDeliveryChallan?.invoiceType ? (
                            !isItemWiseDiscountAndTax ? (
                              <>
                                <div className="col-lg-6 row mb-2">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <div className="select-container ">
                                        <label
                                          htmlFor="p-name "
                                          className="w-100 d-flex justify-content-start align-items-center  mb-2"
                                        >
                                          Discount Type
                                        </label>
                                        <select
                                          name="discountType"
                                          className="form-control form-control-alternative"
                                          style={{
                                            ...selectStyle,
                                            padding: ".8rem",
                                          }}
                                          value={
                                            addDeliveryChallan.discountType ||
                                            ""
                                          }
                                          onChange={updateaddDeliveryChallan}
                                          // styles={selectStyle}
                                        >
                                          <option value="2">₹</option>
                                          <option value="1">%</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Discount</label>
                                      <input
                                        type="text"
                                        className="form-control form-control-alternative"
                                        name="discountValue"
                                        placeholder="0"
                                        value={
                                          addDeliveryChallan.discountValue || ""
                                        }
                                        onChange={(e) =>
                                          handleAddDeliveryChallanInputChangeWithConditonTypeNumber(
                                            e,
                                            /^[0-9\b]+$/
                                          )
                                        }
                                        disabled={!+finalAmounts?.totalAmount}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 mb-2">
                                  <div className="form-group">
                                    <div className="select-container ">
                                      <label
                                        htmlFor="p-name "
                                        className="w-100 d-flex justify-content-start align-items-center  mb-2"
                                      >
                                        Tax{" "}
                                        {hasPermission(loggedUser, 22) && (
                                          <span
                                            className=""
                                            style={{ cursor: "pointer" }}
                                          >
                                            <i
                                              onClick={() =>
                                                setAddTaxVisible(true)
                                              }
                                              className="mdi text-success  fw-bold mdi-plus"
                                            />
                                          </span>
                                        )}
                                      </label>
                                      <Select
                                        // styles={customStyles}
                                        name="taxName"
                                        className="w-100"
                                        value={addDeliveryChallan.taxName || ""}
                                        onChange={(val) =>
                                          updateaddDeliveryChallan(
                                            val,
                                            "taxName"
                                          )
                                        }
                                        isClearable
                                        options={taxArray}
                                        styles={selectCustomStyle}
                                        isDisabled={!finalAmounts?.totalAmount}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}

                          {/* <div className="col-lg">
                              <div className="form-group focused">
                                {checkedCharges.round_off && (
                                  <input
                                    type="number"
                                    id="round_off"
                                    readOnly
                                    onChange={updateOtherCharges}
                                    className="form-control form-control-alternative extra-charge"
                                    name="round_off_value"
                                  />
                                )}
                              </div>
                            </div> */}
                        </div>
                        <div className="col-lg-12 row mb-2">
                          <div className="col-lg-3">
                            <div className="form-group">
                              <input
                                type="checkbox"
                                className="is_checked"
                                checked={!!checkedCharges.shipping_charges}
                                name="shipping_charges"
                                onChange={updateCheckBox}
                              />{" "}
                              <span className="other-charge-font">
                                Shipping Charges ({currency})
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="form-group focused">
                              {checkedCharges.shipping_charges && (
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    handleOtherChargesInputChangeWithConditonTypeFloat(
                                      e,
                                      /^\d*\.?\d{0,2}$/
                                    )
                                  }
                                  value={otherCharges.shipping_charges || ""}
                                  style={{}}
                                  className="form-control form-control-alternative extra-charge"
                                  name="shipping_charges"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 row mb-2">
                          <div className="col-lg-3">
                            <div className="form-group">
                              <input
                                type="checkbox"
                                className="is_checked"
                                checked={!!addDeliveryChallan.round_off}
                                name="round_off"
                                onChange={handleRoundOff}
                              />{" "}
                              <span className="other-charge-font">
                                {" "}
                                Round Off
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="form-group">
                              {addDeliveryChallan.round_off && (
                                <input
                                  type="text"
                                  className="form-control form-control-alternative extra-charge"
                                  name="round_off_value"
                                  value={
                                    addDeliveryChallan?.round_off_value || ""
                                  }
                                  readOnly
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-lg">
                              <div className="form-group">
                                <input
                                  type="checkbox"
                                  className="is_checked"
                                  checked={!!checkedCharges.lr_no}
                                  name="lr_no"
                                  onChange={updateCheckBox}
                                />{" "}
                                <span className="other-charge-font">
                                  {" "}
                                  L.R. No.
                                </span>
                              </div>
                            </div>
                            <div className="col-lg">
                              <div className="form-group">
                                {checkedCharges.lr_no && (
                                  <input
                                    type="text"
                                    className="form-control form-control-alternative extra-charge"
                                    name="lr_no"
                                    onChange={updateOtherCharges}
                                    value={otherCharges.lr_no || ""}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="col-lg">
                              <div className="form-group">
                                <input
                                  type="checkbox"
                                  className="is_checked"
                                  checked={!!checkedCharges.challan}
                                  name="challan"
                                  onChange={updateCheckBox}
                                />{" "}
                                <span className="other-charge-font">
                                  {" "}
                                  Challan No.
                                </span>
                              </div>
                            </div>
                            <div className="col-lg">
                              <div className="form-group">
                                {checkedCharges.challan && (
                                  <input
                                    type="text"
                                    className="form-control form-control-alternative extra-charge"
                                    name="challan"
                                    onChange={updateOtherCharges}
                                    value={otherCharges.challan || ""}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
  
                          <div className="row">
                            <div className="col-lg">
                              <div className="form-group">
                                <input
                                  type="checkbox"
                                  className="is_checked"
                                  checked={!!checkedCharges.sbp_code}
                                  name="sbp_code"
                                  onChange={updateCheckBox}
                                />{" "}
                                <span className="other-charge-font">
                                  {" "}
                                  Sbp Code
                                </span>
                              </div>
                            </div>
                            <div className="col-lg">
                              <div className="form-group">
                                {checkedCharges.sbp_code && (
                                  <input
                                    type="text"
                                    id="sbp_code"
                                    className="form-control form-control-alternative extra-charge"
                                    name="sbp_code"
                                    onChange={updateOtherCharges}
                                    value={otherCharges.sbp_code || ""}
                                  />
                                )}
                              </div>
                            </div>
                          </div> */}
                      </div>
                      {/* Final Amount Table */}
                      <div className="col-lg-4 mb-4">
                        <div className="card shadow">
                          <input
                            type="hidden"
                            id="total_discount_column"
                            defaultValue={0}
                            name="overall_discount"
                          />
                          <div className="table-responsive">
                            <table
                              id="amount_table"
                              className="table align-items-center table"
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">Details</th>
                                  <th scope="col">Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th scope="row">Sub Total</th>
                                  <td id="sub_total_column">
                                    <input
                                      type="hidden"
                                      defaultValue={0}
                                      name="subtotal"
                                    />{" "}
                                    ₹&nbsp;{finalAmounts.totalAmount}{" "}
                                  </td>
                                </tr>
                                {/* <tr
                                        id="cgst_row"
                                        style={{ display: "table-row" }}
                                      >
                                        <th scope="row">GST</th>
                                        <td id="gst_column">
                                          ₹ {finalAmounts.GST}
                                        </td>
                                      </tr> */}
                                {Number(finalAmounts?.discount) ? (
                                  <tr>
                                    <th scope="row">Total Discount</th>
                                    <td id="discount">
                                      <input
                                        type="hidden"
                                        defaultValue={0}
                                        name="discount"
                                      />
                                      {"- "}₹&nbsp;{finalAmounts.discount}
                                    </td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {finalAmounts?.GST ? (
                                  isClientCompanyStateSame ? (
                                    <>
                                      <tr>
                                        <th scope="row">CGST</th>
                                        <td id="grand_total_column">
                                          <input
                                            type="hidden"
                                            defaultValue={0}
                                            name="CGST"
                                          />
                                          ₹&nbsp;{finalAmounts?.CGST}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th scope="row">SGST</th>
                                        <td id="grand_total_column">
                                          <input
                                            type="hidden"
                                            defaultValue={0}
                                            name="SGST"
                                          />{" "}
                                          ₹&nbsp;{finalAmounts?.SGST}
                                        </td>
                                      </tr>
                                    </>
                                  ) : (
                                    <tr>
                                      <th scope="row">IGST</th>
                                      <td id="grand_total_column">
                                        <input
                                          type="hidden"
                                          defaultValue={0}
                                          name="IGST"
                                        />{" "}
                                        ₹&nbsp;{finalAmounts?.IGST}
                                      </td>
                                    </tr>
                                  )
                                ) : (
                                  ""
                                )}
                                <tr>
                                  <th scope="row">Grand Total</th>
                                  <td id="grand_total_column">
                                    <input
                                      type="hidden"
                                      defaultValue={0}
                                      name="grand_sum"
                                    />{" "}
                                    ₹&nbsp;{finalAmounts.grandTotal}
                                  </td>
                                </tr>
                                {/* <tr>
                                        <th scope="row">Amount Paid</th>
                                        <td id="amount_paid">
                                          <input
                                            type="hidden"
                                            defaultValue={0}
                                            name="advance_sum"
                                          />{" "}
                                          ₹ {addDeliveryChallan?.paid_amount || 0}
                                        </td>
                                      </tr>{" "} */}

                                <tr>
                                  <th scope="row">Amount Due</th>
                                  <td id="due_amount">
                                    <input
                                      type="hidden"
                                      defaultValue={0}
                                      name="due_sum"
                                    />{" "}
                                    ₹&nbsp;{finalAmounts.balance}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      {/* <div className="row d-flex align-items-end justify-content-end mt-3">
                          <div className="col-3">
                            <div className="form-group">
                              <div className="input-group d-flex align-items-center justify-content-center gap-4">
                                <label className="thead-light "> Paid</label>
                                <input
                                  type="text"
                                  id="paid_amount"
                                  name="paid_amount"
                                  onChange={(e) =>
                                    handleAddDeliveryChallanInputChangeWithConditonTypeFloat(
                                      e,
                                      /^\d*\.?\d{0,2}$/
                                    )
                                  }
                                  value={addDeliveryChallan?.paid_amount || ""}
                                  className="form-control form-control-alternative "
                                  disabled={!finalAmounts.grandTotal}
                                />
                              </div>
                            </div>
                          </div>
                        </div> */}
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>Note For Client </label>
                          <textarea
                            type="text"
                            className="form-control form-control-alternative"
                            placeholder="Note For Client"
                            name="clientNote"
                            value={otherInfo.clientNote}
                            onChange={updateOtherInfo}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label> Private Note </label>
                          <textarea
                            type="text"
                            className="form-control form-control-alternative"
                            placeholder="Note For Admin"
                            name="note"
                            value={otherInfo.note}
                            onChange={updateOtherInfo}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <div className="select-container ">
                            <label className="col-lg-12 ">Bank</label>
                            <Select
                              // styles={customStyles}

                              placeholder="Select Type"
                              name="bank"
                              className="w-100"
                              value={otherInfo.bank}
                              onChange={(val) => updateOtherInfo(val, "bank")}
                              isClearable={true}
                              options={bankArray}
                              styles={selectCustomStyle}
                              maxMenuHeight={120}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-6">
                          <div className="form-group">
                            <div className="select-container ">
                              <label className="col-12 "> Client Type*</label>
                              <Select
                                // styles={customStyles}
                                required
                                placeholder="Select Type"
                                name="client_type"
                                className="w-100"
                                value={otherInfo.client_type}
                                onChange={(val) =>
                                  updateOtherInfo(val, "client_type")
                                }
                                defaultValue={{
                                  label: "Customer",
                                  value: "Customer",
                                }}
                                options={[
                                  { label: "Customer", value: "Customer" },
                                  { label: "Supplier", value: "Supplier" },
                                  { label: "Transporter", value: "Transporter" },
                                ]}
                                styles={selectCustomStyle}
                              />
                            </div>
                          </div>
                        </div> */}
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary me-2 px-3 py-2"
                      onClick={handleSubmit}
                      disabled={apiInProccess}
                    >
                      <div className="d-flex justify-content-center">
                        {" "}
                        <i className="mdi mdi-plus fw-bold"></i> Save
                      </div>
                    </button>
                    <button
                      type="button"
                      onClick={handlePreview}
                      className="btn btn-primary me-2 px-3 py-2"
                      disabled={
                        !addDeliveryChallan?.name ||
                        !addDeliveryChallan?.date ||
                        !addDeliveryChallan?.dueDate ||
                        !+finalAmounts?.totalAmount
                      }
                    >
                      <div className="d-flex justify-content-center">
                        {" "}
                        <i className="mdi mdi-checkbox-multiple-marked-circle-outline fw-bold"></i>{" "}
                        Preview
                      </div>
                    </button>

                    <button
                      className="btn btn btn-secondary px-3 py-2"
                      onClick={() => navigate("/invoices/delivery_challan")}
                    >
                      <div className="d-flex justify-content-center ">
                        <i className="mdi mdi-close "></i>
                        Cancel
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddDeliveryChallan;
