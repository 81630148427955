import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Header from "../../helper/Header";
import Sidebar from "../../helper/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { companyApi, userProfile } from "../../assets/apis";
import { setCompanyData } from "../../redux-store/Company";
import Footer from "../../helper/Footer";

import axios from "axios";
import {
  loadUserFail,
  loadUserRequest,
  loadUserSuccess,
} from "../../redux-store/Auth";
import toast from "react-hot-toast";
import { setFinancialYearArray } from "../../redux-store/FinancialYear";
import CoverSpin from "../../spinner/second_spinner/CoverSpin";
import ScrollToTop from "../../ScrollToTop/ScrollToTop";

const FullLayout = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, loading } = useSelector((state) => state.Auth);
  const ProtectedRoute = ({ children }) => {
    if (loading === false) {
      if (!isAuthenticated) {
        return <Navigate to="/auth/login" />;
      } else {
        return children;
      }
    } else {
      return <CoverSpin />;
    }
  };

  const loadUser = async () => {
    try {
      dispatch(loadUserRequest());
      const response = await axios.get(userProfile);
      const user = response?.data?.data;
      dispatch(loadUserSuccess({ user }));
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        dispatch(loadUserFail());
      }
    }
  };

  useEffect(() => {
    if (!isAuthenticated) loadUser();
  }, []);

  const getCompany = async () => {
    try {
      const result = await axios.get(companyApi);
      const response = result.data;
      dispatch(setCompanyData(response.company));
      dispatch(
        setFinancialYearArray(response?.financialYearData?.financial_year)
      );
    } catch (error) {
      dispatch(setCompanyData({}));
    }
  };

  useEffect(() => {
    if (isAuthenticated) getCompany();
    return () => {
      dispatch(setCompanyData({}));
    };
  }, [isAuthenticated]);

  return (
    <>
    <ScrollToTop/>
    <ProtectedRoute>
      <div className={`container-scroller`}>
        <Header />
        <div className={`container-fluid page-body-wrapper `}>
          <Sidebar />
          <Outlet />
        </div>
        <Footer />
      </div>
    </ProtectedRoute>
    </>
  );
};

export default FullLayout;
