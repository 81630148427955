import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { userProfile } from "../assets/apis";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { loadUserSuccess } from "../redux-store/Auth";

const UserProfile = () => {
  const { loggedUser } = useSelector((state) => state.Auth);
  const [userProfileData, setUserProfileData] = useState({});
  const [userPasswordData, setUserPasswordData] = useState({});

  const dispatch = useDispatch();
  useMemo(() => {
    if (loggedUser) {
      setUserProfileData((pre) => ({
        ...pre,
        email: loggedUser.email,
        mobile: loggedUser.mobile,
        name: loggedUser.name,
      }));
    }
  }, [loggedUser]);

  const handleChangeUserProfileDataWithCondition = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setUserProfileData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const loadUser = async () => {
    try {
      const response = await axios.get(userProfile);
      const user = response?.data?.data;
      dispatch(loadUserSuccess({ user }));
    } catch (error) {}
  };

  const onSubmitProfileUpdate = async (e) => {
    e.preventDefault();

    const { name, mobile, email } = userProfileData;

    if (!name || (name && !name.trim())) {
      toast.error("Please enter name");
      return;
    }

    const emailRegax = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!email || (email && !emailRegax.test(email))) {
      toast.error("Please enter a valid email");
      return;
    }

    if (!mobile || mobile?.length !== 10) {
      toast.error("Please enter a valid mobile number");
      return;
    }

    let updatedUserData = {
      ...userProfileData,
      ...(name && { name: name.trim() }),
      ...(email && { email: email.trim() }),
    };

    setUserProfileData(updatedUserData);

    try {
      await axios.put(`${userProfile}/profileupdate`, updatedUserData);
      toast.success("User profile updated successfully");
      loadUser();
    } catch (error) {
      toast.error(error?.response?.data?.message || "sorry! sever error");
    }
  };

  const onChangeUserPasswordInput = (e) => {
    const { name, value } = e.target;
    setUserPasswordData((pre) => ({ ...pre, [name]: value }));
  };

  const onSubmitPasswordUpdate = async (e) => {
    e.preventDefault();
    const { oldPassword, password, confirmPassword } = userPasswordData;
    if (!oldPassword) return toast.error("Please enter your old password");
    if (!password || password.length < 8)
      return toast.error("Password must be at least 8 characters");
    if (!confirmPassword)
      return toast.error("Please enter your confirm password");
    if (oldPassword === password)
      return toast.error("Old password and new password cannot be same");
    if (password !== confirmPassword)
      return toast.error("Password and Confirm Password must be same");

    try {
      const response = await axios.put(`${userProfile}/passwordupdate`, {
        oldPassword,
        password,
        confirmPassword,
      });
      toast.success("User password updated successfully");
      setUserPasswordData({});
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="row">
            <div className="col-sm-12">
                <h2 className="ukhd mb-3">My Profile</h2>

                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                    <div className="col-lg-12 grid-margin stretch-card">
                      <div className="card card-rounded">
                        <div className="card-body">
                          <img
                            src="https://www.w3schools.com/howto/img_avatar.png"
                            style={{ borderRadius: "50%", width: "10rem" }}
                            alt=""
                          />
                          <h3 style={{ marginTop: "10px" }}>
                            {userProfileData.email || ""}
                          </h3>
                          <hr />
                          <form
                            onSubmit={onSubmitProfileUpdate}
                            className="row"
                          >
                            <div className="col-lg-5">
                              <div className="form-group">
                                <div className="select-container ">
                                  <label className="col-lg-12"> *Name</label>
                                  <div className="input-group">
                                    <input
                                      type="text"
                                      className="form-control form-control-alternative"
                                      placeholder="name"
                                      name="name"
                                      required
                                      value={userProfileData.name || ""}
                                      onChange={(e) =>
                                        handleChangeUserProfileDataWithCondition(
                                          e,
                                          /^[a-zA-Z\s\b]+$/
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-7">
                              <div className="form-group">
                                <div className="select-container ">
                                  <label className="col-lg-12"> *Email</label>
                                  <div className="input-group">
                                    <input
                                      type="email"
                                      className="form-control form-control-alternative"
                                      placeholder="Email"
                                      name="email"
                                      defaultValue={userProfileData.email || ""}
                                      readOnly={true}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-5">
                              <div className="form-group">
                                <div className="select-container ">
                                  <label className="col-lg-12"> *Phone No</label>
                                  <div className="input-group">
                                    <input
                                      type="text"
                                      className="form-control form-control-alternative"
                                      placeholder="Phone"
                                      name="mobile"
                                      required
                                      minLength={10}
                                      maxLength={10}
                                      value={userProfileData.mobile || ""}
                                      onChange={(e) =>
                                        handleChangeUserProfileDataWithCondition(
                                          e,
                                          /^[0-9\b]+$/
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <button
                                type="submit"
                                className="btn btn-warning me-2"
                              >
                                Update
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="col-lg-12 grid-margin stretch-card">
                      <div className="card card-rounded">
                        <div className="card-body">
                          <h4>Update Password</h4>
                          <hr />

                          <form
                            className="row"
                            onSubmit={onSubmitPasswordUpdate}
                          >
                            <div className="col-lg-10">
                              <div className="form-group">
                                <div className="select-container ">
                                  <label className="col-lg-12">
                                    {" "}
                                    *Old Password
                                  </label>
                                  <div className="input-group">
                                    <input
                                      type="password"
                                      className="form-control form-control-alternative"
                                      placeholder="Old Password"
                                      name="oldPassword"
                                      required
                                      minLength={8}
                                      maxLength={16}
                                      autoComplete="off"
                                      value={userPasswordData.oldPassword || ""}
                                      onChange={onChangeUserPasswordInput}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-10">
                              <div className="form-group">
                                <div className="select-container ">
                                  <label className="col-lg-12">
                                    {" "}
                                    *New Password
                                  </label>
                                  <div className="input-group">
                                    <input
                                      type="password"
                                      className="form-control form-control-alternative"
                                      placeholder="New Password"
                                      name="password"
                                      required
                                      minLength={8}
                                      maxLength={16}
                                      autoComplete="off"
                                      value={userPasswordData.password || ""}
                                      onChange={onChangeUserPasswordInput}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-10">
                              <div className="form-group">
                                <div className="select-container ">
                                  <label className="col-lg-12">
                                    {" "}
                                    *Confirm Password
                                  </label>
                                  <div className="input-group">
                                    <input
                                      type="password"
                                      className="form-control form-control-alternative"
                                      placeholder="Confirm Password"
                                      name="confirmPassword"
                                      required
                                      minLength={8}
                                      maxLength={16}
                                      autoComplete="off"
                                      value={
                                        userPasswordData.confirmPassword || ""
                                      }
                                      onChange={onChangeUserPasswordInput}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-10">
                              <button
                                type="submit"
                                className="btn btn-success me-2"
                              >
                                Update Password
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserProfile;
